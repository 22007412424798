/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useEffect, useState} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {LastLocationProvider} from "react-router-last-location";
import {Routes} from "./app/router/Routes";
import {I18nProvider, LayoutSplashScreen, ThemeProvider} from "./shared";
import {SnackbarProvider} from "notistack";
import { ConfirmProvider } from 'material-ui-confirm';
import { getMaintenanceMode } from "./app/crud/trainingInstitute.crud";


export default function App({store, persistor, basename}) {
  const [maintenance, setMaintenance] = useState(false);
  useEffect(() => {
    getMaintenanceMode().then(response => {
      setMaintenance(response.data);
    })
  }, []);
  return (
    /* Provide Redux store */

    <Provider store={store} loading={<LayoutSplashScreen/>}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen/>}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <LastLocationProvider>
              {/* Provide Metronic theme overrides. */}
              {
                !maintenance &&
              <ThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  {/* Provide Snackbar provider from notistack */}
                  <SnackbarProvider maxSnack={2}>
                    {/* Provide confirm from material-ui-confirm */}
                    <ConfirmProvider>
                    {/* Render routes with provided `Layout`. */}

                        <Routes/>


                    </ConfirmProvider>
                  </SnackbarProvider>
                </I18nProvider>
              </ThemeProvider>
              }
            </LastLocationProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
