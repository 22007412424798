import axios from "axios";

export const TRAINING_OPERATOR_URL = "training-operators";
export const STATUS_URL = "training-institutes/status";
export const AREAZONE_URL = "training-institutes/area-zone";
export const SECTORS_URL = "training-institutes/sectors";
export const MODES_URL = "training-institutes/training-modes";
export const PROGRAMS_URL = "training-institutes/training-programs";
export const LEVELS_URL = "training-institutes/training-levels";
export const TYPES_URL = "training-institutes/training-types";
export const AREA_ORIGIN_URL = "training-institutes/training-area-origin";
export const TRAINING_INSTITUTE = "training-institutes";
export const AGGREGATED_CRITERIA = "aggregation/criteria";
export const AGGREGATED_CRITERIA_YEARS = "aggregation/criteria/years";
export const TRAINING_INSTITUTE_TRAINING_MODE = "training-institutes/mode";
export const TRAINING_INSTITUTE_TRAINING_LEVEL = "training-institutes/level";
export const TRAINING_INSTITUTE_TRAINING_PROGRAM = "training-institutes/program";
export const TRAINING_INSTITUTE_YEARS = "training-institutes/years";
export const MAINTENANCE_MODE_URL = "training-institutes/maintenance";

export function getAllTrainingOperators(pageNumber, pageSize, filters) {
  return axios.get(TRAINING_OPERATOR_URL, { params: filters });
}

export function getAllTrainingStatus() {
  return axios.get(STATUS_URL);
}

export function deleteTrainingInstitute(id) {
  return axios.delete(TRAINING_INSTITUTE, { params: { id } });
}

export function getAllTrainingAreaZone() {
  return axios.get(AREAZONE_URL);
}

export function getAllTrainingSectors() {
  return axios.get(SECTORS_URL);
}

export function getAllTrainingTypes() {
  return axios.get(TYPES_URL);
}

export function getAllTrainingModes() {
  return axios.get(MODES_URL);
}

export function getAllTrainingPrograms() {
  return axios.get(PROGRAMS_URL);
}

export function getAllTrainingLevels() {
  return axios.get(LEVELS_URL);
}

export function getAllAreaOfOrigin() {
  return axios.get(AREA_ORIGIN_URL);
}

export function addOrUpdateTrainingInstitute(data, modify = false) {
  if (data.city) data = { ...data, city: { id: data.city } };
  if (data.areaOfOrigin) data = { ...data, areaOfOrigin: { id: data.areaOfOrigin } };
  if (data.areaZone) data = { ...data, areaZone: { id: data.areaZone } };
  if (data.instituteStatus) data = { ...data, instituteStatus: { id: data.instituteStatus } };
  if (data.trainingOperator) data = { ...data, trainingOperator: { id: data.trainingOperator } };
  if (data.trainingComplex) data = { ...data, trainingComplex: { id: data.trainingComplex } };
  if (data.trainingTypes.length > 0) data = {
    ...data, trainingTypes: data.trainingTypes.map(e => {
      return { id: e };
    })
  };
  if (data.trainingSectors.length > 0) data = {
    ...data, trainingSectors: data.trainingSectors.map(e => {
      return { id: e };
    })
  };
  if (data.branches.length > 0) data = {
    ...data, branches: data.branches.map(e => {
      return { id: e };
    })
  };

  if (modify) {
    return axios.put(TRAINING_INSTITUTE, data);
  }

  return axios.post(TRAINING_INSTITUTE, data);
  return null;
}

export function addNewTrainingMode(data, id) {

  return axios.post(TRAINING_INSTITUTE_TRAINING_MODE,
    { effective: data.effective, trainingMode: { id: data.id }, trainingInstitute: { id: id } });
}

export function addNewTrainingLevel(data, id) {

  return axios.post(TRAINING_INSTITUTE_TRAINING_LEVEL,
    { effective: data.effective, trainingLevel: { id: data.id }, trainingInstitute: { id: id } });
}

export function addNewTrainingProgram(data, id) {

  return axios.post(TRAINING_INSTITUTE_TRAINING_PROGRAM,
    {
      effective: data.effective,
      nbOfHours: data.nbOfHours,
      trainingProgram: { id: data.id },
      trainingInstitute: { id: id }
    });
}

export function getAllTrainingInstitutes(filters) {
  return axios.get(TRAINING_INSTITUTE, { params: filters });
}

export function getCriteriaByYearAndInstitute(id, year) {
  return axios.get(AGGREGATED_CRITERIA + "/" + id + "/" + year);
}

export function getAggregatedYears(id) {
  return axios.get(AGGREGATED_CRITERIA_YEARS + "/" + id);
}

export function saveAggregatedCriteria(element) {
  return axios.post(AGGREGATED_CRITERIA, element);
}

export function getYearsForTrainingInstite(trainingInstituteId) {
  return axios.get(TRAINING_INSTITUTE_YEARS, { params: { trainingInstituteId } });
}


export function getMaintenanceMode() {
  return axios.get(MAINTENANCE_MODE_URL);
}


