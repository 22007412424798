import axios from "axios";

export const REGIONS_URL = "sectors";
export const CITY_URL = "sectors/branch";


export function saveSector(name) {
  return axios.post(REGIONS_URL, { value: name });
}

export function updateSector(id, name) {
  return axios.put(REGIONS_URL, { id, value: name });
}

export function deleteSector(id) {
  return axios.delete(REGIONS_URL, { params: { id } });
}

export function saveBranch(name, regionId) {
  return axios.post(CITY_URL, { value: name }, { params: { regionId } });
}

export function updateBranch(id, name) {
  return axios.put(CITY_URL, { id, value: name });
}


export function deleteBranch(id) {
  return axios.delete(CITY_URL, { params: { id } });
}



