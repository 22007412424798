import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import { withRouter } from "react-router-dom";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import StickyToolbar from "../../app/partials/layout/StickyToolbar";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import "./assets/Base.scss";
import { filter, includes, first, cloneDeep } from "lodash";

const htmlClassService = new HTMLClassService();

function Layout({
                  children,
                  asideDisplay,
                  subheaderDisplay,
                  selfLayout,
                  layoutConfig,
                  user
                }) {
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  const contentContainerCssClasses = htmlClassService.classes.content_container.join(
    " "
  );


  const checkRoleForMenuItem = menu => {
    if (!menu.accessibleRoles) {
      return true;
    } else {
      return includes(menu.accessibleRoles, first(user.roles));
    }
  };

  const filterMenuByRoles = () => {
    const menu = cloneDeep(MenuConfig);
    menu.header.items = filter(menu.header.items, (item) => {
      if (item.submenu) {
        item.submenu = filter(item.submenu, submenu => checkRoleForMenuItem(submenu));
      }
      return checkRoleForMenuItem(item);
    });
    return menu;
  };

  const [menuConfig, setMenuConfig] = useState(MenuConfig);

  useEffect(() => {
    setMenuConfig(filterMenuByRoles());
  }, [user]);

  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={menuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile/>
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <Header/>
            {/* <!-- end:: Header --> */}
            <div
              className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
              id="kt_body"
            >
              {/* <!-- begin:: Aside Left --> */}
              {asideDisplay && (
                <>
                  <div
                    className={`kt-container ${contentContainerCssClasses} kt-container--fit kt-grid kt-grid--ver`}
                  >
                    <AsideLeft/>
                    <div
                      className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                      id="kt_content"
                    >
                      <KtContent>{children}</KtContent>
                    </div>
                  </div>
                </>
              )}
              {!asideDisplay && (
                <>
                  {/* <!-- begin:: Content --> */}
                  <div
                    id="kt_content"
                    className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                  >
                    {/* <!-- begin:: Content Head --> */}
                    {subheaderDisplay && <SubHeader/>}
                    {/* <!-- end:: Content Head --> */}

                    {/* <!-- begin:: Content Body --> */}
                    <KtContent>{children}</KtContent>
                    {/*<!-- end:: Content Body -->*/}
                  </div>
                  {/* <!-- end:: Content --> */}
                </>
              )}

              {/* <!-- end:: Aside Left --> */}
            </div>
            <Footer/>
          </div>
        </div>

        {/* <!-- end:: Body --> */}
      </div>
      <QuickPanel user={user}/>
      <ScrollTop/>
      <StickyToolbar user={user}/>
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig }, auth: { user } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  user
});

export default withRouter(connect(mapStateToProps)(Layout));
