import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { ExpandMore, Refresh } from "@material-ui/icons";
import { getAllRegions } from "../../../../crud/training.crud";
import { merge } from "lodash";
import { ResetButton } from "../../../../../shared/component/ActionsButton";

const TrainingInstituteFilter = ({ filter, setFilter, resetFilter }) => {
  const [cities, setCities] = React.useState([]);
  const [regions, setRegions] = React.useState([]);


  useEffect(() => {
    getAllRegions().then(response => {
      setRegions(response.data);
    });
  }, []);

  const updateFilterValue = (change) => {
    setFilter(merge({}, filter, change));
  };

  const handleChangeRegion = (event) => {
    updateFilterValue({ regionId: event.target.value, city: null });
    setCities(regions.filter(e => e.id == event.target.value)[0].cities);
  };

  return (
    <Accordion className={"mb-2"}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Les paramètres de recherche</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={"row"}>
          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <TextField label="Intitulé" variant="outlined" size="small" style={{ marginTop: "10px" }}
                         value={filter.name}
                         onChange={(event) => updateFilterValue({name: event.target.value})}/>
            </FormControl>
          </div>

          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <InputLabel id="demo-simple-select-helper-label">Région</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={filter.regionId || ''}
                label="Région"
                onChange={handleChangeRegion}
              >
                {
                  regions.map(e => {
                    return (<MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>);
                  })
                }


              </Select>
            </FormControl>
          </div>

          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <InputLabel id="demo-simple-select-helper-label">Ville/Commune/Commune</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={filter.cityId || ''}
                label="Ville/Commune"
                onChange={(event) => updateFilterValue({ cityId: event.target.value })}
              >
                {
                  cities.map(e => {
                    return (<MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>);
                  })
                }
              </Select>
            </FormControl>
          </div>

        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div style={{ float: "right" }}>
              <ResetButton onClick={() => resetFilter()} />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default TrainingInstituteFilter;
