import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography
} from "@material-ui/core";

import { Cancel, FileCopy, Save } from "@material-ui/icons";
import TextField from "@mui/material/TextField";
import {
  getAggregatedYears,
  getCriteriaByYearAndInstitute, getYearsForTrainingInstite,
  saveAggregatedCriteria
} from "../../../../crud/trainingInstitute.crud";
import * as _ from "lodash";
import Divider from "@mui/material/Divider";
import AggregationTable from "../../referential-quiz/AggregationTable";
import { first, size } from "lodash";
import { useNotification } from "../../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

const NewAggregatedCriteria = ({ isOpen, closeModal, data, ti , modeOfTraining, user}) => {
  console.log('=====> rows ===> ti iiii xxx', modeOfTraining)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "auto",
    maxHeight: "95%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  };
  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  const [years, setYears] = useState([]);
  const [existingYears, setExistingYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYears, setSelectedYears] = useState([]);
  const [fillData, setFillData] = useState(false);
  const [all, setAll] = useState(false);
  const [selectedExistingYear, setSelectedExistingYear] = useState(null);
  const [existingData, setExistingData] = useState({ show: false, years: [] });
  const [values, setValues] = useState(() => {
    let x = {};
    Object.keys(data.data).forEach(elem => {
        data.data[elem].data.forEach(e => {
          x[e.field] = null;
        });
      }
    );
    return x;
  });
  const [rows, setRows] = useState(data);

  const handleChange = (event, field) => {
    let fake = { ...values };

    fake[field] = parseInt(event.target.value);
    setValues(fake);
  };

  const handleAddNewYar = () => {
    let valuesTosend = { ...values };
    valuesTosend.trainingInstitute = { id: ti };
    valuesTosend.refYear = selectedYear;

    saveAggregatedCriteria(valuesTosend)
      .catch(err => {
        notifyError("Une erreure technique lors de la sauvegade des indicateurs !");
        throw err;
      })
      .then(response => {
        if (response.data) {
          notifySuccess("Indicateurs statistiques sauvegardées avec succès !");
          setFillData(false);
        }
      });
  };
  const handleChangeYear = (year) => {
    setSelectedYear(year);
    setSelectedYears([year]);
    setAll(false);
    getCriteriaByYearAndInstitute(ti, year).then(response => {
      setValues(response.data);
    });
  };
  const handleChangeYearCopyFrom = (event) => {
    setSelectedExistingYear(event.target.value);
    getCriteriaByYearAndInstitute(ti, event.target.value)
      .catch(err => {
        notifyError("Une erreur technique lors du chargement des données !");
        throw err;
      })
      .then(({ data }) => {
        data.refYear = event.target.value;
        data.id = null;
        setValues(data);
      });
  };

  useEffect(() => {
    Promise.all([getYearsForTrainingInstite(ti), getAggregatedYears(ti)])
      .catch(err => {
        notifyError("Une erreur technique lors du chargement des données !");
        throw err;
      })
      .then(([{ data: yearsData }, { data: existingYearsData }]) => {
        setYears(yearsData);
        setExistingYears(existingYearsData);
        if (size(yearsData) > 0) {
          handleChangeYear(first(yearsData));
        }
      });

  }, []);
  return (
    <Modal
      key={ti + '-modal-indicators' + all + fillData }
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={"row mb-3"}>
          <div className="col-md-6">
            {fillData &&
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Informations statistiques pour l’année {selectedYear}
            </Typography>
            }

            {!fillData &&

            <Typography id="modal-modal-title" variant="h6" component="h2">
              Indicateurs statistiques agrégés 
            </Typography>
            }

          </div>
          <div className="col-md-6">
            <div style={{ float: "right" }}>
              {fillData &&
              <Button variant="outlinedSecondary" onClick={() => {setFillData(false); setExistingYears([selectedYear])}}>
                Afficher les résultats
              </Button>
              }

              {!fillData &&

              <Button className={"mr-1 ml-1"} variant="outlinedSecondary" onClick={() => {
                setFillData(false);
                setSelectedYears([...years.sort()]);
                setAll(true);
              }}>
                Afficher l'évolution par année
              </Button>
              }

              {!fillData && (user.role == "ROLE_EFP_SAISIE") &&
              <Button variant="outlinedSecondary" onClick={() => {setFillData(true); setSelectedYears([selectedYear]); setAll(false)}}>
                Remplir les données
              </Button>
              }
            </div>
          </div>
        </div>

        {fillData &&
        <div>
          <div className="row mb-3">
            <div className="col-md-3">
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Année"
                  onChange={event => handleChangeYear(event.target.value)}
                  value={selectedYear}
                >
                  {years.map(year => <MenuItem value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3">
            </div>
            <div className="col-md-3">
            </div>
            <div className="col-md-3">
              {!existingData.show &&
              <div style={{ float: "right" }}>
                <Button variant="containedPrimary" endIcon={<FileCopy/>}
                        onClick={() => setExistingData({ ...existingData, show: true })}
                        disabled={selectedYear ? false : true}>
                  Données existantes
                </Button>
              </div>
              }
              {existingData.show &&
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Données existantes</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  onChange={handleChangeYearCopyFrom}
                  value={selectedExistingYear}
                >
                  {existingYears.map(year => <MenuItem value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
              }

            </div>
          </div>
          {
            Object.keys(rows.data).map(element => {
              if(element =='learners' && !modeOfTraining){
                console.log('====> element mode <<<', element)
                return (<></>)
          } else {
                console.log('====> element mode www', element)

                return (
            <div className={"mt-2"} key={"rs-" + element.field}>
            <h5>{rows.data[element].label}</h5>
            {
              _.chunk(rows.data[element].data, 3).map(row => (
                <div className="row mb-2" key={"row" + element.field}>
                  {
                    row.map(e => (
                      <div className="col-md-4" key={"div" + e.field}>
                        <FormControl fullWidth key={"form" + e.field}>
                          <TextField
                            key={"text-" + e.field}
                            InputLabelProps={{ shrink: true }}
                            label={e.label}
                            value={parseInt(values[e.field])}
                            onChange={(event) => handleChange(event, e.field)}
                            type={"number"}
                          />
                        </FormControl>
                      </div>
                    ))
                  }
                </div>
              ))
            }
            <Divider/>

            </div>
            )
          }
            })
          }
          <br/>
          <div className="row mt-4 ">
            <div className="col-md-12">
              <div style={{ textAlign: "center" }}>
                <Button variant="outlinedSecondary" startIcon={<Cancel/>}
                        onClick={closeModal}
                        style={{ marginRight: "5px" }}>
                  Annuler
                </Button>
                <Button variant="containedPrimary" endIcon={<Save/>} onClick={() => handleAddNewYar()}>
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
        </div>
        }

        {
          !fillData &&
          <div key={"proc-indicators2"}>
            <div className="row">
              <div className="col-md-3">
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select"
                    label="Année"
                    onChange={event => handleChangeYear(event.target.value)}
                    value={selectedYear}
                  >
                    {years.map(year => <MenuItem value={year} key={"year-" + year}>{year}</MenuItem>)}
                  </Select>
                </FormControl>
              </div>
            </div>
            <AggregationTable year={selectedYear} ti={ti} key={ti + selectedYears + uuidv4()} mode={modeOfTraining}
            existingYears={selectedYears}/>
          </div>
        }
      </Box>


    </Modal>
  );
};

export default NewAggregatedCriteria;
