import axios from "axios";

const LOGIN_URL = "auth/login";
const REQUEST_PASSWORD_URL = "auth/forgot-password";
const USER_INFO_URL = "user/info";
const UPDATE_PWD_URL = "user/password";
const UPDATE_EMAIL_URL = "user/email";
const USER_URL = "user";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function forgotPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, null, { params: { email } });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(USER_INFO_URL);
}

export function updatePassword(request) {
  return axios.post(UPDATE_PWD_URL, request);
}

export function updateEmail(email) {
  return axios.post(UPDATE_EMAIL_URL, email);
}

export function deleteUser(id) {
  return axios.delete(USER_URL, { params: { id } });
}


