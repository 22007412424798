import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import { deleteTrainingOperator, getAllTrainingOperators } from "../../../../crud/training.crud";
import TrainingOperatorFilter from "./TraingingOperatorFilter";
import NewTrainingOperator from "./NewTrainingOperator";
import {
  canAddTrainingOperator,
  canRemoveTrainingOperator,
  canUpdateTrainingOperator
} from "../../../../utils/roles.utils";
import { AddButton, DeleteButton, EditButton } from "../../../../../shared/component/ActionsButton";
import TableLoading from "../../../../../shared/component/TableLoading";
import { genericFilterAndPagination } from "../../../../utils/search.utils";
import CustomPagination from "../../../../../shared/component/CustomPagination";
import { useNotification } from "../../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { actionConfirm } from "../../../../../shared/utils/confirm.util";
import { useConfirm } from "material-ui-confirm";

const INITIAL_FILTER_STATE = { name: "", email: "", phoneNumber: "", cityId: null, regionId: null };

function TrainingOperator({ user }) {
  const confirm = actionConfirm(useConfirm());
  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filter, setFilter] = React.useState(INITIAL_FILTER_STATE);
  const [loading, setLoading] = useState(true);
  const [addAction, setAddAction] = useState(true);
  const [toModify, setToModify] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [openModal]);


  const loadData = () => {
    getAllTrainingOperators()
      .catch(err => {
        notifyError("Une erreure technique est survenue lors du chargement des operateurs de formation !")
        throw err;
      })
      .then(response => {
        setRows(response.data);
        setLoading(false);
      });
  };

  const deleteById = (id) => {
    confirm("Etes vous sûr de bien vouloir suprrimer cet Operateur de formation et ses utilisateurs ?").then(() => {
      deleteTrainingOperator(id)
        .catch(err => {
          notifyError("Une erreure technique est survenue lors de la supression de l'operateur de formation !");
          throw err;
        })
        .then(() => {
          notifySuccess("L'operateur de formation a bien été supprimé !");
          loadData();
        });
    });
  };

  const { data, dataLength } = genericFilterAndPagination(rows, filter, page, rowsPerPage);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-12">
          <div style={{ float: "right" }}>
            {canAddTrainingOperator(user) && <AddButton onClick={() => {
              setOpenModal(true);
              setAddAction(true);
            }}/>}
          </div>
        </div>
      </div>
      <TrainingOperatorFilter filter={filter} setFilter={setFilter}
                              resetFilter={() => setFilter(INITIAL_FILTER_STATE)}/>
      <div className={"kt-portlet"}>

        <div className={"kt-portlet__body"}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Intitulé</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Téléphone</TableCell>
                <TableCell align="right">Ville/Commune</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            {!loading ?

              <TableBody>
                {(data || []).map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.phoneNumber}</TableCell>
                    <TableCell align="right">{row.city.name}</TableCell>
                    <TableCell align="right">
                      {  canUpdateTrainingOperator(user) && <EditButton onClick={() => {
                        setAddAction(false);
                        setToModify(row);
                        setOpenModal(true);
                      }}/>  }
                      {canRemoveTrainingOperator(user) &&
                      <DeleteButton onClick={() => deleteById(row.id)}/>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody> : <TableLoading/>}
            <TableFooter>
              <TableRow>
                <CustomPagination
                  totalRows={dataLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>

      <NewTrainingOperator openModalValue={openModal} closeModal={closeModal} key={openModal} addAction={addAction}
                           rowData={toModify}/>
    </>

  );
}

export default TrainingOperator;

