import axios from "axios";


const REGISTER_URL = "user/register";
const UPDATE_USER_URL = "user";
const GET_TRAINING_OPERATORS_HEADERS_URL = "training-operators/headers";
const GET_TRAINING_INSTITUTES_HEADERS_URL = "training-institutes/headers";
const GET_TRAINING_COMPLEXES_HEADERS_URL = "training-complexes/headers";
const GET_TRAINING_DR_HEADERS_URL = "training-dr/headers";


export function getTrainingOperatorHeaders() {
  return axios.get(GET_TRAINING_OPERATORS_HEADERS_URL);
}

export function getTrainingInstituteHeaders() {
  return axios.get(GET_TRAINING_INSTITUTES_HEADERS_URL);
}

export function getTrainingComplexHeaders() {
  return axios.get(GET_TRAINING_COMPLEXES_HEADERS_URL);
}

export function getTrainingDRHeaders() {
  return axios.get(GET_TRAINING_DR_HEADERS_URL);
}


export function register(user) {
  user.id = null;
  return axios.post(REGISTER_URL, user);
}

export function update(user) {
  delete user["roles"];
  delete user["notification"];
  delete user["isActive"];
  user.trainingComplex = null;
  user.trainingInstitute = null;
  user.trainingOperator = null;
  return axios.put(UPDATE_USER_URL, user);
}
