import React from "react";
import { linearProgressClasses, styled } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import { Button, Chip } from "@material-ui/core";
import { Add,ArrowBack } from "@material-ui/icons";
import { canSetupReferentialNewYear } from "../../../utils/roles.utils";


const ReferentialQuizHeader = ({ trainingInstitute, stats, addYear, status, user, navigation }) => {

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#5d78ff" : "#5d78ff"
    }
  }));

  return (
    <div className="row mb-4">
      <div className="col-md-4">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="mb-1">
            <span>Questionnaire  </span>
            <b>{trainingInstitute} {`${stats.filledPoints}/${stats.totalPoints} points de maturité`}<Chip
              className="ml-1" label={status} size="small"/> </b>
          </div>
        </div>
        <BorderLinearProgress variant="determinate" value={stats.progress}/>
      </div>
      <div className="col-md-4">
      </div>
      <div className="col-md-4">

        <div className={"filter-btn-div"}>
          <Button variant="outlinedPrimary" startIcon={<ArrowBack/>}
                  onClick={() => window.history.back()}
                  style={{ marginRight: "5px" }}>
            Retour
          </Button>
          {canSetupReferentialNewYear(user) && <Button variant="containedPrimary" startIcon={<Add/>}
                                                       onClick={() => addYear()}
                                                       style={{ marginRight: "5px" }}>
            Ajouter une annee
          </Button>}
        </div>
      </div>
    </div>
  );
};


export default ReferentialQuizHeader;
