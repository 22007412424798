import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, MenuItem, Modal, TextField, Typography} from "@material-ui/core";
import {Cancel, Save} from "@material-ui/icons";
import {
  addNewTrainingOperator,
  editTrainingOperator,
  getAllRegions,
  getAllUsers
} from "../../../../crud/training.crud";
import {Controller, useForm} from "react-hook-form";
import {v4 as uuidv4} from 'uuid';

const NewTrainingOperator = ({openModalValue, closeModal, rowData, addAction}) => {
    const [openModal, setOpenModal] = useState(openModalValue);
    const [selectedCity, setSelectedCity] = React.useState(null);
    const [selectedRegion, setSelectedRegion] = React.useState(null);
    const [selectedResponsible, setSelectedResponsible] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    // const [name, setName] = React.useState(null);
    // const [email, setEmail] = React.useState(null);
    const [phoneNumber, setPhoneNumber] = React.useState(null);
    const [cities, setCities] = React.useState([]);
    const [regions, setRegions] = React.useState([]);
    const [responsibles, setResponsibles] = React.useState([]);
    const {control, handleSubmit, formState: {errors}, value, setValue} = useForm({
        defaultValues: {
            name: rowData.name,
            email: rowData.email,
            phoneNumber: rowData.phoneNumber
        }
    });

    const handleChangeRegion = (event) => {
        setSelectedRegion(event.target.value);
        setCities(regions.filter(e => e.id == event.target.value)[0].cities)
    };

    useEffect(() => {
        getAllRegions().then(response => {
            setRegions(response.data);
            if (!addAction) {
                response.data.forEach(e => {
                    e.cities.forEach(ecity => {
                        if (ecity.id == rowData.city.id) {
                            setSelectedRegion(e.id);
                            setCities(e.cities);
                            setSelectedCity(rowData.city.id);
                            setValue('city', rowData.city.id);
                            setValue('region', e.id);
                        }
                    })
                });
            }
        });

        getAllUsers().then(response => {
            setResponsibles(response.data);
            if (!addAction) {
              //  setSelectedResponsible(rowData.responsible.id)

            }
        });

        if (!addAction) {
            setAddress(rowData.address);
            // setName(rowData.name);
            // setEmail(rowData.email);/**/
            setPhoneNumber(rowData.phoneNumber);
        }
    }, []);

    const onSubmit = data => {
        if(addAction){
          addNewTrainingOperator(data.name, data.email, data.phoneNumber, data.address, data.city, data.responsible).then(response => {
            closeModal();
          });
        } else {
          editTrainingOperator(rowData.id, data.name, data.email, data.phoneNumber, data.address, data.city, data.responsible).then(response => {
            closeModal();
          });
        }

    };

    const style = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: 'auto',
        maxHeight: '65%',
        overflowY: 'scroll',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Ajouter un nouvel opérateur de formation
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className={'row'}>
                        <div className="col-md-4">
                            <Controller
                                className={'vertical-center'}
                                name="name"
                                rules={{required: true}}
                                control={control}
                                render={({field}) => <TextField
                                    {...field}
                                    label={"Intitulé *"}
                                    variant="outlined"
                                    size="small"
                                    style={{marginTop: '10px'}}/>}
                            />
                            {errors.name && <span className={'label-error'}>L'intitulé est obligatoire</span>}
                        </div>

                        <div className="col-md-4">
                            <Controller
                                className={'vertical-center'}
                                name="email"

                                rules={{pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g}}

                                control={control}
                                render={({field}) => <TextField
                                    {...field}
                                    label="Email"
                                    variant="outlined"
                                    size="small"
                                    style={{marginTop: '10px'}}/>}
                            />
                            {errors.email && <span className={'label-error'}>Adresse e-mail invalide</span>}

                        </div>

                        <div className="col-md-4">
                            <Controller
                                className={'vertical-center'}
                                name="phoneNumber"
                                rules={{pattern: /^(?:(?:(?:\+|00)212[\s]?(?:[\s]?\(0\)[\s]?)?)|0){1}(?:5[\s.-]?[2-3]|6[\s.-]?[13-9]){1}[0-9]{1}(?:[\s.-]?\d{2}){3}$/gm}}

                                control={control}

                                render={({field}) => <TextField
                                    {...field}
                                    value={phoneNumber}
                                    onInput={(e) => setPhoneNumber(e.target.value)}
                                    label="Téléphone"
                                    variant="outlined"
                                    size="small"
                                    style={{marginTop: '10px'}}/>}
                            />
                            {errors.phoneNumber && <span className={'label-error'}>Numéro de telephone invalide</span>}

                        </div>

                    </div>

                    <div className={'row mt-2'}>
                     {/*   <div className="col-md-4">
                            <Controller
                                className={'vertical-center'}
                                name="responsible"
                                control={control}

                                rules={{required: true}}
                                render={({field}) => <FormControl key={uuidv4()} style={{width: '100%'}} className={'vertical-center'}>

                                    <TextField
                                        label={"Resposable"}
                                        key={selectedResponsible +'resp'}
                                        select
                                        defaultValue={selectedResponsible}
                                        {...field}
                                    >

                                        {
                                            responsibles.map(e => {
                                                return (
                                                    <MenuItem value={e.id}
                                                              key={uuidv4()}>{e.lastName + ' ' + e.firstName}</MenuItem>);
                                            })
                                        }
                                    </TextField>
                                </FormControl>}
                            />
                            {errors.responsible && <span className={'label-error'}>Le responsable est obligatoire</span>}

                        </div>
*/}
                        <div className="col-md-4">


                            <Controller
                                key={uuidv4()}
                                className={'vertical-center'}
                                name="region"
                                control={control}
                                render={({field}) => <FormControl style={{width: '100%'}} className={'vertical-center'}>

                                    <TextField
                                        select
                                        label={"Région"}

                                        value={selectedRegion}
                                        onChange={(e) => handleChangeRegion(e)}


                                    >

                                        {
                                            regions.map(e => {
                                                return (<MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                                            })
                                        }

                                    </TextField>
                                </FormControl>}
                            />

                        </div>

                        <div className="col-md-4">
                            <Controller
                                key={uuidv4()}
                                className={'vertical-center'}
                                name="city"
                                rules={{required: true}}
                                control={control}
                                render={({field}) => <FormControl style={{width: '100%'}} className={'vertical-center'}>

                                    <TextField
                                        label={"Ville/Commune *"}

                                        key={uuidv4() + selectedCity}
                                        select
                                        defaultValue={selectedCity}

                                        {...field}
                                    >
                                        {
                                            cities.map(e => {
                                                return (<MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                                            })
                                        }
                                    </TextField>
                                </FormControl>}
                            />
                            {errors.city && <span className={'label-error'}>La Ville/Commune est obligatoire</span>}

                        </div>
                      <div className="col-md-4">
                        <FormControl style={{width: '100%'}} className={'vertical-center'}>
                          <TextField value={address} label="Adresse" variant="outlined" size="small"
                                     style={{marginTop: '10px'}}
                                     onChange={(event) => setAddress(event.target.value)}/>
                        </FormControl>
                      </div>
                    </div>


                    <div className="row mt-2 mb-1 mt-2">
                        <div className="col-md-12">
                            <div style={{textAlign: 'center'}}>
                                <Button variant="outlinedSecondary" startIcon={<Cancel/>}
                                        style={{marginRight: '5px'}} onClick={closeModal}>
                                    Annuler
                                </Button>
                                <Button variant="containedPrimary" endIcon={<Save/>} type="submit">
                                  {addAction && <>Ajouter</>}
                                  {!addAction && <>Modifier</>}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>

            </Box>
        </Modal>
    );
}

export default NewTrainingOperator;
