/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Dropdown, Nav, Tab} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import {ReactComponent as CompilingIcon} from "../../../shared/layout/assets/layout-svg-icons/Compiling.svg";
import Notification from "../../pages/home/Notifications";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Add, Save } from "@material-ui/icons";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { addNotification } from "../../crud/notification.crud";
import { FormControl, MenuItem, TextField } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { getAllUsers } from "../../crud/training.crud";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};

export default class UserNotifications extends React.Component {
    state = {key: "Alerts", open: false, notification: null, users: [], selectedUsers: []};
    componentDidMount = () => {
      getAllUsers().then(response => {
          this.setState({...this.state, users: response.data})
      })
    };
    handleChangeUsers = (e) => {
      this.setState({...this.state, selectedUsers: e.target.value})
    }

    handleSelectAll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const ids = this.state.users.map(e => e.id).slice();

      this.handleChangeUsers({target: {value: ids}})
    }
    addNotification = () => {
     addNotification(
       {
         subject: null,
         body: this.state.notification,
         from: null,
         read: true,
         to: null
       }
    , this.state.selectedUsers).then(e => {
        this.handleClose();
     })
    };

    handleOpen = () => {
        this.setState({...this.state, open: true});
    };

    handleClose = () => {
        this.setState({...this.state, open: false});
    };

    getHeaderTopBarCssClassList = () => {
        let result = "kt-header__topbar-icon ";
        if (this.props.pulse) {
            result += "kt-pulse ";
        }

        const {iconType} = this.props;
        if (iconType) {
            result += `kt-header__topbar-icon--${iconType}`;
        }

        return result;
    };

    getSvgCssClassList = () => {
        let result = "kt-svg-icon ";
        const {iconType} = this.props;
        if (iconType) {
            result += `kt-svg-icon--${iconType}`;
        }

        return result;
    };

    getHetBackGroundCssClassList = () => {
        let result = "kt-head ";
        if (this.props.skin) {
            result += `kt-head--skin-${this.props.skin} `;
        }

        result += "kt-head--fit-x kt-head--fit-b";
        return result;
    };

    backGroundStyle = () => {
        if (!this.props.bgImage) {
            return "none";
        }

        return "url(" + this.props.bgImage + ")";
    };


    ulTabsClassList = () => {
        let result = "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ";
        if (this.props.type) {
            result += `nav-tabs-line-${this.props.type} `;
        }

        result += "kt-notification-item-padding-x";
        return result;
    };

    render() {
        const {key} = this.state;
        const {useSVG, icon, pulse} = this.props;
        return (
            <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
                <Dropdown.Toggle
                    as={HeaderDropdownToggle}
                    id="dropdown-toggle-user-notifications"
                >
          <span className={this.getHeaderTopBarCssClassList()}>
            {!useSVG && <i className={icon}/>}

              {useSVG && (
                  <span className={this.getSvgCssClassList()}>
                <CompilingIcon/>
              </span>
              )}

              <span className="kt-pulse__ring" hidden={!pulse}/>
            <span className="kt-hidden kt-badge kt-badge--dot kt-badge--notify kt-badge--sm"></span>

          </span>
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
                    <form>
                        {/** Head */}
                        <div
                            className={this.getHetBackGroundCssClassList()}
                            style={{backgroundImage: this.backGroundStyle()}}
                        >
                            <h3 className="kt-head__title">
                                Notifications{' '} {' '}

                            </h3>

                            <Tab.Container
                                defaultActiveKey={key}
                                className={this.ulTabsClassList()}
                            >
                                <Nav
                                    className={this.ulTabsClassList()}
                                    onSelect={_key => this.setState({key: _key})}
                                >
                                    <Nav.Item className="nav-item">
                                        <Nav.Link eventKey="Alerts" className="nav-link show">
                                            Reçues
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Events" className="nav-link show">
                                            Envoyées
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Logs" className="nav-link show">
                                            Logs
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="Alerts">
                                        <PerfectScrollbar
                                            options={perfectScrollbarOptions}
                                            style={{maxHeight: "100vh", position: "relative"}}
                                        >
                                            <div
                                                className="kt-notification kt-margin-t-10 kt-margin-b-10"
                                                style={{maxHeight: "40vh", position: "relative"}}
                                            >
                                                <div
                                                    className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                                                    data-scroll="true"
                                                    data-height="300"
                                                    data-mobile-height="200"
                                                    style={{minHeight: 200}}
                                                >
                                                    <Notification type={'received'}/>
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Events">
                                        <PerfectScrollbar
                                            options={perfectScrollbarOptions}
                                            style={{maxHeight: "100vh", position: "relative"}}
                                        >
                                            <div
                                                className="kt-notification kt-margin-t-10 kt-margin-b-10"
                                                style={{maxHeight: "40vh", position: "relative"}}
                                            >
                                                <div
                                                    className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                                                    data-scroll="true"
                                                    data-height="300"
                                                    data-mobile-height="200"
                                                    style={{minHeight: 200}}
                                                >
                                                    <Button variant="containedPrimary" endIcon={<Save/>}  onClick={this.handleOpen} >
                                                        Nouvelle notification
                                                    </Button>
                                                    <Notification type={'sent'}/>
                                                    <Modal
                                                        keepMounted
                                                        open={this.state.open}
                                                        onClose={this.handleClose}
                                                        aria-labelledby="keep-mounted-modal-title"
                                                        aria-describedby="keep-mounted-modal-description"
                                                    >
                                                        <Box sx={style}>
                                                            <Typography id="keep-mounted-modal-title" variant="h6"
                                                                        component="h2">
                                                                Nouvelle notification
                                                            </Typography>
                                                            <br/>
                                                            <div className="row">
                                                              <div className="col-md-12">
                                                                <TextareaAutosize
                                                                  aria-label="minimum height"
                                                                  value={this.state.notification}
                                                                  onChange={ (e) => {this.setState({...this.state, notification: e.target.value})}}
                                                                  minRows={3}
                                                                  placeholder="Écrire ici"
                                                                  style={{ width: '100%' }}
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                  <FormControl>

                                                                    <TextField
                                                                      select
                                                                      key={'users-select'}
                                                                      value={this.state.selectedUsers}
                                                                      size={'small'}
                                                                      variant="outlined"
                                                                      label="Destinataires"
                                                                      SelectProps={{
                                                                        multiple: true
                                                                      }}
                                                                      onChange={this.handleChangeUsers}
                                                                    >
                                                                      {/*{
                                                                        this.state.users.length != this.state.selectedUsers.length &&
                                                                        <Button onClick={this.handleSelectAll}>
                                                                          selectionner tous les utilisateurs
                                                                        </Button>
                                                                      }
*/}

                                                                      {this.state.users.map((e) => (
                                                                        <MenuItem key={e.id} value={e.id}>
                                                                          {e.lastName + ' ' + e.firstName + ' (' + e.establishment + ')'}
                                                                        </MenuItem>
                                                                      ))}
                                                                    </TextField>

                                                                  </FormControl>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div style={{textAlign: 'center'}}>
                                                                      <Button variant="containedPrimary" endIcon={<Add/>} onClick={() => {
                                                                        this.addNotification()
                                                                      }}>
                                                                        Envoyer
                                                                      </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Logs">
                                        <div
                                            className="kt-grid kt-grid--ver"
                                            style={{minHeight: "200px"}}
                                        >
                                            <div
                                                className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                                                <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </form>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
