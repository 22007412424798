import axios from "axios";

const REFERENTIAL_QUIZ_REPORT_URL = "reporting/referential-quiz";
const AGGREGATED_CRITERIA_URL = "reporting/aggregated-criteria-report";
const IMPROVEMENT_AXES_URL = "reporting/improvement-axes-data";
const TRAINING_OPERATOR_REPORT_DATA_URL = "reporting/training-operator-data";
const DFP_REPORT_DATA_URL = "reporting/dfp-report-data";
const GLOBAL_MATURITY_REPORT_DATA_URL = "reporting/global-maturity-data";
const GENREATE_EXCEL_URL = "reporting/excel";
const EFP_PAR_SESSION = "reporting/efp-par-session";

export function downloadReferentialReport(year, trainingInstituteId, trainingInstituteName) {
  return downloadPdf(REFERENTIAL_QUIZ_REPORT_URL, {
    trainingInstituteId,
    year
  }, `Referentiel-${trainingInstituteName}-${year}.xlsx`);
}


export function getEnhancementAxisData(year,operator,user) {
  if(user["role"]=="ROLE_DFP")
   {
    return axios.get(IMPROVEMENT_AXES_URL, { params: { year,operator} });
   }else{
    return axios.get(IMPROVEMENT_AXES_URL, { params: { year } });

   }
}


export function getAggregatedCriteriaReport(year) {
  axios.get(AGGREGATED_CRITERIA_URL, { params: { year }, responseType: "blob" })
    .catch(err => {
      alert('Une erreure technique lors du telechargement du rapport !')
      throw err;
    })
    .then((response) => saveFile(response.data, `Rapport.xlsx`))
}


export function getEFfpParSession() {
  axios.get(EFP_PAR_SESSION, { responseType: "blob" })
    .catch(err => {
      alert('Une erreure technique lors du telechargement du rapport !')
      throw err;
    })
    .then((response) => saveFile(response.data, `Efp_par_session.xlsx`))
}

export function getTrainingOperatorReportData(year) {
  return axios.get(TRAINING_OPERATOR_REPORT_DATA_URL, { params: { year } });
}

export function getDfpReportData(year) {
  return axios.get(DFP_REPORT_DATA_URL, { params: { year } });
}

export function getGlobalMaturityData(year) {
  return axios.get(GLOBAL_MATURITY_REPORT_DATA_URL, { params: { year } });
}

export function saveImprovementAxesUserInput(data) {
  return axios.post(IMPROVEMENT_AXES_URL, data);
}

export function saveTrainingOperatorReportUserInput(data) {
  return axios.post(TRAINING_OPERATOR_REPORT_DATA_URL, data);
}

export function saveDfpReportUserInput(data) {
  return axios.post(DFP_REPORT_DATA_URL, data);
}


export function pushDataAndDownloadPdf(data, params, filename, successMessage, errorMessage) {
  axios.post(GENREATE_EXCEL_URL, data, { responseType: "blob", params: params })
    .catch(err => {
      if (errorMessage)
        alert(errorMessage);
      throw err;
    })
    .then(response => {
      if (successMessage)
        alert(successMessage);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
}

function downloadPdf(URL, params, filename, successMessage, errorMessage) {
  axios.get(URL, { responseType: "blob", params: params })
    .catch(err => {
      if (errorMessage)
        alert(errorMessage);
      throw err;
    })
    .then(response => {
      if (successMessage)
        alert(successMessage);
      saveFile(response.data, filename);
    });
}


function saveFile(blob,filename){
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}
