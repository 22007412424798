import React from "react";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import QuickPanelToggler from "./QuickPanelToggle";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import Chip from "@material-ui/core/Chip/Chip";
import { getRoleLabel } from "../../../app/utils/roles.utils";

const Topbar = ({ user }) => {
  const getOperator = () => {
    if (user.establishment) return  ' ('+user.establishment+')';
    return '';
  }
  return (
    <div className="kt-header__topbar kt-grid__item">
      <div style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
        <Chip color="secondary" label={'Connecté en tant que ' + getRoleLabel(user.roles[0])  + getOperator()}/>
      </div>

      <UserNotifications
        bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
        pulse="true"
        pulseLight="false"
        skin="dark"
        iconType=""
        isLabel={false}
        type="success"
        useSVG="true"
        dot="false"
      />

      <QuickPanelToggler/>

      <UserProfile showHi={true} showBadge={true}/>
    </div>
  );
};


export default Topbar;
