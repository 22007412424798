import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as processManagement from "./ducks/process-management.duck";
import { metronic } from "../../shared";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  processManagement: processManagement.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer
});

export function* rootSaga() {
  yield all([auth.saga(), processManagement.saga()]);
}
