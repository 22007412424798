import React, { useState, useEffect } from "react";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import InstituteIdentity from "./InstituteIdentity";
import GeneralInformations from "./GeneralInformations";
import Formations from "./Formations";
import { Cancel, Save } from "@material-ui/icons";
import {
  addOrUpdateTrainingInstitute,
} from "../../../../../crud/trainingInstitute.crud";
import { useNotification } from "../../../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";

const NewTrainingInstitute = ({ user, openModalValue, closeModal, dataToModify, addAction }) => {

  const city = dataToModify.city ? dataToModify.city.id : null;
  const trainingOperator = dataToModify.trainingOperator ? dataToModify.trainingOperator.id : null;
  const trainingComplex = dataToModify.trainingComplex ? dataToModify.trainingComplex.id : null;
  const areaZone = dataToModify.areaZone ? dataToModify.areaZone.id : null;
  const instituteStatus = dataToModify.instituteStatus ? dataToModify.instituteStatus.id : null;
  const [identityData, setIdentityData] = useState({
    dfpCodification: dataToModify.dfpCodification,
    trainingOperatorCodification: dataToModify.trainingOperatorCodification,
    name: dataToModify.name,
    address: dataToModify.address,
    nbTextOfCreation: dataToModify.nbTextOfCreation,
    creationDate: dataToModify.creationDate,
    trainingComplex: trainingComplex,
    city: city,
    trainingOperator: trainingOperator
  });

  const [generalInormationsData, setGeneralInformationsData] = useState({
    email: dataToModify.email,
    instituteStatus: instituteStatus,
    phoneNumber: dataToModify.phoneNumber,
    areaZone: areaZone
  });

  const [formationsData, setFormationData] = useState({
    trainingSectors: dataToModify.trainingSectors.map(e => e.id),
    branches: dataToModify.branches.map(e => e.id),
    learningByDoingMode: dataToModify.learningByDoingMode,
    trainingTypes: dataToModify.trainingTypes.map(e => e.id)
  });

  const [disableAdd, setDisableAdd] = useState(false);
  const [capacityZoneData, setCapacityZoneData] = useState({
    /*pedagogicalCapacityInternal: null,
    accommodationCapacity: null,
    */currentWorkforce: null,
    areaOfOrigin: []
  });

  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  const handleIdentityData = (data) => {
    console.log(data);
    setIdentityData(data);
  };
  const handleGeneraleInformationsData = (data) => {
    setGeneralInformationsData(data);
  };
  const handleFormationsData = (data) => {
    setFormationData(data);
  };

  const validTi = () => {
    return identityData.name && identityData.region && identityData.city && identityData.trainingOperator && identityData.creationDate;
  };

  const handleAddNewInstitute = () => {
    setDisableAdd(true);
    if (validTi()) {
      addOrUpdateTrainingInstitute({
        ...identityData, ...generalInormationsData, ...capacityZoneData, ...formationsData,
        id: addAction ? undefined : dataToModify.id
      }, addAction === false)
        .catch(err => {
          if (err.response?.data?.message) {
            notifyError(err.response?.data?.message);
          } else {
            notifyError("Une erreure technique lors de l'ajoute de l'EFP !");
          }
          throw err;
        })
        .then(response => {
          if (response.data) {
            notifySuccess(addAction ? "EFP Ajouté avec success" : "EFP a été modifié avec success");
            setDisableAdd(false);
            closeModal();
          }
        });
    } else {
      setDisableAdd(false);
      notifyError("Veuillez  svp vérifier les champs obligatoires");
    }
  };


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "auto",
    maxHeight: "65%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  };

  return (
    <Modal
      open={openModalValue}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      key={"addNewTI"}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Ajouter un nouvel établissement de formation
        </Typography>
        <InstituteIdentity user={user} identityData={identityData} key={"instituteIdentity"}
                           handleIdentityData={handleIdentityData} tiId={dataToModify.id}/>
        <GeneralInformations generalInformationsData={generalInormationsData}
                             handleGeneraleInformationsData={handleGeneraleInformationsData}/>
        <Formations formationsData={formationsData} handleFormationsData={handleFormationsData}/>
        {/*<WorkForceRepartition workForceData={workForceRepartitionData}
                                      handleWorkForceData={handleWorkForceData}/>*/}
        {/*
                <CapacityZone capacityZoneData={capacityZoneData} handleCapacityZoneData={handleCapacityZoneData}/>
*/}
        <div className="row mt-2 mb-4">
          <div className="col-md-12">
            <div style={{ textAlign: "center" }}>
              <Button variant="outlinedSecondary" startIcon={<Cancel/>}
                      style={{ marginRight: "5px" }} onClick={() => closeModal()}> Annuler</Button>
              <Button variant="containedPrimary" endIcon={<Save/>}
                      onClick={() => handleAddNewInstitute()} disabled={disableAdd}>
                {addAction && <>Ajouter</>}
                {!addAction && <>Modifier</>}
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default NewTrainingInstitute;
