import axios from "axios";

const GET_REF_PROCESS_URL = "process/ref";
const REF_PROCESS_URL = "process";
const REF_STANDARD_URL = "process/standard";
const GET_REF_STANDARDS_URL = "process/standards";
const REF_CRITERIA_URL = "process/criteria";
const REF_CRITERIA_ORDER_URL = "process/criteria/order";

export function getRefProcess() {
  return axios.get(GET_REF_PROCESS_URL);
}

export function saveProcess(label) {
  return axios.post(REF_PROCESS_URL, { value: label });
}

export function updateProcess(id, label) {
  return axios.put(REF_PROCESS_URL, { id, value: label });
}

export function deleteProcessById(id) {
  return axios.delete(REF_PROCESS_URL, { params: { id } });
}

export function getRefStandards() {
  return axios.get(GET_REF_STANDARDS_URL);
}

export function getStandardById(id) {
  return axios.get(REF_STANDARD_URL.concat("/", id));
}

export function saveStandard(label, processId) {
  return axios.post(REF_STANDARD_URL, { value: label }, { params: { processId } });
}

export function updateStandard(id, label, processId) {
  return axios.put(REF_STANDARD_URL, { id, value: label }, { params: { processId } });
}

export function deleteStandardById(id) {
  return axios.delete(REF_STANDARD_URL, { params: { id } });
}

export function saveCriteria(criteria) {
  return axios.post(REF_CRITERIA_URL, criteria);
}

export function upateCriteria(criteria) {
  return axios.put(REF_CRITERIA_URL, criteria);
}

export function updateCriteriaOrder(changes) {
  return axios.put(REF_CRITERIA_ORDER_URL, changes);
}

export function deleteCriteria(criteriaId) {
  return axios.delete(REF_CRITERIA_URL, { params: { criteriaId } });
}




