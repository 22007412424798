/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import dfpManual from './user-guide-files/MU-DFP.pdf';
import efpManual from './user-guide-files/MU-EFP.pdf';
import ofManual from './user-guide-files/MU-OF.pdf';
import cfManual from './user-guide-files/MU-OF.pdf';
import drManual from './user-guide-files/MU-OF.pdf';
import adminManual from './user-guide-files/MU-DFP.pdf';
import { Roles } from "../../utils/roles.utils";

const StickyToolbar = ({user}) => {

  const userRoleToManualFile = {
    [Roles.ADMIN]: adminManual,
    [Roles.DFP]: dfpManual,
    [Roles.OF]: ofManual,
    [Roles.EFP_SAISIE]: efpManual,
    [Roles.EFP_VALIDATEUR]: efpManual,
    [Roles.CF]: cfManual,
    [Roles.DR]: drManual
  };

  return (
    <>
      <ul className="kt-sticky-toolbar" style={{ marginTop: "30px" }}>

        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="layout-tooltip">Manuel d'utilisation</Tooltip>}
        >
          <li
            className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
            data-placement="left"
          >
            <a href={userRoleToManualFile[user.role]} download={"Manuel d'utilisation-" + user.role.replace('ROLE_', '') + ".pdf"}>
              <i className="flaticon2-help" />
            </a>
          </li>
        </OverlayTrigger>

        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="layout-tooltip">Bibliothèque</Tooltip>}
        >
          <li
            className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
            data-placement="left"
          >
            <a target="_blank" href="https://drive.google.com/drive/folders/1_ZIP56OLQGjJzHi0ui4vkDjsT1KnQmM4?usp=sharing">
              <i className="flaticon2-open-text-book" />
            </a>
          </li>
        </OverlayTrigger>


      </ul>
    </>
  );

};


export default StickyToolbar;
