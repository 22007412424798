import React, { useEffect, useState } from "react";

import PageLoading from "../../../../shared/component/PageLoading";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { includes } from "lodash";
import { ArrowBack } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { getAllRegions } from "../../../crud/training.crud";
import { actionConfirm } from "../../../../shared/utils/confirm.util";
import { useConfirm } from "material-ui-confirm";
import {
  deleteCity,
  deleteRegion,
  saveCity,
  saveRegion,
  updateCity,
  updateRegion
} from "../../../crud/region-management.crud";
import { SectorTable } from "./sector-table";
import AddNewSector from "./AddNewSector";
import { getAllTrainingSectors } from "../../../crud/trainingInstitute.crud";
import {
  deleteBranch,
  deleteSector, saveBranch,
  saveSector,
  updateBranch,
  updateSector
} from "../../../crud/sector-management.crud";


const SectorManagement = () => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());
  const confirm = actionConfirm(useConfirm());
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getAllTrainingSectors()
      .catch(err => {
        notifyError("Une erreure technique est survenue lors du chargement de donnees");
        throw err;
      })
      .then(response => {
        setData(response.data);
        setLoading(false);
      });
  };

  const updateItem = (type, payload, callback) => {
    switch (type) {
      case "region":
        updateSector(payload.id, payload.name)
          .catch(err => {
            notifyError("Une erreure technique est survenue lors de la mise a jour du secteur !");
            throw err;
          })
          .then(() => {
            notifySuccess("Le secteur est mise a jour avec succes !");
            loadData();
            callback();
          });
        break;
      case "city":
        updateBranch(payload.id, payload.name)
          .catch(err => {
            notifyError("Une erreure technique est survenue lors de la mise à jour de la filière !");
            throw err;
          })
          .then(() => {
            notifySuccess("La filière est mise à jour avec succes !");
            loadData();
            callback();
          });
        break;
      default:
        console.error("Unsupported type", type);
    }
  };

  const deleteItem = (type, id) => {
    confirm().then(() => {
      switch (type) {
        case "region":
          deleteSector(id)
            .catch(err => {
              notifyError("Une erreure technique est survenue lors de la supression du secteur !");
              throw err;
            })
            .then(() => {
              notifySuccess("Le secteur est supprimé avec succes !");
              loadData();
            });
          break;
        case "city":
          deleteBranch(id)
            .catch(err => {
              notifyError("Une erreure technique est survenue lors de la supression de la filière !");
              throw err;
            })
            .then(() => {
              notifySuccess("La fillière est supprimee avec succes !");
              loadData();
            });
          break;
        default:
          console.error("Unsupported type", type);
      }
    });
  };

  const addRegion = (name, closeModal) => {
    saveSector(name)
      .catch(err => {
        notifyError("Une erreure technique est survenue lors de l'ajout de la region !");
        throw err;
      })
      .then(() => {
        notifySuccess("La region est ajoute avec succes !");
        loadData();
        closeModal();
      });
  };

  const addCity = (name, regionId, closeModal) => {
    saveBranch(name, regionId)
      .catch(err => {
        notifyError("Une erreure technique est survenue lors de l'ajout de la ville !");
        throw err;
      })
      .then(() => {
        notifySuccess("La filière est ajoute avec succes !");
        loadData();
        closeModal();
      });
  };


  if (loading)
    return <PageLoading/>;
  return (
    <>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <div className="row mb-4">
            <div className="col-md-4">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span>Paramétrage des secteurs</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <div className={"filter-btn-div"}>
                <Button variant="outlinedPrimary" startIcon={<ArrowBack/>}
                        onClick={() => window.history.back()}
                        style={{ marginRight: "5px" }}>
                  Retour
                </Button>
              </div>
            </div>
          </div>
          <AddNewSector addNewRegion={addRegion}/>
          <SectorTable rows={data} setRows={setData}
                       updateItem={updateItem}
                       deleteItem={deleteItem}
                       addCity={addCity}
                       key={"regions-table"}/>
        </div>
      </div>
    </>
  );
};


export default SectorManagement;
