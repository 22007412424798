export const NEW_YEAR_DATA = {
  year: null,
  numberOfRows: 8,
  data: {
    trainees: {
      label: "Stagiaires",
      data: [
        { field: "nbOfRegistrants1", label: "Nombre des inscrits pour la première année de l’année en cours", value: "" },
        { field: "nbOfRegistrants2", label: "Nombre des inscrits pour la deuxième année de l’année en cours", value: "" },
        { field: "nbOfRegistrants3", label: "Nombre des inscrits pour la troisième année de l’année en cours", value: "" },
        { field: "nbOfSuccessPrevious1", label: "Nombre des stagiaires ayant réussi la première année (de l’année n-1)", value: "" },
        { field: "nbOfRegistrantsPrevious1", label: "Nombre des stagiaires inscrits en première année (de l’année n-1)", value: "" },
        { field: "nbOfSuccessPrevious2", label: "Nombre des stagiaires ayant réussi la deuxième année (données de l’année n-1)", value: "" },
        { field: "nbOfRegistrantsPrevious2", label: "Nombre des stagiaires inscrits en deuxième année (données de l’année n-1)", value: "" },
        { field: "nbOfSuccessPrevious3", label: "Nombre de stagiaires ayant réussi la troisième année de formation (données de l’année n-1)", value: "" },
        { field: "nbOfRegistrantsPrevious3", label: "Nombre de stagiaires inscrits à la troisième année de formation (données de l’année n-1)", value: "" },
        { field: "nbOfGraduatedPrevious", label: "Nombre des lauréats (données de l’année n-1)", value: "" },
        { field: "nbOfRegistrantsPromotionPrevious", label: "Nombre des stagiaires inscrit en première année de la même promotion (données de l’année n-3 si la formation est de deux ans  ou n-4 si la formation en 3 ans", value: "" },
        { field: "dropoutsPrevious1", label: "Nombre des abandons pour la première année (données de l’année n-1)", value: "" },
        { field: "dropoutsPrevious2", label: "Nombre des abandons pour la deuxième année (données de l’année n-1)", value: "" },
        { field: "dropoutsPrevious3", label: "Nombre des abandons pour la troisième année (données de l’année n-1)", value: "" },
        { field: "nbOfTeachingPlacesAvailable", label: "Nombre des places pédagogiques disponibles (données de l’année en cours)", value: "" },
        { field: "nbOfApplicants", label: "Nombre des postulants (données de l’année en cours )", value: "" },
        { field: "nbOfGraduatedEmployedDuringThe9MonthsAfterGraduation", label: "Nombre des diplômés ayant occupé un emploi durant les 9 mois après graduation (données année n-2)", value: "" },
        { field: "nbOfGraduatedPrevious2", label: "Nombre de lauréats de la même année (données année n-2)", value: "" },
        { field: "numberOfForeignInterns", label: "Nombre des stagiaires étrangers (année en cours)", value: "" },
        { field: "nbOfGlobalInterns", label: "Nombre global des stagiaires (année en cours)", value: "" },
        { field: "firstAge", label: "Nombre de stagiaires Moins de 18 ans (données de l’année en cours)", value: "" },
        { field: "secondAge", label: "Nombre de stagiaires Entre 19 et 24 ans (données de l’année en cours)", value: "" },
        { field: "thirdAge", label: "Nombre de stagiaires Plus de 24 ans (données de l’année en cours)", value: "" },
        { field: "nbOfTraineesWithSpecialNeeds", label: "Nombre de stagiaires à besoins spécifiques", value: "" },
        { field: "internsComingFromTheUniversity", label: "Nombre des stagiaires en provenance de l’université (données de l’année en cours)", value: "" },
      ]
    },
    learners: {
      label: "Apprentissage",
      data: [
        { field: "nbOfRegistrantsLearners1", label: "Nombre des inscrits en apprentissage pour la première année de l’année en cours", value: "" },
        { field: "nbOfRegistrantsLearners2", label: "Nombre des inscrits apprentissage pour la deuxième année de l’année en cours ", value: "" },
        { field: "nbOfSuccessPreviousLearners1", label: "Nombre des apprentis ayant réussi la première année (de l’année n-1)", value: "" },
        { field: "nbOfRegistrantsPreviousLearners1", label: "Nombre des apprentis inscrits en première année (de l’année n-1)", value: "" },
        { field: "nbOfSuccessPreviousLearners2", label: "Nombre des apprentis ayant réussi la deuxième année (données de l’année n-1)", value: "" },
        { field: "nbOfRegistrantsPreviousLearners2", label: "Nombre des apprentis inscrits en deuxième année (données de l’année n-1)", value: "" },
        { field: "nbOfGraduatedLearnersPrevious", label: "Nombre des lauréats (données de l’année n-1)", value: "" },
        { field: "nbOfRegistrantsPromotionLearnersPrevious", label: "Nombre des apprentis inscrit en première année de la même promotion :\n" +
            " - données de l’année n-2 si la formation est de 1 an" +
            " ou données de l’année n-3si la formation est de deux ans)", value: "" },
        { field: "dropoutsLearnerPrevious1", label: "Nombre des abandons pour la première année (données de l’année n-1)", value: "" },
        { field: "dropoutsLearnersPrevious2", label: "Nombre des abandons pour la deuxième année (données de l’année n-1)", value: "" },
        { field: "learnersEmployedDuringThe9MonthsAfterGraduation", label: "Nombre des diplômés de la formation par apprentissage ayant occupé un emploi durant les 9 mois après graduation (données année n-2)", value: "" },
        { field: "nbOfGraduatedLearnersSameYear", label: "Nombre de lauréats de la formation par apprentissage  de la même année (données année n-2)", value: "" },
        { field: "nbOfAllLearners", label: "Nombre global des apprentis (année en cours)", value: "" },
        { field: "learnersFirstAge", label: "Nombre des apprentis Moins de 18 ans (données de l’année en cours)", value: "" },
        { field: "learnersSecondAge", label: "Nombre de apprentis Entre 19 et 24 ans (données de l’année en cours)", value: "" },
        { field: "learnersThirdAge", label: "Nombre de apprentis  Plus de 24 ans (données de l’année en cours)", value: "" },
        { field: "nbOfLearnersTraineesWithSpecialNeeds", label: "Nombre de apprentis à besoins spécifiques", value: "" },
        { field: "learnersInternsComingFromTheUniversity", label: "Nombre des apprentis en provenance de l’université (données de l’année en cours)", value: "" },
        { field: "cumulationOfLearnersGraduated", label: "Effectif des lauréats formés par apprentissage  dans le cadre de la dernière convention « formation par apprentissage » ", value: "" },
        { field: "expectedLearnersGraduated", label: "Effectif des lauréats prévu dans le cadre de la dernière convention « formation par apprentissage »", value: "" },
      ]
    },
    trainers: {
      label: "Formateurs",
      data: [
        { field: "nbOfTrainers", label: "Nombre global des formateurs pour l’année en cours", value: "" },
        { field: "nbOfPermanentTrainers", label: "Nombre de formateurs permanents seulement pour l’année en cours", value: "" }

      ]
    },

    Capacity: {
      label: "Capacité",
      data: [
        { field: "nbOfBranches", label: "Nombre de filières implantées depuis la création de l’établissement", value: "" },
        { field: "removedBranches", label: "Nombre de filières supprimées depuis la création de l’établissement", value: "" },
        { field: "cumulationOfGraduated", label: "Cumule des lauréats formés dans le cadre des conventions « formation par apprentissage » (données pour l’ensemble des conventions signés jusqu’à l’année n-1)", value: "" },
        { field: "expectedGraduated", label: "L’effectif des lauréats prévu dans les conventions (données pour l’ensemble des conventions signés jusqu’à l’année n-1)", value: "" },
        { field: "boysAccommodated", label: "Nombre des stagiaires garçons hébergés", value: "" },
        { field: "girlsAccommodated", label: "Nombre des stagiaires filles hébergées", value: "" },
        { field: "budget", label: "Budget global annuel alloué à la formation", value: "" },

      ]
    }

  }
};
