export const indicatorsHeaders = [
  {
    criteria: "Nombre d’inscrits durant l’année",
    forumula: "Nombre des inscrits (première année + deuxième année + troisième année)" +
      " - Donnée de l’année en cours",
    result: "regestrants",
    suffix: "",
    type: 0
  }, {
    criteria: "Taux de réussite de la 1 ere année",
    forumula: "Nombre de stagiaires ayant réussi la 1 ere année/nombre de stagiaires inscrits en 1 ere année \n" +
      " - Donnée de l’année n-1",
    result: "success1",
    suffix: "%",
    type: 0
  }, {
    criteria: "Taux de réussite de la 2ème année",
    forumula: "Nombre de stagiaires ayant réussi la 2 eme année/nombre de stagiaires inscrits en 2 eme année\n" +
      " - Données de l’année n-1",
    result: "success2",
    suffix: "%",
    type: 0
  }, {
    criteria: "Taux de réussite de la 3ème année",
    forumula: "Nombre de stagiaires ayant réussi la 3eme année/nombre de stagiaires inscrits en 3 eme année\n" +
      " - Données de l’année n-1\n",
    result: "success3",
    suffix: "%",
    type: 0
  }, {
    criteria: "Taux de diplômation",
    forumula: "Nombre de lauréats / nombre de stagiaires inscrit 1ère année de la même promotion\n" +
      "Données des lauréats de l’année n-1 \n" +
      "Données des inscrits de l’année n-3 si formation de 2ans\n" +
      " ou Données des inscrits de l’année n-4 si formation en 3ans \n" +
      "\n",
    result: "graduationComission",
    suffix: "%",
    type: 0

  }, {
    criteria: "Taux d’abandon par année en 1 ere année",
    forumula: "Nombre des abandons 1 ere année/Nombre des inscrits de l’année\n" +
      "Données de l’année n-1\n",
    result: "dropOut1",
    suffix: "%",
    type: 0
  }, {
    criteria: "Taux d’abandon par année en 2 eme année",
    forumula: "Nombre des abandons 2 eme année/Nombre des inscrits de l’année\n" +
      "Données de l’années n-1\n",
    result: "dropOut2",
    suffix: "%",
    type: 0
  }, {
    criteria: "Taux d’abandon par année en 3 eme année",
    forumula: "Nombre des abandons 3 eme année/Nombre des inscrits de l’année\n" +
      "Données de l’année n-1\n" +
      "\n",
    result: "dropOut3",
    suffix: "%",
    type: 0
  }, {
    criteria: "Taux d’affluence ",
    forumula: "Nombre des postulants/Nombre de places pédagogiques disponibles\n" +
      "Données de l’année n\n",
    result: "affluence",
    suffix: " (Nombre de candidats pour une  place pédagogique)",
    type: 0
  }, {
    criteria: "Taux d’encadrement global",
    forumula: "Nombre des stagiaires /Nombre global de formateurs\n" +
      "Années en cours \n" +
      "\n",
    result: "globalSupervision",
    suffix: " (Nombre de stagiaires pour un formateur)",
    type: 0
  }, {
    criteria: "Taux d’encadrement ( formateurs permanents)",
    forumula: "Nombre des stagiaires / Nombre global de formateurs permanents \n" +
      "Années en cours\n",
    result: "supervisionRate",
    suffix: " (Nombre de stagiaires pour un formateur permanent)",
    type: 0
  }, {
    criteria: "Taux d’insertion professionnelle des lauréats",
    forumula: "Nombre de diplômés ayant occupé un emploi 9 mois après diplomation/Nombre de lauréats de la même promotion\n" +
      "Données année n-2 \n",
    result: "integrationRate",
    suffix: "%",
    type: 0
  }, {
    criteria: "Nombre des stagiaires étrangers",
    forumula: "Nombre des stagiaires étrangers  \n" +
      "pour l’année en cours \n",
    result: "foreignRate",
    suffix: "",
    type: 0
  }, {
    criteria: "Répartition des stagiaires inscrits par tranche d’âge -  -18 ans",
    forumula: " (Nombre de stagiaires de moins de 18ans /Nombre total des stagiaires) X 100\n" +
      "\n" +
      " Données de l’année en cours",
    result: "ageMax18",
    suffix: "%",
    type: 0
  }, {
    criteria: "Répartition des stagiaires inscrits par tranche d’âge -  Entre 18 et 24 ans",
    forumula: "((Nombre de stagiaires entre 19 et24 ans / Nombre total des stagiaires = nb stagiaires 1ère année + 2ème année +3ième année) X 100\n" +
      "Année en cours \n",
    result: "ageBetween18_24",
    suffix: "%",
    type: 0
  }, {
    criteria: "Répartition des stagiaires inscrits par tranche d’âge -  plus de 24 ans",
    forumula: "(Nombre de stagiaires de plus de 24 ans /Nombre total des stagiaires) X 100\n" +
      "\n" +
      "Année en cours \n",
    result: "ageMin_24",
    suffix: "%",
    type: 0
  }, {
    criteria: "Nombre de filières implantées depuis la création de l’établissement",
    forumula: "Nombre de filières implantées depuis la création de l’établissement",
    result: "nbOfBranches",
    suffix: "",
    type: 0
  }, {
    criteria: "Nombre de filières supprimées depuis la création de l’établissement",
    forumula: "Nombre de filières supprimées depuis la création de l’établissement",
    result: "removedBranches",
    suffix: "",
    type: 0
  }, {
    criteria: "Nombre de stagiaires à besoins spécifiques",
    forumula: "Nombre de stagiaires à besoins spécifiques",
    result: "nbOfTraineesWithSpecialNeeds"
    ,
    suffix: "",
    type: 0
  }, {
    criteria: "Taux des stagiaires en provenance de l’université ",
    forumula: "Nombre des stagiaires en provenance de l’université/ nb stagiaires 1ère année + 2ème année\n" +
      " Année en cours  ",
    result: "internsComingFromTheUniversity",
    suffix: "%",
    type: 0
  },/* {
    criteria: "Taux de réalisation de l'exécution des conventions de FPA",
    forumula: "Cumule des lauréats formés dans le cadre de convention pour la formation par apprentissage / l’effectif des lauréats prévu dans les conventions \n" +
      "Pour l’ensemble des conventions signés jusqu’à l’année n-1\n",
    result: "executionFPA",
    suffix: "%",
    type: 0
  },*/ {
    criteria: "Coût unitaire de formation",
    forumula: "Budget global annuel alloué à la formation / nb stagiaires 1ère année + 2ème année" +
      "Pour l’ensemble des conventions signés jusqu’à l’année n-1\n",
    result: "trainingCost",
    suffix: " MAD",
    type: 0
  }, {
    criteria: "Evolution du nombre de stagiaires garçons hébergés",
    forumula: "Nombre de stagiaires garçons hébergés \n" +
      " Pour l’année en cours ",
    result: "evolutionOfAccomodationBoys",
    suffix: "",
    type: 0
  }, {
    criteria: "Evolution du nombre de stagiaires filles hébergées",
    forumula: "Nombre de stagiaires filles hébergées \n" +
      " Pour l’année en cours ",
    result: "evolutionOfAccomodationGirls",
    suffix: "",
    type: 0
  }, {
    criteria: "Nombre d’inscrits apprentis durant l’année",
    forumula: "Nombre des apprentis inscrits (première année + deuxième année )\n" +
      " - Donnée de l’année en cours \n",
    result: "regestrantsLearners",
    suffix: "",
    type: 1
  }, {
    criteria: "Taux de réussite des apprentis  de la 1 ere année",
    forumula: "Nombre de apprentis ayant réussi la 1 ere année/nombre de stagiaires inscrits en 1 ere année \n" +
      " - Donnée de l’année n-1\n",
    result: "successLearners1",
    suffix: "%",
    type: 1
  }, {
    criteria: "Taux de réussite des apprentis de la 2ème année",
    forumula: "Nombre de apprentis ayant réussi la 2 eme année/nombre de stagiaires inscrits en 2 eme année Données de l’année n-1",
    result: "successLearners2",
    suffix: "%",
    type: 1
  }, {
    criteria: "Taux de diplomation  des apprentis",
    forumula: "" +
      "Nombre de lauréats / nombre de apprentis inscrit 1ère année de la même promotion\n" +
      " - Données des lauréats de l’année n-1 \n" +
      " - Données des inscrits apprentis de l’année n-2 si formation de 1an\n" +
      " - Données des inscrits apprentis de l’année n-3 si formation en 2an \n",
    result: "graduationComissionLearners",
    suffix: "%",
    type: 1
  }, {
    criteria: "Taux d’abandon par année en 1 ere année",
    forumula: "Nombre des abandons 1 ere année/Nombre des apprentis  inscrits de l’année\n" +
      " - Données de l’année n-1\n",
    result: "dropOutLearners1",
    suffix: "%",
    type: 1
  }, {
    criteria: "Taux d’abandon des apprentis par année en 2 eme année",
    forumula: "Nombre des abandons 2 eme année/Nombre des apprentis inscrits de l’année\n" +
      " - Données de l’années n-1\n",
    result: "dropOutLearners2",
    suffix: "%",
    type: 1
  }, {
    criteria: "Taux d’insertion professionnelle des lauréats  de al formation par apprentissage",
    forumula: "Nombre de diplômés ayant occupé un emploi 9 mois après diplomation/Nombre de lauréats de la même promotion\n" +
      " - Données année n-2 \n",
    result: "integrationLearnersRate",
    suffix: "%",
    type: 1
  }, {
    criteria: "Répartition des apprentis inscrits par tranche d’âge - -18 ans",
    forumula: "Nombre de apprentis de moins de 18ans /Nombre total des apprentis) X 100\n" +
      "\n" +
      " Données de l’année en cours\n" +
      "\n",
    result: "learnersAgeMax18",
    suffix: "%",
    type: 1
  }, {
    criteria: "Répartition des apprentis inscrits par tranche d’âge - Entre 18 et 24 ans",
    forumula: "(Nombre des apprentis entre 18 et24 ans /Nombre total des apprentis= nb apprentis 1ère année + 2ème année) X 100\n" +
      " - Année en cours \n",
    result: "learnersAgeBetween18_24",
    suffix: "%",
    type: 1
  }, {
    criteria: "Répartition des apprentis inscrits par tranche d’âge - plus de 24 ans",
    forumula: "(Nombre de apprentis de plus de 24 ans /Nombre total des apprentis) X 100\n" +
      "\n" +
      "Année en cours \n",
    result: "learnersAgeBetween18_24",
    suffix: "%",
    type: 1
  }, {
    criteria: "Nombre des apprentis  à besoins spécifiques",
    forumula: "Nombre des apprentis à besoins spécifiques",
    result: "nbOfLearnersTraineesWithSpecialNeeds",
    suffix: "",
    type: 1
  }, {
    criteria: "Taux des apprentis en provenance de l’université",
    forumula: "Nombre des apprentis en provenance de l’université/ nb apprentis 1ère année + 2ème année\n" +
      " - Année en cours ",
    result: "learnersInternsComingFromTheUniversity",
    suffix: "%",
    type: 1
  }, {
    criteria: "Taux de réalisation de l'exécution des conventions de FPA",
    forumula: "Cumule des lauréats formés dans le cadre de la dernière convention pour la formation par apprentissage / l’effectif des lauréats prévu dans la dernière  convention",
    result: "executionLearningFPA",
    suffix: "%",
    type: 1
  }
];
