import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { indicatorsHeaders } from "../../../config/indicatorsHeader";
import { getCriteriaByYearAndInstitute } from "../../../crud/trainingInstitute.crud";
import "./qui.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "@material-ui/core";
import { pushDataAndDownloadPdf } from "../../../crud/reporting.crud";


const AggregationTable = ({ ti , mode, existingYears}) => {
  const [data, setData] = useState([]);
  const toFix = (value, label) => {
    if (isNaN(value))
      return "";
    else if (value && label != "regestrants"
      && label != "foreignRate" && label != "foreignRate"
      && label != "nbOfBranches" && label != "nbOfTraineesWithSpecialNeeds"
      && label != "nbOfTraineesWithSpecialNeeds"
      && label != "evolutionOfAccomodationBoys"
      && label != "evolutionOfAccomodationGirls")
      return value.toFixed(2);
    else
      return value;
  };
  useEffect(() => {
    console.log("====> existing", existingYears)
    if (existingYears.length > 0) {
        let tmpData = [];
        existingYears.forEach((year, i) => {
          getCriteriaByYearAndInstitute(ti, year).then(response => {
            let dataFromCriteria = response.data;
            let result = {};
            result.regestrants = dataFromCriteria.nbOfRegistrants1 + dataFromCriteria.nbOfRegistrants2 + dataFromCriteria.nbOfRegistrants3;
            result.success1 = (dataFromCriteria.nbOfSuccessPrevious1 / dataFromCriteria.nbOfRegistrantsPrevious1) * 100;
            result.success2 = (dataFromCriteria.nbOfSuccessPrevious2 / dataFromCriteria.nbOfRegistrantsPrevious2) * 100;
            result.success3 = (dataFromCriteria.nbOfSuccessPrevious3 / dataFromCriteria.nbOfRegistrantsPrevious3) * 100;
            result.graduationComission = dataFromCriteria.nbOfGraduatedPrevious / dataFromCriteria.nbOfRegistrantsPromotionPrevious * 100;
            result.dropOut1 = (dataFromCriteria.dropoutsPrevious1 / dataFromCriteria.nbOfRegistrantsPrevious1) * 100;
            result.dropOut2 = (dataFromCriteria.dropoutsPrevious2 / dataFromCriteria.nbOfRegistrantsPrevious2) * 100;
            result.dropOut3 = (dataFromCriteria.dropoutsPrevious3 / dataFromCriteria.nbOfRegistrantsPrevious3) * 100;
            result.affluence = (dataFromCriteria.nbOfApplicants / dataFromCriteria.nbOfTeachingPlacesAvailable);
            result.globalSupervision = (dataFromCriteria.nbOfGlobalInterns / dataFromCriteria.nbOfTrainers );
            result.supervisionRate = (dataFromCriteria.nbOfGlobalInterns / dataFromCriteria.nbOfPermanentTrainers);
            result.integrationRate = (dataFromCriteria.nbOfGraduatedEmployedDuringThe9MonthsAfterGraduation / dataFromCriteria.nbOfGraduatedPrevious2) * 100;
            result.foreignRate = dataFromCriteria.numberOfForeignInterns;
            result.ageMax18 = (dataFromCriteria.firstAge / dataFromCriteria.nbOfGlobalInterns) * 100;
            result.ageBetween18_24 = (dataFromCriteria.secondAge / dataFromCriteria.nbOfGlobalInterns) * 100;
            result.ageMin_24 = (dataFromCriteria.thirdAge / dataFromCriteria.nbOfGlobalInterns) * 100;
            result.nbOfBranches = dataFromCriteria.nbOfBranches;
            result.removedBranches = dataFromCriteria.removedBranches;
            result.nbOfTraineesWithSpecialNeeds = dataFromCriteria.nbOfTraineesWithSpecialNeeds;
            result.internsComingFromTheUniversity = (dataFromCriteria.internsComingFromTheUniversity / (dataFromCriteria.nbOfRegistrants1 + dataFromCriteria.nbOfRegistrants2 + dataFromCriteria.nbOfRegistrants3)) * 100;
            result.executionFPA = (dataFromCriteria.cumulationOfGraduated / dataFromCriteria.expectedGraduated) * 100;
            result.trainingCost = (dataFromCriteria.budget / result.regestrants) ;
            result.evolutionOfAccomodationBoys = dataFromCriteria.boysAccommodated;
            result.evolutionOfAccomodationGirls = dataFromCriteria.girlsAccommodated;


            result.regestrantsLearners = dataFromCriteria.nbOfRegistrantsLearners1 + dataFromCriteria.nbOfRegistrantsLearners2;
            result.successLearners1 = dataFromCriteria.nbOfSuccessPreviousLearners1 + dataFromCriteria.nbOfRegistrantsPreviousLearners1;
            result.successLearners2 = dataFromCriteria.nbOfSuccessPreviousLearners2 + dataFromCriteria.nbOfRegistrantsLearners2;
            result.graduationComissionLearners = dataFromCriteria.nbOfGraduatedLearnersPrevious / dataFromCriteria.nbOfRegistrantsPromotionLearnersPrevious * 100;
            result.dropOutLearners1 = (dataFromCriteria.dropoutsLearnerPrevious1 / dataFromCriteria.nbOfRegistrantsPreviousLearners1) * 100;
            result.dropOutLearners2 = (dataFromCriteria.dropoutsLearnersPrevious2 / dataFromCriteria.nbOfRegistrantsPreviousLearners2) * 100;
            result.integrationLearnersRate = (dataFromCriteria.learnersEmployedDuringThe9MonthsAfterGraduation / dataFromCriteria.nbOfGraduatedLearnersSameYear) * 100;
            result.learnersAgeMax18 = (dataFromCriteria.learnersFirstAge / dataFromCriteria.nbOfAllLearners) * 100;
            result.learnersAgeBetween18_24 = (dataFromCriteria.learnersSecondAge / dataFromCriteria.nbOfAllLearners) * 100;
            result.learnersAgeMin_24 = (dataFromCriteria.learnersThirdAge / dataFromCriteria.nbOfAllLearners) * 100;
            result.learnersAgeMin_24 = dataFromCriteria.nbOfLearnersTraineesWithSpecialNeeds;
            result.nbOfLearnersTraineesWithSpecialNeeds = dataFromCriteria.nbOfLearnersTraineesWithSpecialNeeds;
            result.learnersInternsComingFromTheUniversity = (dataFromCriteria.learnersInternsComingFromTheUniversity / (dataFromCriteria.nbOfRegistrantsLearners1 + dataFromCriteria.nbOfRegistrantsLearners2)) * 100;
            result.executionLearningFPA = (dataFromCriteria.cumulationOfLearnersGraduated / dataFromCriteria.expectedLearnersGraduated) * 100;
            tmpData.push({year: year, result: result});
            if(i == existingYears.length - 1){
              console.log('====> tmp', year);
                setData(tmpData);
            }
          });
        })
    }
  }, [existingYears, ti]);
  const [value, setValue] = React.useState(0);
  const [indicators, setIndicators] = React.useState(indicatorsHeaders.filter(e => e.type == 0));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 0) {
      setIndicators(indicatorsHeaders.filter(e => e.type == 0));
    } else if (newValue == 1) {
      setIndicators(indicatorsHeaders.filter(e => e.type == 1));
    }
  };

  const getExcelSheetHeaderTitles = (data) => {
    console.log('In getEcelSheetHeaderTitles')

    const headers = ["Indicateurs statistiques agrégés"]
    data.forEach((yearData) => {
      headers.push("Résultat (" + yearData.year + ")")
    })
    headers.push("Type")
    return headers
  }

  const getAllExcelSheetData = (criteria, data, result, suffix, type) => {
    console.log('In getExcelSheetYearResults')

    const allSheetData = [criteria]
    data.forEach((yearData) => {
      allSheetData.push(toFix(yearData.result[result], result) + suffix)
    })

    allSheetData.push(type === 0 ? "Formation résidentielle et alternée" : "Formation par apprentissage")

    return allSheetData
  }

  const exportData = () => {
    const exportParams = {
      header: getExcelSheetHeaderTitles(data),
      data: indicatorsHeaders.map(({ criteria, result, type, suffix }) => {
        if(suffix == '%' || suffix == ' MAD' )
          return getAllExcelSheetData(criteria, data, result, suffix, type);
        else
          return getAllExcelSheetData(criteria, data, result, suffix, type);
      }),
      editColIndexThreshold: 3,
      headerStyleCellsRows: [],
      headerStyleRows: [1]
    };
    pushDataAndDownloadPdf(exportParams, {}, "Indicateurs statistiques agrégés.xlsx");
  };

  return (
    <>
      {indicatorsHeaders.length && <div style={{ float: "right" }}>
        <Button variant="outlinedPrimary" onClick={() => exportData()}>
          Export
        </Button>
      </div>}

      <div className="row">
        <div className="col-md-12">
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Résidentiel" >

            </Tab>
            {
              mode &&
              <Tab label="Apprentissage">

              </Tab>
            }
          </Tabs>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TableContainer component={Paper} key={"tableContainer" + existingYears.length}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">indicateurs statistiques agrégés</TableCell>
                  <TableCell align="left">Formule</TableCell>
                  {existingYears.map(e => {
                    return   <TableCell align="center">{'resultat (' + e +')'}</TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody key={"tbody" + existingYears.length} >
                {indicators.map((row) => (
                  <TableRow
                    key={row.criteria}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >

                    <TableCell align="left" key={row.criteria + "1"}>{row.criteria}</TableCell>
                    <TableCell align="left" key={row.criteria + "2"}>{row.forumula}</TableCell>
                    {
                      data.map(e => {
                        console.log('======> e check here x', e.year)
                        console.log('======> e check here keys', e.result)
                        return <TableCell align="center"
                                          key={row.criteria + e.year + e.result[row.result]}>{toFix(e.result[row.result], row.result) + row.suffix}</TableCell>
                      })
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>


  );
};

export default AggregationTable;
