import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button
} from "@material-ui/core";
import * as _ from "lodash";
import * as crud from "../../../crud/process-management.crud";


import { ArrowBack, ExpandMore } from "@material-ui/icons";
import { ProcessManagementTable } from "./ProcessManagementTable";
import PageLoading from "../../../../shared/component/PageLoading";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import Chip from "@mui/material/Chip/Chip";
import { swapArray } from "../../../../shared";
import { updateCriteriaOrder } from "../../../crud/process-management.crud";
import { actionConfirm } from "../../../../shared/utils/confirm.util";
import { useConfirm } from "material-ui-confirm";
import { merge } from "lodash";
import EditableInput from "../../../../shared/component/EditableInput";
import AddNewProcess from "./AddNewProcess";
import AddNewStandard from "./AddNewStandard";


const ProcessManagementPage = () => {

  const { notifySuccess, notifyError } = useNotification(useSnackbar());
  const confirm = actionConfirm(useConfirm());


  useEffect(() => {
    loadRefs();
    crud.getRefStandards().then(({ data }) => {
      setAllStandard(data);
    });
  }, []);

  const loadRefs = () => {
    crud.getRefProcess()
      .catch(err => {
        notifyError("Une erreure technique est survenue lors du chargement !");
      })
      .then(({ data }) => {
        setLoading(false);
        setProcess(data);
      });
  };

  const [process, setProcess] = useState([]);
  const [allStandard, setAllStandard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toUpdateProcess, setToUpdateProcess] = useState();
  const [toUpdateStandard, setToUpdateStandard] = useState();


  const removeCriteria = (standardId, criteriaId, setCriteria) => {
    confirm("Etes vous sûr de bien vouloir suprrimer ce Critere ?").then(() => {
      crud.deleteCriteria(criteriaId)
        .catch(err => {
          notifyError("Erreur lors de la suppression du critere !");
          setCriteria(null);
          throw err;
        })
        .then(() => {
          notifySuccess("Critere supprime avec succes !");
          loadStandard(standardId);
        });
    });
  };


  const moveCriteria = (standard, fromIndex, toIndex) => {
    const criterias = [...standard.criterias];
    swapArray(criterias, fromIndex, toIndex);
    const newCriteriasOrder = criterias.reduce((previousValue, currentValue, index) => {
      previousValue[currentValue.id] = index + 1;
      return previousValue;
    }, {});
    updateCriteriaOrder(newCriteriasOrder)
      .catch(err => {
        notifyError("Une erreure technique lors de la modification de l'ordre des criteres !");
        throw err;
      })
      .then(() => {
        notifySuccess("Modification de l'ordre des criteres avec succès !");
        loadStandard(standard.id);
      });
  };

  const loadStandard = standardId => {
    crud.getStandardById(standardId).then(({ data }) => {
      replaceStandard(data);
    });
  };

  const replaceStandard = standard => {
    setProcess(data => {
      const processIndex = _.findIndex(data, p => p.id === standard.processId);
      const standardIndex = _.findIndex(data[processIndex].standards, std => std.id === standard.id);
      data[processIndex].standards[standardIndex] = standard;
      return [...data];
    });
  };

  const validateAddCriteria = (newCriteria, setNewCriteria) => {
    crud.saveCriteria(newCriteria)
      .then(() => {
        notifySuccess("Critere ajoute avec succes !");
        loadStandard(newCriteria.standardId);
        setNewCriteria(null);
      })
      .catch(() => {
        notifyError("Erruer technique lors de l'ajout !");
      });
  };

  const validateUpdateCriteria = (toUpdateCriteria, cancelUpdateMode) => {
    crud.upateCriteria(toUpdateCriteria)
      .catch(err => {
        notifyError("Erreur technique lors de la mise a jour du critere !");
        throw err;
      })
      .then(() => {
        notifySuccess("Critere mise a jour avec succes !");
        loadStandard(toUpdateCriteria.standardId);
        cancelUpdateMode(toUpdateCriteria);
      });
  };


  const validateUpdateProcess = (e) => {
    e.stopPropagation();
    const { id, label } = toUpdateProcess;
    crud.updateProcess(id, label)
      .catch(err => {
        notifyError("Erreur technique lors de la mise a jour du processus !");
        throw err;
      })
      .then(() => {
        notifySuccess("Processus mis a jour avec succes !");
        loadRefs();
        setToUpdateProcess(null);
      });
  };

  const validateUpdateStandard = (e) => {
    e.stopPropagation();
    const { id, label, processId } = toUpdateStandard;
    crud.updateStandard(id, label, processId)
      .catch(err => {
        notifyError("Erreur technique lors de la mise a jour du standard !");
        throw err;
      })
      .then(() => {
        notifySuccess("Standard mis a jour avec succes !");
        loadRefs();
        setToUpdateStandard(null);
      });
  };

  const deleteProcess = (processId) => {
    crud.deleteProcessById(processId)
      .catch(err => {
        notifyError("Erreur technique lors de la suppression du processus !");
        throw err;
      })
      .then(() => {
        notifySuccess("Processus supprime avec succes");
        loadRefs();
      });
  };

  const deleteStandard = (standardId) => {
    crud.deleteStandardById(standardId)
      .catch(err => {
        notifyError("Erreur technique lors de la suppression du standard !");
        throw err;
      })
      .then(() => {
        notifySuccess("Standard supprime avec succes");
        loadRefs();
        setToUpdateProcess(null);
      });
  };

  const addNewProcess = (label, closeModal) => {
    crud.saveProcess(label)
      .catch(err => {
        notifyError("Erreur technique lors de l'ajout du processus !");
        throw err;
      })
      .then(() => {
        notifySuccess("Processus ajoute avec succes");
        loadRefs();
        setToUpdateProcess(null);
        closeModal();
      });
  };

  const addNewStandard = (label, processId, closeModal) => {
    crud.saveStandard(label, processId)
      .catch(err => {
        notifyError("Erreur technique lors de l'ajout du standard !");
        throw err;
      })
      .then(() => {
        notifySuccess("Standard ajoute avec succes");
        loadRefs();
        closeModal();
        setToUpdateProcess(null);
      });
  };


  if (loading)
    return <PageLoading/>;
  return (
    <>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <div className="row mb-4">
            <div className="col-md-4">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span>Gestion des processus</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <div className={"filter-btn-div"}>
                <Button variant="outlinedPrimary" startIcon={<ArrowBack/>}
                        onClick={() => window.history.back()}
                        style={{ marginRight: "5px" }}>
                  Retour
                </Button>
              </div>
            </div>
          </div>
          <AddNewProcess addNewProcess={addNewProcess}/>
          {process.map((proc, procIndex) => (
              <Accordion className={"mb-2"} defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMore/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >

                  <EditableInput
                    label={`Process ${procIndex + 1}`}
                    value={proc}
                    toUpdate={toUpdateProcess}
                    setToUpdate={setToUpdateProcess}
                    field="label"
                    isUpdating={proc.id === toUpdateProcess?.id}
                    validateEdit={validateUpdateProcess}
                    onDelete={() => deleteProcess(proc.id)}
                    title={<h6>
                      <Chip label={`Process ${procIndex + 1}`} size="small" variant="outlined"
                            className="mr-2"/>
                      {proc.label}
                    </h6>}
                  />

                </AccordionSummary>
                <AccordionDetails>
                  <AddNewStandard process={proc} addNewStandard={addNewStandard}/>
                  {proc.standards.map((standard) => {
                    return (
                      <>
                        <Accordion className={"mb-2"} defaultExpanded={true}>
                          <AccordionSummary
                            sx={{
                              backgroundColor: "gray"
                            }}
                            expandIcon={<ExpandMore/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <EditableInput
                              label={standard.description}
                              value={standard}
                              toUpdate={toUpdateStandard}
                              setToUpdate={setToUpdateStandard}
                              field="label"
                              isUpdating={standard.id === toUpdateStandard?.id}
                              validateEdit={validateUpdateStandard}
                              onDelete={() => deleteStandard(standard.id)}
                              title={<h6>
                                <Chip label={standard.description} size="small" variant="outlined"
                                      className="mr-2"/>
                                {standard.label}
                              </h6>}
                            />


                          </AccordionSummary>
                          <AccordionDetails>
                            <ProcessManagementTable standard={standard} processId={proc.id}
                                                    removeCriteria={removeCriteria}
                                                    moveCriteria={moveCriteria}
                                                    validateAddCriteria={validateAddCriteria}
                                                    validateUpdateCriteria={validateUpdateCriteria}
                                                    allStandard={allStandard}/>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            )
          )
          }
        </div>
      </div>
    </>
  );
};


export default ProcessManagementPage;
