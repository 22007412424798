import React from "react";
import TextField from "@material-ui/core/TextField/TextField";
import { merge } from "lodash";


const EditableInput = ({ toUpdate, setToUpdate, value, isUpdating, validateEdit, field, label, title, onDelete }) => {
  const handleUserInput = value => {
    setToUpdate(merge({}, toUpdate, { [field]: value }));
  };

  if (isUpdating) {
    return <>

      <button
        onClick={validateEdit}
        className="btn btn-clean btn-sm btn-icon btn-icon-lg mt-2"
        title="Valider l'ajout"
      >
        <i className="flaticon2-check-mark"></i>
      </button>
      <button
        onClick={e => {
          e.stopPropagation();
          setToUpdate(null);
        }}
        className="btn btn-clean btn-sm btn-icon btn-icon-lg mt-2"
        title="Annuler l'ajout"
      >
        <i className="flaticon2-cancel"></i>
      </button>
      <TextField
        onClick={(e) => e.stopPropagation()}
        value={isUpdating ? toUpdate[field] : value[field]}
        label={label}
        onChange={event => handleUserInput(event.target.value)}
        margin="dense"
        variant="outlined"
        multiline
        rowsMax="10"
        disabled={!isUpdating}/>
    </>;
  } else {
    return <>
      <button
        onClick={e => {
          e.stopPropagation();
          setToUpdate(value);
        }}
        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
        title="Modifier"
      >
        <i className="flaticon2-edit"></i>
      </button>
      <button
        onClick={e => {
          e.stopPropagation();
          onDelete();
        }}
        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
        title="Supprimer"
      >
        <i className="flaticon-delete"></i>
      </button>
      <span className="mt-2">
        {title}
      </span>
    </>;
  }

};

export default EditableInput;
