import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import React, { useEffect, useState } from "react";
import { getReferentialAnswersRecap } from "../../../crud/referential-quiz.crud";
import TableContainer from "@mui/material/TableContainer/TableContainer";

const DashboardByTi = ({ year, ti, to, tc, dr }) => {

  const maturities = [
    { maturity: 1, value: 0, label: "0%" },
    { maturity: 2, value: 0.3, label: "30%" },
    { maturity: 3, value: 0.7, label: "70%" },
    { maturity: 4, value: 1, label: "100%" },
    { maturity: 0, value: 0, label: "0%" }];
  const [rows, setRows] = useState({ processDtos: [] });
  useEffect(() => {
    // @TODO to be changed
    if (year) {
      if (ti) {
        getReferentialAnswersRecap(year, ti, 0).then(response => {
          setRows(response.data);

        });
      }
      else if (tc) {
        getReferentialAnswersRecap(year, tc, 1).then(response => {
          setRows(response.data);
        });
      }
      else if (dr) {
        getReferentialAnswersRecap(year, dr, 3).then(response => {
          setRows(response.data);
        });
      }
      else if (to) {
        getReferentialAnswersRecap(year, to, 2).then(response => {
          setRows(response.data);
        });
      }

    }
  }, [year, ti, tc, to, dr]);
  const [expanded, setExpanded] = useState(true);
  return (
    <Accordion className={"mb-2"} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Tableau récapitulatif par EFP, par processus et par niveau de maturité des
          processus</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer sx={{ maxHeight: 250 }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center" style={{ width: 120 }}> OF</TableCell>
                {/*
                            <TableCell component="th" scope="row" align="center"   style={{ width: 140 }}>CFP</TableCell>
*/}
                <TableCell component="th" scope="row" align="center" style={{ width: 140 }}> EFP </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <TableRow>
                    <TableCell component="th" scope="row" align="center"
                               style={{ width: 140 }}> Processus </TableCell>

                    <TableCell component="th" scope="row" align="center" style={{ width: 120 }}>
                      Nb de critères
                    </TableCell>
                    <TableCell component="th" scope="row" align="center" style={{ width: 120 }}>
                      Nb de saisies
                    </TableCell>

                    <TableCell component="th" scope="row" align="center" style={{ width: 120 }}>
                      Taux de saisie
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">

                      <TableRow>
                        <TableCell component="th" scope="row" align="center" style={{ width: 250 }}>
                          Resultat

                          <TableRow>
                            <TableCell component="th" scope="row" align="center"
                                       style={{ width: 50 }}>
                              NM
                            </TableCell>
                            <TableCell component="th" scope="row" align="center"
                                       style={{ width: 200 }}>
                              Taux
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      </TableRow>

                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <TableRow>
                        <TableCell align="center" style={{ width: 120 }}>Commentaire</TableCell>
                      </TableRow>
                    </TableCell>

                  </TableRow>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>

              <TableRow
                key={rows.processus}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row"
                           align="center" style={{ width: 140 }}> {rows.trainingOperator}</TableCell>
                <TableCell component="th" scope="row" align="center"
                           style={{ width: 140 }}> {rows.trainingInstitute} </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {rows.processDtos.map(element => {
                    return (
                      <TableRow>
                        <TableCell scope="row"
                                   align="center"
                                   style={{ width: 140 }}> {element.name} </TableCell>
                        <TableCell scope="row"
                                   align="center" style={{ width: 120 }}>
                          {element.criteriaMaturityDtoList.length} </TableCell>
                        <TableCell scope="row" align="center"
                                   style={{ width: 120 }}>
                          {element.criteriaMaturityDtoList.filter(e => e.maturity != null).length}
                        </TableCell>
                        <TableCell scope="row" align="center"
                                   style={{ width: 120 }}>
                          {((element.criteriaMaturityDtoList.filter(e => e.maturity != null).length / element.criteriaMaturityDtoList.length) * 100).toFixed(0)}%
                        </TableCell>
                        <TableCell scope="row" align="center" style={{ width: 80 }}>
                          {[1, 2, 3, 4, "NA"].map(e => {
                            return (
                              <TableRow>
                                <TableCell align="center">
                                  {e}
                                </TableCell>
                              </TableRow>
                            );
                          })
                          }
                        </TableCell>
                        <TableCell scope="row" align="left" style={{ width: 200 }}>
                          {maturities.map(e => {
                            return (
                              <TableRow>
                                <TableCell align="left">
                                  {e.label + " x " + element.criteriaMaturityDtoList.filter(el => el.maturity == e.maturity).length
                                  + " = " + (e.value * element.criteriaMaturityDtoList.filter(el => el.maturity == e.maturity).length).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            );
                          })
                          }
                        </TableCell>
                        <TableCell scope="row" align="center" style={{ width: 120 }}>
                          Soit un sous total de <span
                          style={{
                            fontWeight: "bold",
                            color: "red"
                          }}>{element.totalOfMaturities.toFixed(2)}</span>
                          <br/>
                          sur un total potentiel
                          de {element.criteriaMaturityDtoList.length} = &nbsp;
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "red"
                            }}>{(element.totalOfMaturities * 100 / element.criteriaMaturityDtoList.length).toFixed(2)}%</span>

                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default DashboardByTi;
