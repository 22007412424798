import { chunk } from "lodash";

const includeIgnoreCase = (a, b) => {
  if (a === null || b === null)
    return false;
  return a.toLowerCase().includes(b.toLowerCase());
};

const compare = r => l => {
  if (typeof l === "object") {
    return contains(r)(l);
  } else if (typeof l === "string") {
    return includeIgnoreCase(l, r);
  } else {
    return l === r;
  }
  ;
};

const contains = r => l =>
  Object.keys(r).filter(k => r[k] != null && l !=null).every(k => l.hasOwnProperty(k) && compare(r[k])(l[k]));


export const genericFilter = (list, filter) => {
  return list.filter(contains(filter));
};

export const genericFilterAndPagination = (list, filter, page = 0, size = 5) => {
  const filteredList = list.filter(contains(filter));
  return {
    data: chunk(filteredList, size)[page],
    dataLength: filteredList.length
  };
};
