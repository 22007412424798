import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getRecapByProcessAndTrainingComplex, getRecapByProcessAndTrainingDR,
  getRecapByProcessAndTrainingOperator,
  getReferentialAnswersByProcess
} from "../../../crud/referential-quiz.crud";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import * as _ from "lodash";
import TableContainer from "@mui/material/TableContainer";


const MaturityLevelMeasuresByProcess = ({ year, ti, to, tc, dr }) => {
  const [rows, setRows] = useState([]);
  const [toShowTooltipIndex, setToShowTooltipIndex] = useState(null);
  useEffect(() => {
    // @TODO to be changed
    if (year) {
      let promise = null;

      if (ti) {
        getReferentialAnswersByProcess(year, ti).then(response => {
          if (response.data) {
            let fakeTable = [];
            _.orderBy(Object.keys(response.data)).forEach(e => {
              fakeTable.push(
                {
                  process: e,
                  label: response.data[e][0].label,
                  processId: response.data[e][0].processId,
                  criteria: response.data[e],
                  aggregatedResult: (Array.from(response.data[e]).map(el => el.mtLevel).filter(e => {
                    return (e && e != "N/A");
                  }).map(e => Number(e)).reduce((e, i) => (e + i), 0) / response.data[e].length).toFixed(2)
                });
            });
            setRows(fakeTable);
          }
        });
      } else if (dr) {
        promise = getRecapByProcessAndTrainingDR(year, dr);
      } else if (tc) {
        promise = getRecapByProcessAndTrainingComplex(year, tc);
      } else if (to) {
        promise = getRecapByProcessAndTrainingOperator(year, to);
      }

      if (promise) {
        promise.then(response => {
          setRows([]);

          let result = [];
          if (response.data) {
            result = response.data.reduce(function(r, a) {
              r[a.processId] = r[a.processId] || [];
              r[a.processId].push(a);
              return r;
            }, Object.create(null));
            setRows(Object.keys(result).map(e => {
              return {
                process: result[e][0].processId,
                processId: result[e][0].processId,
                label: result[e][0].processLabel,
                criteria: result[e],
                aggregatedResult: (result[e].map(el => el.mtLevel).reduce((e, i) => e + i) / result[e].length).toFixed(2)
              };
            }));
          }
        });
      }


    }
  }, [year, tc, ti, to, dr]);
  return (
    <>
      <h5 className={"title-recap"}>Tableau récapitulatif des niveaux de maturité par processus</h5>
      <TableContainer sx={{ maxHeight: 250 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Processus</TableCell>
              <TableCell align="center">Résultat agrégé</TableCell>
              <TableCell align="center">Critères</TableCell>
              <TableCell align="center">Niveau de maturité ateint</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(item => (
              <>
                <TableRow>
                  <TableCell rowSpan={item.criteria.length + 1} align="center">

                    <Tooltip className="m-1 btn-pointer"
                             title={<p style={{ "white-space": "pre-wrap" }}> {item.label} </p>}
                             placement="top-start" enterTouchDelay={0}>
                      <span>{item.process}</span>
                    </Tooltip>
                  </TableCell>

                  <TableCell rowSpan={item.criteria.length + 1} align="center"
                             className={(item.aggregatedResult <= 2) ? "weakness-point" : "strength-point"}>
                    {item.aggregatedResult}
                  </TableCell>
                </TableRow>
                {item.criteria.map(detail => (
                  <TableRow key={item.processId + "." + detail.stLabel + "." + detail.criteria}>
                    <TableCell align="center">
                      <Tooltip open={ item.processId + "." + detail.stLabel + "." + detail.criteria === toShowTooltipIndex }
                               onClose={() => setToShowTooltipIndex(null)}
                               className="m-1 btn-pointer" title={<p
                        style={{ "white-space": "pre-wrap" }}> {detail.criteriaLabel} </p>}
                               placement="top-start">
                        <span onClick={() => setToShowTooltipIndex(item.processId + "." + detail.stLabel + "." + detail.criteria)}>{item.processId + "." + detail.stLabel + "." + detail.criteriaOrder}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={(detail.mtLevel <= 2) ? "weakness-point" : "strength-point"}
                               style={{ fontWeight: "bold" }}>{detail.mtLevel}</TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MaturityLevelMeasuresByProcess;
