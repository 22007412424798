/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { includes, first } from "lodash";
import {
  canAccessLogViewer,
  canAccessProcessManagement,
  canAccessReferentialManagement
} from "../../utils/roles.utils";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

export default class QuickPanel extends React.Component {

  state = { selectedTab: "Parameters" };
  setTab = _tabName => {
    this.setState({ selectedTab: _tabName });
  };

  closeTab() {
    // @todo enhance this close function
    document.getElementById("kt_quick_panel_close_btn").click();
  }

  render() {
    return (
      <div id="kt_quick_panel" className="kt-quick-panel">
        <a
          href="#"
          className="kt-quick-panel__close"
          id="kt_quick_panel_close_btn"
        >
          <i className="flaticon2-delete"/>
        </a>
        <Tab.Container defaultActiveKey={this.state.selectedTab}>
          <div className="kt-quick-panel__nav">
            <Nav
              onSelect={this.setTab}
              className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x"
            >

              <Nav.Item>
                <Nav.Link eventKey="Parameters" className="nav-link show">
                  Paramètres
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="kt-quick-panel__content">
            <Tab.Content>
              <Tab.Pane eventKey="Parameters">
                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    position: "relative"
                  }}
                >
                  <div className="kt-notification-v2">
                    {canAccessProcessManagement(this.props.user) ?
                      <div className="kt-notification-v2__item">
                        <div className="kt-notification-v2__item-icon">
                          <i className="flaticon-settings kt-font-brand"/>
                        </div>
                        <div className="kt-notification-v2__itek-wrapper">

                          <Link to={"/process-management"} onClick={() => this.closeTab()}>
                            <Button
                              variant="primary" block
                            >
                              Gestion des Processus
                            </Button>
                          </Link>
                        </div>
                      </div> : <></>}
                    {canAccessReferentialManagement(this.props.user) ?
                      <div href="#" className="kt-notification-v2__item">
                        <div className="kt-notification-v2__item-icon">
                          <i className="flaticon-settings kt-font-brand"/>
                        </div>
                        <div className="kt-notification-v2__itek-wrapper">

                          <Link to={"/referential-management"} onClick={() => this.closeTab()}>
                            <Button
                              variant="primary" block
                            >
                              Paramétrage de la session d’autoévaluation
                            </Button>
                          </Link>
                        </div>
                      </div> : <></>}
                      {canAccessReferentialManagement(this.props.user) ?
                      <div href="#" className="kt-notification-v2__item">
                        <div className="kt-notification-v2__item-icon">
                          <i className="flaticon-settings kt-font-brand"/>
                        </div>
                        <div className="kt-notification-v2__itek-wrapper">

                          <Link to={"/session-management"} onClick={() => this.closeTab()}>
                            <Button
                              variant="primary" block
                            >
                              Activation de session
                            </Button>
                          </Link>
                        </div>
                      </div> : <></>}
                    {canAccessReferentialManagement(this.props.user) ?
                      <div href="#" className="kt-notification-v2__item">
                        <div className="kt-notification-v2__item-icon">
                          <i className="flaticon-settings kt-font-brand"/>
                        </div>
                        <div className="kt-notification-v2__itek-wrapper">

                          <Link to={"/region-management"} onClick={() => this.closeTab()}>
                            <Button
                              variant="primary" block
                            >
                              Paramétrage des régions
                            </Button>
                          </Link>
                        </div>
                      </div> : <></>}
                    {canAccessReferentialManagement(this.props.user) ?
                      <div href="#" className="kt-notification-v2__item">
                        <div className="kt-notification-v2__item-icon">
                          <i className="flaticon-settings kt-font-brand"/>
                        </div>
                        <div className="kt-notification-v2__itek-wrapper">

                          <Link to={"/sector-management"} onClick={() => this.closeTab()}>
                            <Button
                              variant="primary" block
                            >
                              Paramétrage des secteurs
                            </Button>
                          </Link>
                        </div>
                      </div> : <></>}
                    {canAccessLogViewer(this.props.user) ?
                      <div href="#" className="kt-notification-v2__item">
                        <div className="kt-notification-v2__item-icon">
                          <i className="flaticon-settings kt-font-brand"/>
                        </div>
                        <div className="kt-notification-v2__itek-wrapper">

                          <Link to={"/logs-viewer"} onClick={() => this.closeTab()}>
                            <Button
                              variant="primary" block
                            >
                              Visualisation des logs
                            </Button>
                          </Link>
                        </div>
                      </div> : <></>}
                  </div>
                </PerfectScrollbar>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    );
  }
}
