import { isEmpty } from "lodash";

const ReportType = {
  IMPROVEMENT_AXIS_REPORT: "enhancement-axis",
  DFP_REPORT: "training-institute-percent",
  TRAINING_OPERATOR_REPORT: "training-operator-percent",
  GLOBAL_MATURITY_REPORT: "global-maturity",
  AGGREGATED_CRITERIA: "aggregated-criteria-report",
  EFP_PAR_SESSION: "efp-par-session"
};

Object.freeze(ReportType);


const ReportTypeToTitle = {
  [ReportType.IMPROVEMENT_AXIS_REPORT]: "Axes d’amélioration",
  [ReportType.DFP_REPORT]: "au profit du DFP",
  [ReportType.TRAINING_OPERATOR_REPORT]: "pour l’Opérateur de Formation",
  [ReportType.GLOBAL_MATURITY_REPORT]: "Maturité globale"
};

const ReportTypeToFilename = {
  [ReportType.IMPROVEMENT_AXIS_REPORT]: "Rapport-Axes-amélioration",
  [ReportType.DFP_REPORT]: "Rapport-DFP",
  [ReportType.TRAINING_OPERATOR_REPORT]: "Rapport-Opérateur-Formation",
  [ReportType.GLOBAL_MATURITY_REPORT]: "Rapport-maturité-globale"
};

const ReportTypeToExcelExportParams = {
  [ReportType.IMPROVEMENT_AXIS_REPORT]: {
    headerStyleRows: [1],
    headerStyleCellsRows: [],
    toMergeCells: [0, 1, 2]
  },
  [ReportType.DFP_REPORT]: {
    headerStyleRows: [1],
    headerStyleCellsRows: [],
    toMergeCells: [0, 1]
  },
  [ReportType.TRAINING_OPERATOR_REPORT]: {
    headerStyleRows: [1],
    headerStyleCellsRows: [],
    toMergeCells: [0, 1]
  },
  [ReportType.GLOBAL_MATURITY_REPORT]: {
    headerStyleRows: [1],
    headerStyleCellsRows: [],
    toMergeCells: [0, 1]
  }
};


const generateSaveReportData = (exportData, type, threshold, year) => {
  let toPersistData = [];
  switch (type) {
    case ReportType.IMPROVEMENT_AXIS_REPORT:
      for (let chunk of exportData) {
        const values = chunk.splice(threshold, chunk.length);
        const [improvementAxes, trainingInstitutesSolutions, guardianshipSolutions, otherSolutions, term, resourcesToMobilize, criteriaId, id, trainingInstituteId] = values;
        if ([improvementAxes, trainingInstitutesSolutions, guardianshipSolutions, otherSolutions, term, resourcesToMobilize].filter(item => !isEmpty(item)).length > 0) {
          toPersistData.push({
            improvementAxes,
            trainingInstitutesSolutions,
            guardianshipSolutions,
            otherSolutions,
            term,
            resourcesToMobilize,
            criteria: { id: criteriaId },
            trainingInstitute: { id: trainingInstituteId },
            year,
            id
          });
        }
      }
      return toPersistData;
    case ReportType.TRAINING_OPERATOR_REPORT:
    case ReportType.DFP_REPORT:
      for (let chunk of exportData) {
        const values = chunk.splice(threshold, chunk.length);
        const [improvementAxes, term, resources, criteriaId, id] = values;
        if ([improvementAxes, term, resources].filter(item => !isEmpty(item)).length > 0) {
          toPersistData.push({
            improvementAxes,
            term,
            resources,
            criteria: { id: criteriaId },
            year,
            id
          });
        }
      }
      return toPersistData;
    default:
      return exportData;
  }
};


export { ReportType, ReportTypeToTitle, ReportTypeToFilename, ReportTypeToExcelExportParams, generateSaveReportData };



