import axios from "axios";

export const NOTIFICATION_URL = "notifications/";
export const LOGS_URL = "logs";
export const NOTIFICATION_UPDATE_URL = "notifications/isRead";

export function getAllSentNotifications(user) {
  return axios.get(`${NOTIFICATION_URL}${user}/sent`);
}

export function getAllReceivedNotifications(user) {
  return axios.get(`${NOTIFICATION_URL}${user}/received`);
}

export function addNotification(notification, users) {
  return axios.post(NOTIFICATION_URL, {notification, users});
}

export function getLogs(page = 0, size = 100, from, to) {
  return axios.get(`${LOGS_URL}`, { params: { page, size, from, to } });
}

export function updateNotification(notification) {
    return axios.post(NOTIFICATION_UPDATE_URL , notification);
}
