import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import {LayoutSplashScreen} from "../../../shared";
import TrainingInstitute from "./formation/Training-institute/training-institute";
import TrainingOperator from "./formation/training-operator/training-operator";
import AccountManager from "./accounts-manager/AccountsManager";
import ReferentialQuizPage from "./referential-quiz/ReferentialQuizPage";
import ProcessManagementPage from "./process-management/ProcessManagementPage";
import TrainingComplex from "./formation/training-complex/training-complex";
import ReferentialManagementPage from "./referential-management/ReferentialManagementPage";
import ReferentialSessionPage from "./referential-session/ReferentialSessionPage";
import MyProfilePage from "./my-profile/MyProfilePage";
import connect from "react-redux/es/connect/connect";
import ProtectedRoute from "../../router/ProtectedRoute";
import TrainingDR from "./formation/training-DR/training-DR";
import RegionManagement from "./region-management/region-management";
import LogViewer from "./logs-viewer/LogViewer";
import SectorManagement from "./sector-management/sector-management";

function HomePage({user}) {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>

            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ProtectedRoute user={user} path="/dashboard" Component={Dashboard}/>
                <ProtectedRoute user={user} path="/accounts" Component={AccountManager}/>
                <ProtectedRoute user={user} path="/training-institute" Component={TrainingInstitute}/>
                <ProtectedRoute user={user} path="/training-operator" Component={TrainingOperator}/>
                <ProtectedRoute user={user} path="/direction-regionale" Component={TrainingDR}/>
                <ProtectedRoute user={user} path="/training-complex" Component={TrainingComplex}/>
                <ProtectedRoute user={user} path="/referential-quiz/:id" Component={ReferentialQuizPage}/>
                <ProtectedRoute user={user} path="/process-management" Component={ProcessManagementPage}/>
                <ProtectedRoute user={user} path="/my-profile" Component={MyProfilePage}/>
                <ProtectedRoute user={user} path="/referential-management" Component={ReferentialManagementPage}/>
                <ProtectedRoute user={user} path="/region-management" Component={RegionManagement}/>
                <ProtectedRoute user={user} path="/sector-management" Component={SectorManagement}/>
                <ProtectedRoute user={user} path="/session-management" Component={ReferentialSessionPage}/>
                <ProtectedRoute user={user} path="/logs-viewer" Component={LogViewer}/>
                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}


const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(HomePage);
