import TableContainer from "@mui/material/TableContainer/TableContainer";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getGlobalRecap,
  getReferentialAnswersRecap
} from "../../../crud/referential-quiz.crud";

export const FillStatusByOperatorAndComplex = ({ year, to, tc, dr }) => {
  const [data, setData] = useState([]);

  const handleData = (responseData) => {
    setData([]);
    let listOfData = [];
    responseData.filter(e => e.trainingOperator).forEach(value => {
      let recapObject = { numberOfIndicators: 0, fulfilledCriteria: 0, lastEnterData: null };
      if (value) {
        value.processDtos.forEach((e, i) => {
          recapObject.numberOfIndicators += e.criteriaMaturityDtoList.length;
          recapObject.fulfilledCriteria += e.criteriaMaturityDtoList.filter(e => e.maturity != null).length;
        });
        listOfData.push({
          ...recapObject,
          ti: value.trainingInstitute,
          city: value.city,
          email: value.email,
          session:value.session,
          phoneNumber: value.phoneNumber,
          lastEntryDate: value.lastEntryDate
        });
      }
    });

    setData(listOfData);
  };

  useEffect(() => {
    if (year) {
      if (dr) {
        getReferentialAnswersRecap(year, dr, 3).then(response => {
          handleData([response.data]);
        });
      } else if (to) {
        getGlobalRecap(year, to).then(response => {
          handleData(response.data);
        });
      }
    }
  }, [year, to, tc, dr]);
  return (
    <>
      <TableContainer sx={{ maxHeight: 250 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>EFP</TableCell>
              <TableCell align="center">Ville/Commune</TableCell>
              <TableCell align="center">E-mail</TableCell>
              <TableCell align="center">Téléphone</TableCell>
              <TableCell align="center">Session</TableCell>
              <TableCell align="center">Critères saisies</TableCell>
              <TableCell align="center">Taux de remplissage</TableCell>
              <TableCell align="center">Dernière date de saisie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.sort((a, b) => a.fulfilledCriteria - b.fulfilledCriteria).map(item => (
              <>

                <TableRow>
                  <TableCell align="center">
                    <span>{item.ti}</span>
                  </TableCell>
                  <TableCell align="center">
                    <span>{item.city}</span>
                  </TableCell>
                  <TableCell align="center">
                    <span>{item.email}</span>
                  </TableCell>
                  <TableCell align="center">
                    <span>{item.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <span>{item.session}</span>
                  </TableCell>
                  <TableCell align="center">
                    {item.fulfilledCriteria}
                  </TableCell>
                  <TableCell align="center">
                    {((item.fulfilledCriteria / item.numberOfIndicators) * 100).toFixed(2)}%
                  </TableCell>

                  <TableCell align="center">
                    {item.lastEntryDate}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
