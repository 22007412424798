import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Ballot, DonutSmall } from "@material-ui/icons";
import TrainingInstituteFilter from "./TraingingInstituteFilter";
import NewTrainingInstitute from "./new-training-institute/NewTrainingInstitute";
import { Link } from "react-router-dom";
import { deleteTrainingInstitute, getAllTrainingInstitutes } from "../../../../crud/trainingInstitute.crud";
import NewAggregatedCriteria from "./NewAggretedCriteriaYear";
import {
  canAddTrainingInstitute,
  canRemoveTrainingInstitute,
  canUpdateTrainingInstitute
} from "../../../../utils/roles.utils";
import { genericFilterAndPagination } from "../../../../utils/search.utils";
import { AddButton, DeleteButton, EditButton } from "../../../../../shared/component/ActionsButton";
import { NEW_YEAR_DATA } from "./data";
import { useNotification } from "../../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import CustomPagination from "../../../../../shared/component/CustomPagination";
import { actionConfirm } from "../../../../../shared/utils/confirm.util";
import { useConfirm } from "material-ui-confirm";

const INITIAL_FILTER_STATE = { name: "", cityId: null, regionId: null };
const INITIAL_TI_STATE = {
  id: null,
  city: { id: null },
  region: null,
  trainingOperator: { id: null },
  trainingSectors: [],
  trainingTypes: [],
  branches: []
};

function TrainingInstitute({ user }) {

  const confirm = actionConfirm(useConfirm());

  const { notifySuccess, notifyError } = useNotification(useSnackbar());
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [selectedTi, setSelectedTi] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filter, setFilter] = React.useState(INITIAL_FILTER_STATE);
  const [setupYearModal, setSetupYearModal] = useState(false);
  const [dataToModify, setDataToModify] = useState(INITIAL_TI_STATE);
  const [addAction, setAddAction] = useState(true);
  const [newYearData] = useState(NEW_YEAR_DATA);

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    loadData();
  }, [openModal]);

  const loadData = () => {
    getAllTrainingInstitutes()
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des établissement de formation !");
        throw err;
      })
      .then(response => {
        if (response.data) {
          setRows(response.data);
        }
      });
  };

  const deleteById = (id) => {
    confirm("Etes vous sûr de bien vouloir suprrimer l'instut de formation et ses utilisateurs ?").then(() => {
      deleteTrainingInstitute(id)
        .catch(err => {
          notifyError("Une erreure technique lors de la supression de l'établissement de formation !");
          throw err;
        })
        .then(() => {
          notifySuccess("L'établissement de formation a bien été supprimé !");
          loadData();
        });
    });
  };

  const { data, dataLength } = genericFilterAndPagination(rows, filter, page, rowsPerPage);

  const openModalAggre = (e, id) => {
    setSelectedTi(id);
    setSetupYearModal(true);
  }
  return (
    <>
      <div className="row mb-2">
        <div className="col-md-12">
          <div style={{ float: "right" }}>
            {canAddTrainingInstitute(user) &&
            <AddButton onClick={() => {
              setDataToModify(INITIAL_TI_STATE);
              setOpenModal(true);
              setAddAction(true);
            }}/>}
          </div>
        </div>
      </div>
      <NewTrainingInstitute user={user} openModalValue={openModal} closeModal={closeModal} key={openModal}
                            dataToModify={dataToModify} addAction={addAction}/>
      <TrainingInstituteFilter filter={filter} setFilter={setFilter}
                               resetFilter={() => setFilter(INITIAL_FILTER_STATE)}/>
      <div className={"kt-portlet"}>

        <div className={"kt-portlet__body"}>
          <Table sx={{ minWidth: 650 }}
                 size="small"
                 aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Intitulé</TableCell>
                <TableCell align="right">Session</TableCell>
                <TableCell align="right">Ville/Commune</TableCell>
                <TableCell align="right">OF</TableCell>
                
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || []).map((row) => (
                <TableRow
                  key={row.id + 'row'}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.session}</TableCell>
                  <TableCell align="right">{row.city && row.city.name}</TableCell>
                  <TableCell align="right">{row.trainingOperatorName}</TableCell>
                  <TableCell align="right" key={'cell' + row.id}>
                    {canUpdateTrainingInstitute(user) && <EditButton onClick={() => {
                      setDataToModify(row);
                      setOpenModal(true);
                      setAddAction(false);
                    }}/>}
                    {canRemoveTrainingInstitute(user) && <DeleteButton onClick={() => deleteById(row.id)}/>}

                    <span title="Acceder au referentiel">
                      <Link to={"/referential-quiz/" + row.id}><Ballot/></Link>
                    </span>
                    <span title="Indicateurs statistiques">
                      <DonutSmall key={"sm"+ row.id + selectedTi + setupYearModal} style={{ color: "#2739C1" }}
                                  onClick={(e) => openModalAggre(e, row.id + '')}/>
                    </span>

                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CustomPagination
                  totalRows={dataLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {selectedTi && setupYearModal && <NewAggregatedCriteria isOpen={setupYearModal}
                                                key={"modal-" + setupYearModal + selectedTi}
                                                user={user}
                                                closeModal={() => setSetupYearModal(false)} data={newYearData}
                                                ti={selectedTi} modeOfTraining={rows.filter(e => e.id == selectedTi)[0].learningByDoingMode}/>}
        </div>
      </div>
    </>
  );
}


export default TrainingInstitute;

