/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  downloadEnhancementAxisReport, downloadGlobalMaturityReport,
  downloadTrainingInstitutPercentageReport,
  downloadTrainingOperatorPercentageReport
} from "../../../crud/reporting.crud";
import { ReportType } from "../../../utils/report-type.utils";
import {
  canDownloadAggregatedCriteriaReport,
  canDownloadDFPReport,
  canDownloadEnhancmentReport,
  canDownloadGlobalMaturityReport,
  canDownloadOFReport
} from "../../../utils/roles.utils";

const PortletHeaderDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      id="kt_dashboard_daterangepicker"
      type="button"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
      className="btn btn-sm btn-outline-primary"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {/*<i className="flaticon-more-1">États de sortie</i>*/}
      États de sortie
    </button>
  );

});

const PortletHeaderDropdown = ({ year, onSelect, user }) => {
  return (
    <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
      <Dropdown.Toggle
        as={PortletHeaderDropdownToggle}
        id="dropdown-toggle-top"
      >
        <i className="flaticon-more-1"/>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
        <ul className="kt-nav">
          <li className="kt-nav__section kt-nav__section--first">
            <span className="kt-nav__section-text">États de sortie</span>
          </li>
          {canDownloadEnhancmentReport(user) &&
          <li className="kt-nav__item" onClick={() => onSelect(ReportType.IMPROVEMENT_AXIS_REPORT)}>
            <a className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-checking" style={{ color: "green" }}></i>
              <span className="kt-nav__link-text">Tableau de renseignement des axes d'amélioration</span>
            </a>
          </li>}
          {canDownloadOFReport(user) &&
          <li className="kt-nav__item" onClick={() => onSelect(ReportType.TRAINING_OPERATOR_REPORT)}>
            <a className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-checking" style={{ color: "green" }}></i>
              <span className="kt-nav__link-text">État de sortie pour l'opérateur de formation</span>
            </a>
          </li>}
          {canDownloadDFPReport(user) && <li className="kt-nav__item" onClick={() => onSelect(ReportType.DFP_REPORT)}>
            <a className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-checking" style={{ color: "green" }}></i>
              <span className="kt-nav__link-text">État de sortie à générer au profit du DFP</span>
            </a>
          </li>}
          {canDownloadGlobalMaturityReport(user) &&
          <li className="kt-nav__item" onClick={() => onSelect(ReportType.GLOBAL_MATURITY_REPORT)}>
            <a className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-checking" style={{ color: "green" }}></i>
              <span className="kt-nav__link-text">État de sortie taux de maturité globale par opérateur</span>
            </a>
          </li>}
          {canDownloadAggregatedCriteriaReport(user) &&
          <li className="kt-nav__item" onClick={() => onSelect(ReportType.AGGREGATED_CRITERIA)}>
            <a className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-checking" style={{ color: "green" }}></i>
              <span className="kt-nav__link-text">État de sortie Indicateurs statistiques agrégés</span>
            </a>
          </li>}
          {canDownloadAggregatedCriteriaReport(user) &&
          <li className="kt-nav__item" onClick={() => onSelect(ReportType.EFP_PAR_SESSION)}>
            <a className="kt-nav__link">
              <i className="kt-nav__link-icon flaticon2-checking" style={{ color: "green" }}></i>
              <span className="kt-nav__link-text">EFP par session</span>
            </a>
          </li>}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PortletHeaderDropdown;
