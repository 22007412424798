import React, { useEffect } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import {
  getTrainingDRHeaders,
  getTrainingComplexHeaders,
  getTrainingInstituteHeaders,
  getTrainingOperatorHeaders,
  register,
  update
} from "../../../crud/register.crud";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { getRoleLabel, Roles, UnderlyingRoles } from "../../../utils/roles.utils";
import { first } from "lodash";

const NewAccount = ({ openModalValue, closeModal, user, loadData, modify, dataToModify }) => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  const [trainingOperators, setTrainingOperators] = React.useState([]); // OFs
  const [trainingInstitutes, setTrainingInstitutes] = React.useState([]); // EFPs
  const [trainingComplexes, setTrainingComplexes] = React.useState([]); // CFs
  const [trainingDRs, setTrainingDRs] = React.useState([]); // CFs

  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: dataToModify
  });

  const roleWatcher = watch("role");

  useEffect(() => {
    switch (first(user.roles)) {
      case Roles.ADMIN:
      case Roles.DFP:
        loadTrainingOperators();
        break;
      case Roles.OF:
        loadTrainingComplexes();
        loadTrainingInstitutes();
        loadTrainingDRs();
        break;
      case Roles.CF:
        loadTrainingInstitutes();
      case Roles.DR:
        loadTrainingComplexes();
        loadTrainingInstitutes();
    }
  }, []);


  const loadTrainingOperators = () => {
    getTrainingOperatorHeaders()
      .catch(error => handleLoadRefFails(error, "OF"))
      .then(({ data }) => setTrainingOperators(data));
  };

  const loadTrainingInstitutes = () => {
    getTrainingInstituteHeaders()
      .catch(error => handleLoadRefFails(error, "EFP"))
      .then(({ data }) => setTrainingInstitutes(data));
  };

  const loadTrainingComplexes = () => {
    getTrainingComplexHeaders()
      .catch(error => handleLoadRefFails(error, "CF"))
      .then(({ data }) => setTrainingComplexes(data));
  };

  const loadTrainingDRs = () => {
    getTrainingDRHeaders()
      .catch(error => handleLoadRefFails(error, "CF"))
      .then(({ data }) => setTrainingDRs(data));
  };

  const handleLoadRefFails = (error, name) => {
    console.error("failed to laod refs", error, name);
    throw error;
  };

  const onSubmit = newUser => {
    if (!modify) {
      register(newUser)
        .catch((err) => {
          if (err.response?.data?.message) {
            notifyError(err.response?.data?.message);
          } else if(err?.response?.status === 403){
            notifyError("Operation non autorisee !");
          } else {
            notifyError("Une erreure technique lors de l'ajout de l'utilisateur");
          }
          throw err;
        })
        .then(response => {
          if (response.data) {
            notifySuccess("Le compte est crée avec succès");
            closeModal();
            loadData();
          }
        });
    } else {
      update(newUser).then(response => {
        if (response.data) {
          notifySuccess("Le compte est modifié avec succès");
          closeModal();
          loadData();
        }
      });
    }
  };

  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "auto",
    maxHeight: "65%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  };
  return (
    <Modal
      open={openModalValue}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        {!modify &&
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Ajouter un nouveau compte
        </Typography>
        }
        {modify &&
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Modifier un compte
        </Typography>
        }
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className={"row"}>
            <div className="col-md-4">
              <Controller
                className={"vertical-center"}
                name="firstName"
                rules={{ required: true }}
                control={control}
                render={({ field, value, onChange }) => <TextField
                  {...field}
                  label={"Prénom *"}
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}/>}
              />
              {errors.name && <span className={"label-error"}>Le prénom est obligatoire</span>}
            </div>
            <div className="col-md-4">
              <Controller
                className={"vertical-center"}
                name="lastName"
                rules={{ required: true }}
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  label={"Nom *"}
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}/>}
              />
              {errors.name && <span className={"label-error"}>Le nom est obligatoire</span>}
            </div>

            <div className="col-md-4">
              <Controller
                className={"vertical-center"}
                name="email"
                rules={{ required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g }}
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  label="E-mail *"
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}/>}
              />
              {errors.email && <span className={"label-error"}>Adresse e-mail invalide</span>}
            </div>

            <div className="col-md-4">
              <Controller
                className={"vertical-center"}
                name="phoneNumber"
                rules={{ pattern: /^(?:(?:(?:\+|00)212[\s]?(?:[\s]?\(0\)[\s]?)?)|0){1}(?:5[\s.-]?[2-3]|6[\s.-]?[13-9]){1}[0-9]{1}(?:[\s.-]?\d{2}){3}$/gm }}
                control={control}
                render={({ field }) => <TextField
                  {...field}
                  label="Téléphone"
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}/>}
              />
              {errors.phoneNumber && <span className={"label-error"}>Numéro de telephone invalide</span>}
            </div>
            {!modify &&
            <>
              <div className="col-md-4">
                <Controller
                  className={"vertical-center"}
                  name="password"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <TextField
                    {...field}
                    type="password"
                    label={"Mot de passe *"}
                    variant="outlined"
                    size="small"
                    style={{ marginTop: "10px" }}/>}
                />
                {errors.password && <span className={"label-error"}>Le mot de passe est obligatoire</span>}
              </div>


              <div className="col-md-4">
                <Controller
                  name="role"
                  className={"vertical-center"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <TextField
                      select
                      {...field}
                      size={"small"}
                      variant="outlined"
                      label="Role *"
                      style={{ marginTop: 10 }}
                    >
                      {(UnderlyingRoles[first(user.roles)] || []).map(item => <MenuItem key={item}
                                                                                        value={item}>{getRoleLabel(item)}</MenuItem>)}
                    </TextField>
                  }
                />
                {errors.role && <span className={"label-error"}>Le choix du role est obligatoire</span>}
              </div>

              {roleWatcher === Roles.OF && (<div className="col-md-4">
                <Controller
                  name="trainingOperator"
                  className={"vertical-center"}
                  control={control}
                  rules={{ required: roleWatcher === Roles.OF }}
                  render={({ field }) =>
                    <TextField
                      select
                      {...field}
                      size={"small"}
                      variant="outlined"
                      label="Operateur de formation *"
                      style={{ marginTop: 10 }}
                    >
                      {trainingOperators.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </TextField>

                  }
                />
                {errors.trainingOperator && <span className={"label-error"}>Le choix d'un OF est obligatoire</span>}
              </div>)}

              {[Roles.EFP_SAISIE, Roles.EFP_VALIDATEUR].includes(roleWatcher) && (<div className="col-md-4">
                <Controller
                  name="trainingInstitute"
                  className={"vertical-center"}
                  control={control}
                  rules={{ required: [Roles.EFP_SAISIE, Roles.EFP_VALIDATEUR].includes(roleWatcher) }}
                  render={({ field }) =>
                    <TextField
                      select
                      {...field}
                      size={"small"}
                      variant="outlined"
                      label="Etablissements de FP *"
                      style={{ marginTop: 10 }}
                    >
                      {trainingInstitutes.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </TextField>
                  }
                />
                {errors.trainingInstitute && <span className={"label-error"}>Le choix d'un EFP est obligatoire</span>}
              </div>)}

              {roleWatcher === Roles.CF && (<div className="col-md-4">
                <Controller
                  name="trainingComplex"
                  className={"vertical-center"}
                  control={control}
                  rules={{ required: roleWatcher === Roles.CF }}
                  render={({ field }) =>
                    <TextField
                      select
                      {...field}
                      size={"small"}
                      variant="outlined"
                      label="Complexe de formation *"
                      style={{ marginTop: 10 }}
                    >
                      {trainingComplexes.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </TextField>
                  }
                />
                {errors.trainingComplex && <span className={"label-error"}>Le choix d'un CF est obligatoire</span>}
              </div>)}

              {roleWatcher === Roles.DR && (<div className="col-md-4">
                <Controller
                  name="trainingDR"
                  className={"vertical-center"}
                  control={control}
                  rules={{ required: roleWatcher === Roles.DR }}
                  render={({ field }) =>
                    <TextField
                      select
                      {...field}
                      size={"small"}
                      variant="outlined"
                      label="Direction regionale *"
                      style={{ marginTop: 10 }}
                    >
                      {trainingDRs.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                    </TextField>
                  }
                />
                {errors.regionalDirection && <span className={"label-error"}>Le choix d'un CF est obligatoire</span>}
              </div>)}
            </>
            }


          </div>

          <div className="row mt-2 mb-1 mt-4">
            <div className="col-md-12">
              <div style={{ textAlign: "center" }}>
                <Button variant="outlinedSecondary" startIcon={<Cancel/>}
                        style={{ marginRight: "5px" }} onClick={closeModal}>
                  Annuler
                </Button>
                <Button variant="containedPrimary" endIcon={<Save/>} type="submit">
                  {modify ? "Modifier" : "Ajouter"}
                </Button>
              </div>
            </div>
          </div>
        </form>

      </Box>
    </Modal>
  );
};

export default NewAccount;
