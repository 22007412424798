import axios from "axios";
import {getRefProcess} from "./process-management.crud";
import {first, groupBy, keys, map, mapValues, merge, sortedUniq} from "lodash";

const BASE_URL = "referential-quiz";
const METADATA_URL = "referential-quiz/metadata";
const SUBMIT_ANSWER_URL = "referential-quiz/answers/save";
const COMPLETE_SUBMISSION_URL = "referential-quiz/complete";
const ANSWERS_BY_STANDARD_URL = "referential-quiz/answer-by-standard/";
const ANSWERS_BY_REFERENCE_AND_OPERATOR_URL = "referential-quiz/recap/ti";
const ANSWERS_BY_REFERENCE_AND_COMPLEX_URL = "referential-quiz/recap/tc";
const ANSWERS_BY_REFERENCE_AND_DR_URL = "referential-quiz/recap/dr";
const ANSWERS_BY_STANDARD_AND_OPERATOR_URL = "referential-quiz/recap/to/standard";
const ANSWERS_BY_STANDARD_AND_COMPLEX_URL = "referential-quiz/recap/tc/standard";
const ANSWERS_BY_STANDARD_AND_DR_URL = "referential-quiz/recap/dr/standard";
const ANSWERS_BY_PROCESS_URL = "referential-quiz/answer-by-process/";
const ANSWERS_RECAP_URL = "referential-quiz/recap/";
const ANSWERS_GLOBAL_RECAP_URL = "referential-quiz/global-recap/";
const ALL_YEAR = "reporting/all-quiz-years";
const ALL_YEARS_LIST = "reporting/all-quiz-years-list";

function getQuizMetadata(trainingInstituteId) {
    return axios.get(METADATA_URL, {params: {trainingInstituteId}});
}

function getReferentialQuiz(trainingInstituteId) {
    return axios.get(BASE_URL, {params: {trainingInstituteId}});
}

export function setUpQuiz(trainingInstituteId, year) {
    return axios.post(BASE_URL, {trainingInstituteId, year});
}

export function saveAnswers(responses) {
    return axios.put(SUBMIT_ANSWER_URL, responses);
}

export function completeSubmit(trainingInstituteId, year, responses) {
    return axios.put(COMPLETE_SUBMISSION_URL, {quizId: {trainingInstituteId, year}, data: responses});
}

export function loadAllQuizData(trainingInstituteId) {
    return Promise.all([getQuizMetadata(trainingInstituteId), getRefProcess(), getReferentialQuiz(trainingInstituteId)])
        .then(([{data: metadata}, {data: process}, {data: answersByYear}]) => {
            const years = sortedUniq(keys(answersByYear));
            answersByYear = mapValues(answersByYear, (value) => mapValues(groupBy(value, 'criteriaId'), first));
            return {
                metadata,
                process,
                years,
                answersByYear
            };
        });
}

export function getReferentialAnswersByStandard(year, id) {
    return axios.get(ANSWERS_BY_STANDARD_URL + id + '/' + year);
}

export function getReferentialAnswersByProcess(year, id) {
    return axios.get(ANSWERS_BY_PROCESS_URL + id + '/'+ year);
}

export function getReferentialAnswersRecap(year, id, type) {
    return axios.get(ANSWERS_RECAP_URL + id + '/'+ year+ '/'+ type);
}

export function getGlobalRecap(year, id) {
    return axios.get(ANSWERS_GLOBAL_RECAP_URL + id + '/'+ year);
}

export function getAllYears() {
    return axios.get(ALL_YEAR);
}

export function getAllYearsList() {
    return axios.get(ALL_YEARS_LIST);
}

export function getRecapByProcessAndTrainingOperator(year, id) {
    return axios.get(ANSWERS_BY_REFERENCE_AND_OPERATOR_URL + '/' + id + '/'+ year);
}
export function getRecapByProcessAndTrainingComplex(year, id) {
  return axios.get(ANSWERS_BY_REFERENCE_AND_COMPLEX_URL  + '/' + id+ '/'+ year );
}

export function getRecapByProcessAndTrainingDR(year, id) {
  return axios.get(ANSWERS_BY_REFERENCE_AND_DR_URL  + '/' + id+ '/'+ year );
}

export function getRecapByStandardAndTrainingOperator(year, id) {
    return axios.get(ANSWERS_BY_STANDARD_AND_OPERATOR_URL + '/' + id + '/'+ year);
}

export function getRecapByStandardAndTrainingComplex(year, id) {
    return axios.get(ANSWERS_BY_STANDARD_AND_COMPLEX_URL + '/' + id + '/'+ year);
}

export function getRecapByStandardAndTrainingDR(year, id) {
    return axios.get(ANSWERS_BY_STANDARD_AND_DR_URL + '/' + id + '/'+ year);
}
