import React from 'react';
import Skeleton from "@mui/material/Skeleton/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";


const PageLoading = () => {
  return (
    <div className={'kt-portlet'}>
      <div className={'kt-portlet__body'}>
        <h5 className={'d-flex align-items-center'} style={{gap: '30px'}}><span>Chargement de la page</span> <CircularProgress color={'inherit'} /></h5>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
        <Skeleton animation="pulse" className={'avatarLoader'}/>
      </div>
    </div>
  )
};

export default PageLoading;
