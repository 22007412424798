import { includes } from "lodash";


/**
 * Roles definition
 * @type {{ADMIN: string, OF: string, CF: string, DFP: string, EFP: string}}
 */

export const Roles = Object.freeze({
  ADMIN: "ROLE_ADMIN",
  OF: "ROLE_OF",
  CF: "ROLE_CF",
  DFP: "ROLE_DFP",
  EFP_SAISIE: "ROLE_EFP_SAISIE",
  EFP_VALIDATEUR: "ROLE_EFP_VALIDATEUR",
  DR: "ROLE_DR"
});

const { ADMIN, OF, CF, DFP, EFP_SAISIE, EFP_VALIDATEUR, DR } = Roles;

/**
 * Functions for access check by roles
 */

export const canAcessDashboard = user => includes([ADMIN, DFP, OF, CF, EFP_SAISIE, EFP_VALIDATEUR, DR], user.role);
export const canAccessProcessManagement = user => includes([ADMIN, DFP], user.role);
export const canAccessReferentialManagement = user => includes([ADMIN, DFP], user.role);
export const canAccessRegionManagement = user => includes([ADMIN, DFP], user.role);
export const canAccessSectorManagement = user => includes([ADMIN, DFP], user.role);
export const canAccessTrainingOperator = () => [ADMIN, DFP, OF];
export const canAccessTrainingDR = () => [ADMIN, DFP, OF, DR];
export const canAccessTrainingComplex = () => [ADMIN, DFP, OF, CF, DR];
export const canAccessTrainingInstitute = () => [ADMIN, DFP, OF, CF, DR];
export const canAccessTrainingInstituteMenu = () => [EFP_SAISIE, EFP_VALIDATEUR];
export const canManageUsers = () => [ADMIN, DFP, OF, CF, DR];
export const canAddTrainingInstitute = (user) => includes([ADMIN, DFP, OF, CF], user.role);
export const canRemoveTrainingInstitute = (user) => includes([ADMIN, DFP, OF, CF], user.role);
export const canUpdateTrainingInstitute = (user) => includes([ADMIN, DFP, OF, CF, EFP_SAISIE, EFP_VALIDATEUR], user.role);
export const canAddTrainingOperator = (user) => includes([ADMIN, DFP, CF], user.role);
export const canRemoveTrainingOperator = (user) => includes([ADMIN, DFP], user.role);
export const canUpdateTrainingOperator = (user) => includes([ADMIN, DFP, OF], user.role);
export const canRemoveTrainingComplex = (user) => includes([ADMIN, DFP, OF, DR], user.role);
export const canUpdateTrainingComplex = (user) => includes([ADMIN, DFP, OF, DR], user.role);
export const canSaveTrainingInstituteAnswers = (user) => includes([EFP_SAISIE], user.role);
export const canSubmitTrainingInstituteAnswers = (user) => includes([EFP_VALIDATEUR], user.role);
export const isReadOnlyTrainingInstituteAnswers = (user) => user.role !== EFP_SAISIE;
export const canSetupReferentialNewYear = (user) => includes([DFP], user.role);
export const canDownloadEnhancmentReport = (user) => includes([ADMIN, OF, DFP, EFP_SAISIE, EFP_VALIDATEUR], user.role);
export const canDownloadOFReport = (user) => includes([ADMIN, DFP, OF], user.role);
export const canDownloadDFPReport = (user) => includes([ADMIN, DFP], user.role);
export const canDownloadGlobalMaturityReport = (user) => includes([ADMIN, DFP, OF], user.role);
export const canDownloadAggregatedCriteriaReport = (user) => includes([ADMIN, DFP, OF], user.role);
export const canAccessReferentialQuiz = (user) => includes([ADMIN, DFP, OF, CF, EFP_SAISIE, EFP_VALIDATEUR, DR], user.role);
export const canAccessMyProfile = (user) => includes([ADMIN, DFP, OF, CF, EFP_SAISIE, EFP_VALIDATEUR, DR], user.role);
export const canAccessLogViewer = (user) => includes([ADMIN, DFP], user.role);
export const canSeeFillStatus = (user) => includes([OF, DR, DFP, ADMIN], user.role);


/**
 * Undelying roles for each role
 */
export const UnderlyingRoles = {
  [ADMIN]: [ADMIN, OF, DFP],
  [DFP]: [DFP, OF],
  [OF]: [DR, CF, EFP_SAISIE, EFP_VALIDATEUR],
  [CF]: [EFP_SAISIE, EFP_VALIDATEUR],
  [DR]: [CF, EFP_SAISIE, EFP_VALIDATEUR]
};


/**
 * Define a label for each role that the user can visualize
 */
const roleToLabel = Object.freeze({
  [ADMIN]: "Administrateur",
  [DFP]: "DFP",
  [OF]: "Operateur de formation",
  [CF]: "Complexe de formation",
  [EFP_SAISIE]: "EFP/Agent de saisie",
  [EFP_VALIDATEUR]: "EFP/Lecteur",
  [DR]: "Direction régionale"
});


export const getRoleLabel = role => {
  return roleToLabel[role] || "unsupported";
};


export const canAccessPage = (path, params, user) => {
  switch (path) {
    case "/dashboard":
      return canAcessDashboard(user);
    case "/accounts":
      return includes(canManageUsers(), user.role);
    case "/training-institute":
      return includes(canAccessTrainingInstitute(), user.role) || includes(canAccessTrainingInstituteMenu(), user.role);
    case "/direction-regionale":
      return includes(canAccessTrainingDR(), user.role);
    case "/training-operator":
      return includes(canAccessTrainingOperator(), user.role);
    case "/training-complex":
      return includes(canAccessTrainingComplex(), user.role);
    case "/referential-quiz/:id":
      return canAccessReferentialQuiz(user);
    case "/process-management":
      return canAccessProcessManagement(user);
    case "/my-profile":
      return canAccessMyProfile(user);
    case "/referential-management":
      return canAccessReferentialManagement(user);
      case "/session-management":
        return canAccessReferentialManagement(user);
    case "/region-management":
      return canAccessRegionManagement(user);
    case "/sector-management":
      return canAccessSectorManagement(user);
    case "/logs-viewer":
      return canAccessLogViewer(user);
    default:
      console.log("access not managed for path", path);
  }
};




