import axios from "axios";

export const TRAINING_OPERATOR_URL = "training-operators";
export const TRAINING_COMPLEX_URL = "training-complexes";
export const TRAINING_DR_URL = "training-dr";
export const CITIES_URL = "cities";
export const REGIONS_URL = "regions";
export const USERS_URL = "user/list";

export function getAllTrainingOperators() {
  return axios.get(TRAINING_OPERATOR_URL);
}

export function getTrainingOperator(id) {
  return axios.get(`${TRAINING_OPERATOR_URL}/${id}`);
}

export function deleteTrainingOperator(id) {
  return axios.delete(TRAINING_OPERATOR_URL, { params: { id } });
}

export function getAllRegions() {
  return axios.get(REGIONS_URL);
}

export function getAllCities() {
  return axios.get(CITIES_URL);
}

export function getAllUsers() {
  return axios.get(USERS_URL);
}

export function addNewTrainingOperator(name, email, phoneNumber, address, city, responsible) {
  return axios.post(TRAINING_OPERATOR_URL, {
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    address: address,
    city: { id: city }
  });
}

export function editTrainingOperator(id, name, email, phoneNumber, address, city, responsible) {
  return axios.put(TRAINING_OPERATOR_URL, {
    id: id,
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    address: address,
    city: { id: city }
  });
}

export function addNewTrainingComplex(trainingComplex) {
  return axios.post(TRAINING_COMPLEX_URL, trainingComplex);
}

export function editTrainingComplex(trainingComplex) {
  return axios.put(TRAINING_COMPLEX_URL, trainingComplex);
}

export function deleteTrainingComplex(id) {
  return axios.delete(TRAINING_COMPLEX_URL, { params: { id } });
}

export function getAllTrainingComplexes() {
  return axios.get(TRAINING_COMPLEX_URL);
}

export function addNewTrainingDr(trainingDr) {
  return axios.post(TRAINING_DR_URL, trainingDr);
}

export function editTrainingDr(trainingComplex) {
  return axios.put(TRAINING_DR_URL, trainingComplex);
}

export function deleteTrainingDr(id) {
  return axios.delete(TRAINING_DR_URL, { params: { id } });
}

export function getAllTrainingDr() {
  return axios.get(TRAINING_DR_URL);
}
