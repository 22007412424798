import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {Controller, useForm} from "react-hook-form";
import {getAllTrainingSectors, getAllTrainingTypes} from "../../../../../crud/trainingInstitute.crud";
import {v4 as uuidv4} from "uuid";

export const Formations = ({formationsData, handleFormationsData}) => {
    const [sectors, setSectors] = useState([]);

    const [branches, setbranches] = useState([]);
    const [types, setTypes] = useState([]);
    const {watch, control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: formationsData
    });
    const ITEM_HEIGHT = 25;
    const ITEM_PADDING_TOP = 2;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const onSubmit = data => {
        handleFormationsData(data);
    };

    useEffect(() => {
        let secs = [];
        getAllTrainingSectors().then(response => {
            setSectors(response.data);
            secs = response.data;
          console.log("test xx", sectors)
          let tmpBranches = [];
          formationsData.trainingSectors.forEach(e => {
            console.log("test 3", secs)
            tmpBranches = [...tmpBranches, ...secs.filter(sec => sec.id == e)[0].branches]
          });
          setbranches(tmpBranches);

        });

        getAllTrainingTypes().then(response => {
            setTypes(response.data);
        });

        const subscription = watch((value, {name, type}) => {
          let tmpBranches = [];

          if(name == 'trainingSectors'){
            console.log('test 1 ', value)

              value.trainingSectors.forEach(e => {
                  console.log("test 3", secs)
                  tmpBranches = [...tmpBranches, ...secs.filter(sec => sec.id == e)[0].branches]
              });
            setbranches(tmpBranches);
          }

          handleFormationsData(value)
        });
        return () => subscription.unsubscribe();

    }, [watch]);

    return (
        <div className="row">
            <div className="col-md-12">
                <Accordion className={'mb-2'}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Formations</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Controller
                                        className={'vertical-center'}
                                        name="trainingSectors"
                                        control={control}
                                        render={({field}) =>
                                            <FormControl>

                                                <TextField
                                                    select
                                                    {...field}
                                                    size={'small'}
                                                    variant="outlined"
                                                    label="Secteur de formation"
                                                    SelectProps={{
                                                        multiple: true,
                                                        value: field.value,
                                                    }}
                                                >
                                                    {sectors.map((e) => (
                                                        <MenuItem key={uuidv4()} value={e.id}>
                                                            {e.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                            </FormControl>
                                        }
                                    />
                                </div>
                                <div className="col-md-4">

                                    <Controller
                                        key={uuidv4()}
                                        className={'vertical-center'}

                                        name="learningByDoingMode"
                                        rules={{required: false}}
                                        control={control}
                                        render={({field}) => <FormControl>
                                            <TextField
                                                select
                                                {...field}
                                                size={'small'}
                                                variant="outlined"
                                                label="Mode de formation par apprentissage"
                                                SelectProps={{
                                                    value: field.value,
                                                }}
                                            >
                                                <MenuItem value={true}>Oui</MenuItem>
                                                <MenuItem value={false}>Non</MenuItem>
                                            </TextField>

                                        </FormControl>}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Controller
                                        key={uuidv4()}
                                        className={'vertical-center'}
                                        size={'small'}
                                        name="trainingTypes"
                                        rules={{required: false}}
                                        control={control}
                                        render={({field}) => <FormControl style={{width: '100%'}}
                                                                          className={'vertical-center'}>
                                            <TextField
                                                select
                                                {...field}
                                                size={'small'}
                                                variant="outlined"
                                                label="Type de formation"
                                                SelectProps={{
                                                    multiple: true,
                                                    value: field.value,
                                                }}
                                            >
                                                {types.map((e) => (
                                                    <MenuItem key={uuidv4()} value={e.id}>
                                                        {e.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                        </FormControl>}
                                    />
                                </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <Controller
                                  className={'vertical-center'}
                                  name="branches"
                                  control={control}
                                  render={({field}) =>
                                    <FormControl>

                                      <TextField
                                        select
                                        {...field}
                                        size={'small'}
                                        variant="outlined"
                                        label="Filière"
                                        SelectProps={{
                                          multiple: true,
                                          value: field.value,
                                        }}
                                      >
                                        {branches.map((e) => (
                                          <MenuItem key={uuidv4()} value={e.id}>
                                            {e.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>

                                    </FormControl>
                                  }
                                />
                              </div>
                            </div>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
};

export default Formations;
