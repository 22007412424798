import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import { uniq } from "lodash";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { genericFilterAndPagination } from "../../../utils/search.utils";
import CustomPagination from "../../../../shared/component/CustomPagination";
import { getLogs } from "../../../crud/notification.crud";
import LogViewerFilter from "./LogViewerFilter";

const INITIAL_FILTER_STATE = {
  size: 100,
  dateRange: [null, null]
};

function LogViewer({ }) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [filters, setFilters] = React.useState(INITIAL_FILTER_STATE);

  const { notifyError } = useNotification(useSnackbar());

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getLogs(0, filters.size, filters.dateRange[0], filters.dateRange[1])
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des utilisateurs !");
        throw err;
      })
      .then(({ data }) => {
        setRows(data.content);
      });
  };

  const applyFilter = () => {
    loadData();
  };

  const { data, dataLength } = genericFilterAndPagination(rows, {}, page, rowsPerPage);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-12">
          <div style={{ float: "right" }}>
          </div>
        </div>
      </div>

      <LogViewerFilter applyFilter={applyFilter} setFilter={setFilters}
                       filter={filters}
                       resetFilter={() => {
                         setFilters(INITIAL_FILTER_STATE);
                         loadData();
                       }}/>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Log</TableCell>
                <TableCell align="right">Utilisateur</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || []).map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{row.message}</TableCell>
                  <TableCell align="right">{row.user?.firstName + " " + row.user?.lastName}</TableCell>
                  <TableCell align="right">{row.created}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>

                <CustomPagination
                  totalRows={dataLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
    </>
  );
}


export default LogViewer;
