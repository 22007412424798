import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getRecapByStandardAndTrainingComplex, getRecapByStandardAndTrainingDR,
  getRecapByStandardAndTrainingOperator,
  getReferentialAnswersByStandard
} from "../../../crud/referential-quiz.crud";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import * as _ from "lodash";
import TableContainer from "@mui/material/TableContainer/TableContainer";


const MaturityLevelMeasures = ({ year, ti, to, tc, dr }) => {
  const [rows, setRows] = useState([]);
  const [toShowTooltipIndex, setToShowTooltipIndex] = useState(null);

  useEffect(() => {
    // @TODO to be changed
    if (year) {
      let promise = null;

      if (ti) {
        getReferentialAnswersByStandard(year, ti).then(response => {
          if (response.data) {

            let fakeTable = [];
            Object.keys(response.data).sort((a, b) => response.data[a][0].id - response.data[b][0].id).forEach(e => {
              fakeTable.push(
                {
                  standardId: response.data[e][0].id,
                  processId: response.data[e][0].processId,
                  standard: e,
                  label: response.data[e][0].label,
                  criteria: _.orderBy(response.data[e], ["criteriaOrder"], ["asc"]),
                  aggregatedResult: (response.data[e].map(el => el.mtLevel).reduce((e, i) => e + i) / response.data[e].length).toFixed(2)
                });
            });

            setRows(fakeTable);
          }
        });
      }

      else if (tc) {
        promise = getRecapByStandardAndTrainingComplex(year, tc);

      } else if (dr) {

        promise = getRecapByStandardAndTrainingDR(year, dr);

      } else if (to) {
        promise = getRecapByStandardAndTrainingOperator(year, to);
      }

      if (promise) {
        promise.then(response => {
          setRows([]);
          if (response.data) {
            let result = [];
            if (response.data.length) {
              result = response.data.reduce(function(r, a) {
                r[a.standardId] = r[a.standardId] || [];
                r[a.standardId].push(a);
                return r;
              }, Object.create(null));
              setRows(Object.keys(result).map(e => {
                return {
                  processId: result[e][0].processId,
                  standard: result[e][0].standardId,
                  label: result[e][0].standardLabel,
                  criteria: result[e],
                  aggregatedResult: (result[e].map(el => el.mtLevel).reduce((e, i) => e + i) / result[e].length).toFixed(2),
                };
              }));
            }
          }
        });
      }


    }
  }, [year, ti, to, tc, dr]);
  return (
    <>
      <h6 className={"title-recap"}>Tableau récapitulatif des niveaux de maturité par standard</h6>
      <TableContainer sx={{ maxHeight: 250 }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Standard</TableCell>
              <TableCell align="center">Résultat agrégé</TableCell>
              <TableCell align="center">Critères</TableCell>
              <TableCell align="center">Niveau de maturité ateint</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(item => (
              <>
                <TableRow>

                  <TableCell rowSpan={item.criteria.length + 1} align="center">
                    <Tooltip className="m-1 btn-pointer"
                             title={<p style={{ "white-space": "pre-wrap" }}> {item.label} </p>}
                             placement="top-start">
                      <span>{item.standard}</span>
                    </Tooltip>

                  </TableCell>

                  <TableCell rowSpan={item.criteria.length + 1} align="center"
                             className={(item.aggregatedResult <= 2) ? "weakness-point" : "strength-point"}>
                    {item.aggregatedResult}
                  </TableCell>
                </TableRow>
                {item.criteria.map(detail => (
                  <TableRow
                    key={item.standard + "-" + detail.criteriaOrder + "-" + detail.criteria}>
                    <TableCell align="center">
                      <Tooltip open={item.standard + "-" + detail.criteriaOrder + "-" + detail.criteria === toShowTooltipIndex}
                               onClose={() => {
                                 console.log('toto' );
                                 setToShowTooltipIndex(null);
                               } }
                               className="m-1 btn-pointer" title={<p
                        style={{ "white-space": "pre-wrap" }}>  {String(item.standard).replace("Standard", "") + "." + detail.criteriaOrder + " - " + detail.criteriaLabel} </p>}
                               placement="top-start">
                        <span onClick={() => setToShowTooltipIndex(item.standard + "-" + detail.criteriaOrder + "-" + detail.criteria)}>{ item.processId + "." + String(item.standard).replace("Standard", "") + "." + detail.criteriaOrder}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className={(detail.mtLevel <= 2) ? "weakness-point" : "strength-point"}
                               style={{ fontWeight: "bold" }}>{detail.mtLevel}</TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MaturityLevelMeasures;
