import { TablePagination } from "@material-ui/core";
import React from "react";
import TablePaginationActions from "./TablePaginationActions";

const CustomPagination = ({ totalRows, rowsPerPage, page, onPageChange, onRowsPerPageChange }) => {
  return (<TablePagination
    rowsPerPageOptions={[5, 10, 25, 50]}
    colSpan={5}
    count={totalRows}
    rowsPerPage={rowsPerPage}
    page={page}
    SelectProps={{
      inputProps: {
        "aria-label": "Nb de lignes par page"
      },
      native: true
    }}
    labelRowsPerPage={"Lignes par page"}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
    ActionsComponent={TablePaginationActions}
  />);
};

export default CustomPagination;
