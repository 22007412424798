import React, { useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { updatePassword } from "../../../crud/auth.crud";

const UpdatePasswordModal = ({ visibility, closeModal, user, withOldPassword }) => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: ""
    }
  });

  useEffect(() => {

  }, []);


  const onSubmit = ({ oldPassword, newPassword, newPasswordConfirm }) => {
    if ( newPassword !== newPasswordConfirm){
      return notifyError('Les mots de passes saisies ne sont pas indetiques !');
    }
    updatePassword({ oldPassword, newPassword, newPasswordConfirm, userId: user.id })
      .catch(err => {
        if(err.response?.data?.message){
          notifyError(err.response?.data?.message);
        } else {
          notifyError("Une erreure technique lors de la modification du mot de passe !");
        }
        throw err;
      })
      .then(() => {
        notifySuccess("Le mot de passe a ete mis a jout avec succes !");
        closeModal();
      });
    console.log('goo !');

  };

  const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "auto",
    maxHeight: "65%",
    overflowY: "scroll",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  };
  return (
    <Modal
      open={visibility}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Modification du mot de passe ({user?.email})
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>

          <div className={"row"}>
            {withOldPassword && (<div className="col-md-4">
              <Controller
                className={"vertical-center"}
                name="oldPassword"
                rules={{ required: true }}
                control={control}
                render={({ field, value, onChange }) => <TextField
                  {...field}
                  type="password"
                  label={"Ancien mot de passe *"}
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}/>}
              />
              {errors.oldPassword && <span className={"label-error"}>L'ancien mot de passe est obligatore !</span>}
            </div>)}

            <div className="col-md-4">
              <Controller
                className={"vertical-center"}
                name="newPassword"
                rules={{ required: true, minLength: 6 }}
                control={control}
                render={({ field, value, onChange }) => <TextField
                  {...field}
                  type="password"
                  label={"Nouveau mot de passe *"}
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}/>}
              />
              {errors.newPassword &&
              <span className={"label-error"}>Le mot de passe est obligatoire et doit passer 6 caractères</span>}
            </div>

            <div className="col-md-4">
              <Controller
                className={"vertical-center"}
                name="newPasswordConfirm"
                rules={{ required: true, minLength: 6 }}
                control={control}
                render={({ field, value, onChange }) => <TextField
                  {...field}
                  type="password"
                  label={"Confirmation du nouveau mot de passe *"}
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}/>}
              />
              {errors.newPasswordConfirm &&
              <span className={"label-error"}>Le mot de passe est obligatoire et doit passer 6 caractères</span>}
            </div>


          </div>

          <div className="row mt-2 mb-1 mt-4">
            <div className="col-md-12">
              <div style={{ textAlign: "center" }}>
                <Button variant="outlinedSecondary" startIcon={<Cancel/>}
                        style={{ marginRight: "5px" }} onClick={closeModal}>
                  Annuler
                </Button>
                <Button variant="containedPrimary" endIcon={<Save/>} type="submit">
                  Modifier
                </Button>
              </div>
            </div>
          </div>
        </form>

      </Box>
    </Modal>
  );
};

export default UpdatePasswordModal;
