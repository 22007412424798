import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  deleteTrainingComplex,
  getAllTrainingComplexes,
  getAllTrainingOperators
} from "../../../../crud/training.crud";
import TrainingComplexFilter from "./TrainingComplexFilter";
import NewTrainingComplex from "./NewTrainingComplex";
import { useNotification } from "../../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { canRemoveTrainingComplex, canUpdateTrainingComplex } from "../../../../utils/roles.utils";
import { actionConfirm } from "../../../../../shared/utils/confirm.util";
import { useConfirm } from "material-ui-confirm";
import { AddButton, DeleteButton, EditButton } from "../../../../../shared/component/ActionsButton";
import TableLoading from "../../../../../shared/component/TableLoading";
import { genericFilterAndPagination } from "../../../../utils/search.utils";
import CustomPagination from "../../../../../shared/component/CustomPagination";


const INITIAL_FILTER_STATE = { name: "", email: "", phoneNumber: "", cityId: null, regionId: null };

const TrainingComplex = ({ user }) => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filter, setFilter] = React.useState(INITIAL_FILTER_STATE);
  const [loading, setLoading] = useState(false);
  const [addAction, setAddAction] = useState(true);
  const [toModify, setToModify] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const confirm = actionConfirm(useConfirm());


  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    loadTrainingComplexes();
  }, [openModal]);

  const loadTrainingComplexes = () => {
    setLoading(true);
    getAllTrainingComplexes()
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des complexes de formation !");
        throw err;
      })
      .then(({ data }) => {
        console.log('data')
        console.log(data)
        setRows(data);
        setLoading(true);
      });
  };


  const deleteComplex = (id, name) => {
    confirm(`Etes vous sure de vouloir supprimer le complexe de formation '${name}' et ses utilisateurs ? `)
      .then(() => deleteTrainingComplex(id).catch(err => {
          notifyError("Une erreure technique lors de la supression du complexe de formation !");
          throw err;
        }).then(() => {
          loadTrainingComplexes();
          notifySuccess("Complexe de formation supprime avec succès !");
        })
      );
  };

  const { data, dataLength } = genericFilterAndPagination(rows, filter, page, rowsPerPage);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-12">
          <div style={{ float: "right" }}>
            <AddButton onClick={() => {
              setToModify(null);
              setOpenModal(true);
              setAddAction(true);
            }}/>
          </div>
        </div>
      </div>
      <TrainingComplexFilter filter={filter} setFilter={setFilter} resetFilter={() => setFilter(INITIAL_FILTER_STATE)}/>
      <div className={"kt-portlet"}>

        <div className={"kt-portlet__body"}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Intitulé</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Téléphone</TableCell>
                <TableCell align="right">Ville/Commune</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            {loading ?

              <TableBody>
                {(data || []).map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.phoneNumber}</TableCell>
                    <TableCell align="right">{row.city.name}</TableCell>
                    <TableCell align="right">

                      {canUpdateTrainingComplex(user) && <EditButton onClick={() => {
                        setAddAction(false);
                        setToModify(row);
                        setOpenModal(true);
                      }}/>}
                      {canRemoveTrainingComplex(user) &&
                      <DeleteButton onClick={() => deleteComplex(row.id, row.name)}/>}

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              :
              <TableLoading/>}
            <TableFooter>
              <TableRow>
                <CustomPagination
                  totalRows={dataLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
      <NewTrainingComplex openModalValue={openModal} closeModal={closeModal} key={openModal} addAction={addAction}
                          rowData={toModify}  />
    </>

  );
};

export default TrainingComplex;
