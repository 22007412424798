import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { ExpandMore } from "@material-ui/icons";
import {
  getAllRegions,
  getAllTrainingComplexes,
  getAllTrainingOperators
} from "../../../../../crud/training.crud";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import { useNotification } from "../../../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { Roles } from "../../../../../utils/roles.utils";


export const InstituteIdentity = ({ identityData, handleIdentityData, tiId, user }) => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());
  const [trainingOperators, setTrainingOperators] = useState([]);
  const [trainingComplexes, setTrainingComplexes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [selectedRegion, setSelectedRegion] = React.useState(null);

  const handleChangeRegion = (event) => {
    setSelectedRegion(event.target.value);
    setValue('region', event.target.value);
    setCities(regions.filter(e => e.id == event.target.value)[0].cities);
  };

  const { watch, control, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: identityData
  });

  const onSubmit = data => {

  };

  useEffect(() => {
    getAllTrainingOperators(null, null, null).then(response => {
      setTrainingOperators(response.data);

      if (tiId) {
        response.data.forEach(e => {
          e.trainingInstitutes.forEach(ti => {
            if (ti.id == tiId) {
              setValue("trainingOperator", e.id);
            }
          });
        });
      } else if (response.data.length > 0) {
        setValue("trainingOperator", response.data[0].id);
      }
    });

    getAllTrainingComplexes()
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des complexes de formation !");
        throw err;
      })
      .then(({ data }) => {
        setTrainingComplexes(data);
      });

    getAllRegions().then(response => {
      setRegions(response.data);
    }, []);


    const subscription = watch((value, { name, type }) => {
      handleIdentityData(value);

    });
    return () => subscription.unsubscribe();

  }, [watch]);

  useEffect(() => {
    regions.forEach(e => {
      e.cities.forEach(city => {
        if (city.id == identityData.city) {
          setSelectedRegion(e.id);
          setCities(e.cities);
          setSelectedCity(identityData.city);
          setValue("region", e.id);
          setValue("city", identityData.city);
        }
      });
    });
  }, [regions]);

  return (
    <div className="row">
      <div className="col-md-12">
        <Accordion className={"mb-2"}>
          <AccordionSummary
            expandIcon={<ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Identité de l'EFP</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="row mb-2">
                <div className="col-md-3">
                  <Controller
                    className={"vertical-center"}
                    name="dfpCodification"
                    control={control}
                    render={({ field }) => <TextField
                      {...field}
                      label={"Codiffication DFP"}
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}/>}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    className={"vertical-center"}
                    name="trainingOperatorCodification"
                    control={control}
                    render={({ field }) => <TextField
                      {...field}
                      label={"Codiffication OF"}
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}/>}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    className={"vertical-center"}
                    name="name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <TextField
                      {...field}
                      label={"Intitulé *"}
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}/>}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    className={"vertical-center"}
                    name="address"
                    control={control}
                    render={({ field }) => <TextField
                      {...field}
                      label={"Address"}
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}/>}
                  />
                </div>

              </div>
              <div className="row mb-2">
                <div className="col-md-3">
                  <Controller
                    className={"vertical-center"}
                    name="nbTextOfCreation"
                    control={control}
                    render={({ field }) => <TextField
                      {...field}
                      label={"N° texte de création EFP"}
                      variant="outlined"
                      size="small"
                      style={{ marginTop: "10px" }}/>}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    key={uuidv4()}
                    className={"vertical-center"}
                    name="trainingOperator"
                    control={control}
                    render={({ field }) => <FormControl style={{ width: "100%" }}
                                                        className={"vertical-center"}>

                      <TextField
                        label={"Opérateur de formation"}
                        disabled={user.roles.includes(Roles.EFP_VALIDATEUR) || user.roles.includes(Roles.EFP_SAISIE)}
                        key={uuidv4()}
                        select
                        {...field}
                      >
                        {
                          trainingOperators.map(e => {
                            return (<MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                          })
                        }
                      </TextField>
                    </FormControl>}
                  />
                  {errors.trainingOperator && <span className={"label-error"}></span>}
                </div>
                <div className="col-md-3">
                  <Controller
                    key={uuidv4()}
                    className={"vertical-center"}
                    name="region"
                    control={control}
                    rules={{required: true}}
                    render={({ field }) => <FormControl style={{ width: "100%" }} className={"vertical-center"}>
                      <TextField
                        select
                        label={"Région *"}
                        value={selectedRegion}
                        onChange={(e) => handleChangeRegion(e)}
                      >

                        {
                          regions.map(e => {
                            return (<MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                          })
                        }

                      </TextField>
                    </FormControl>}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    key={uuidv4()}
                    className={"vertical-center"}
                    name="city"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <FormControl style={{ width: "100%" }}
                                                        className={"vertical-center"}>

                      <TextField
                        label={"Ville/Commune *"}
                        key={uuidv4() + selectedCity}
                        select
                        defaultValue={selectedCity}
                        {...field}
                      >
                        {
                          cities.map(e => {
                            return (<MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                          })
                        }
                      </TextField>
                    </FormControl>}
                  />
                  {errors.city && <span className={"label-error"}>La Ville/Commune est obligatoire</span>}
                </div>
              </div>
              <div className="row ">
                <div className="col-md-3">

                  <Controller
                    key={uuidv4()}
                    className={"vertical-center"}
                    name="creationDate"
                    defaultValue={new Date()}
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={["year"]}
                        label="Année de création *"
                        {...field}
                        renderInput={(params) => <TextField {...params} helperText={null}/>}
                      />
                    </LocalizationProvider>}/>

                </div>
                <div className="col-md-3">
                  <Controller
                    key={uuidv4()}
                    className={"vertical-center"}
                    name="trainingComplex"
                    rules={{ required: true }}
                    control={control}
                    disabled={user.roles.includes(Roles.EFP_VALIDATEUR) || user.roles.includes(Roles.EFP_SAISIE)}
                    render={({ field }) => <FormControl style={{ width: "100%" }}
                                                        className={"vertical-center"}>

                      <TextField
                        label={"Complexe de formation"}
                        disabled={user.roles.includes(Roles.EFP_VALIDATEUR) || user.roles.includes(Roles.EFP_SAISIE)}
                        key={uuidv4()}
                        select
                        {...field}
                      >
                        {
                          trainingComplexes.map(e => {
                            return (<MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                          })
                        }
                      </TextField>
                    </FormControl>}
                  />
                </div>
              </div>

            </form>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default InstituteIdentity;
