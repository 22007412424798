import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import React, { useState } from "react";
import { omit, merge, includes } from "lodash";
import { Add } from "@material-ui/icons";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { formatDate } from "../../../utils/date.utils";
import frLocale from "date-fns/locale/fr";


import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

export const ReferentialManagementTable = ({ data, addNewParamter, updateParamter }) => {
  const [newParameter, setNewParameter] = useState(null);
  const [toUpdateParameter, setToUpdateParameter] = useState(null);

  const setNewParameterValue = (value) => {
    setNewParameter(merge({}, newParameter, value));
  };

  const setToUpdateParameterValue = (value) => {
    setToUpdateParameter(merge({}, toUpdateParameter, value));
  };

  const disableExistingYears = d => includes(data.map(item => item.year), d?.getFullYear());

  return (
    <>
      <div style={{ float: "right" }}>
        <Button variant="containedPrimary" endIcon={<Add/>}
                onClick={() => setNewParameter({ year: "", validFrom: null, validTo: null })}>
          Ajouter
        </Button>
      </div>

      <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>

        <Table sx={{ minWidth: 650 }} size="small"
               aria-label="a dense table" className="mt-2">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "30%" }}>Année</TableCell>
              <TableCell style={{ width: "30%" }}>Date début de saisie</TableCell>
              <TableCell style={{ width: "30%" }}>Date fin de saisie</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newParameter && (<TableRow
              key="newCriteria"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">

                <DatePicker
                  format="yyyy"
                  views={["year"]}
                  label="Année de création"
                  shouldDisableDate={disableExistingYears}
                  value={newParameter.year}
                  onChange={(year) => setNewParameterValue({ year })}
                  renderInput={(params) => <TextField {...params} helperText={null}/>}
                />

              </TableCell>
              <TableCell align="right">
                <DatePicker
                  label="Date début de saisie"
                  value={newParameter.validFrom}
                  format="dd/MM/yyyy"
                  onChange={(validFrom) => setNewParameterValue({ validFrom })}
                  renderInput={(params) => <TextField {...params} helperText={null}/>}
                />
              </TableCell>

              <TableCell align="right">
                <DatePicker
                  label="Date fin de saisie"
                  value={newParameter.validTo}
                  format="dd/MM/yyyy"
                  onChange={(validTo) => setNewParameterValue({ validTo })}
                  renderInput={(params) => <TextField {...params} helperText={null}/>}
                />
              </TableCell>

              <TableCell align="right">
                <button
                  onClick={e => addNewParamter(newParameter, setNewParameter)}
                  className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                  title="Valider l'ajout"
                >
                  <i className="flaticon2-check-mark"></i>
                </button>
                <button
                  onClick={e => setNewParameter(null)}
                  className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                  title="Annuler l'ajout"
                >
                  <i className="flaticon2-cancel"></i>
                </button>
              </TableCell>

            </TableRow>)}


            {data.map((row) => (
              <TableRow
                key={row.year}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.year}
                </TableCell>
                <TableCell component="th" scope="row">
                  {toUpdateParameter?.year === row.year ? (<DatePicker
                    label="Date début de saisie"
                    inputFormat="dd/MM/yyyy"
                    format="dd/MM/yyyy"
                    value={toUpdateParameter.validFrom}
                    onChange={(validFrom) => setToUpdateParameterValue({ validFrom })}
                    renderInput={(params) => <TextField {...params} helperText={null}/>}
                  />) : formatDate(row.validFrom)}
                </TableCell>
                <TableCell>
                  {toUpdateParameter?.year === row.year ? (<DatePicker
                    label="Date fin de saisie"
                    inputFormat="dd/MM/yyyy"
                    format="dd/MM/yyyy"
                    value={toUpdateParameter.validTo}
                    onChange={(validTo) => setToUpdateParameterValue({ validTo })}
                    renderInput={(params) => <TextField {...params} helperText={null}/>}
                  />) : formatDate(row.validTo)}
                </TableCell>
                <TableCell>
                  {toUpdateParameter?.year === row.year ? (
                    <>
                      <button
                        onClick={e => updateParamter(toUpdateParameter, setToUpdateParameter)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Valider l'ajout">
                        <i className="flaticon2-check-mark"></i>
                      </button>
                      <button
                        onClick={e => setToUpdateParameter(null)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Annuler l'ajout">
                        <i className="flaticon2-cancel"></i>
                      </button>
                    </>
                  ) : <button
                    onClick={e => setToUpdateParameter(row)}
                    className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                    title="Modifier le critere"
                  >
                    <i className="flaticon2-edit"></i>
                  </button>}


                </TableCell>
              </TableRow>
            ))}

          </TableBody>

        </Table>
      </MuiPickersUtilsProvider>

    </>);
};
