import {
    Button,
    FormControl,
    makeStyles,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import Input from "@material-ui/core/Input/Input";
import TextField from "@material-ui/core/TextField/TextField";
import clsx from "clsx";
import React, {useState} from "react";
import {omit} from "lodash";
import {Add} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
}));

export const ProcessManagementTable = ({standard, moveCriteria, validateAddCriteria, validateUpdateCriteria, removeCriteria, allStandard}) => {
    const classes = useStyles();

    const [toUpdateCriterias, setToUpdateCriterias] = useState({});

    const [newCriteria, setNewCriteria] = useState(null);

    const updateCriteria = (row) => {
        setToUpdateCriterias({
            ...toUpdateCriterias,
            [row.id]: row
        });
    };

    const cancelUpdate = (row) => {
        setToUpdateCriterias({
            ...omit(toUpdateCriterias, row.id)
        });
    };

    const handleCriteriaValueChange = (criteriaId, target, value) => {
        setToUpdateCriterias(data => {
            data[criteriaId][target] = value;
            return {...data};
        });
    };

    const getRowActionsCell = (row) => {
        if (!toUpdateCriterias[row.id]) { // case of not update mode
            return (
                <>
                    <button
                        onClick={e => updateCriteria(row)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Modifier le critere"
                    >
                        <i className="flaticon2-edit"></i>
                    </button>

                    <button
                        onClick={e => removeCriteria(standard.id, row.id)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Supprimer le critere"
                    >
                        <i className="flaticon-delete"></i>
                    </button>
                </>
            )
        } else {
            return (
                <>
                    <button
                        onClick={e => validateUpdateCriteria(toUpdateCriterias[row.id], cancelUpdate)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Valider la modification"
                    >
                        <i className="flaticon2-check-mark"></i>
                    </button>
                    <button
                        onClick={e => cancelUpdate(row)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Annuler la modification"
                    >
                        <i className="flaticon2-cancel"></i>
                    </button>
                </>
            )
        }
    };

    const getRowOrderActionsCell = (row, criteriaIndex) => {
        if (!toUpdateCriterias[row.id]) { // case of not update mode
            return (
                <>
                    {criteriaIndex > 0 && <button
                        onClick={e => moveCriteria(standard, criteriaIndex, criteriaIndex - 1)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Deplacer en haut"
                    >
                        <i className="flaticon2-arrow-up"/>
                    </button>}
                    {criteriaIndex !== standard.criterias.length - 1 && <button
                        onClick={e => moveCriteria(standard, criteriaIndex, criteriaIndex + 1)}
                        className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                        title="Deplacer en bas"
                    >
                        <i className="flaticon2-arrow-down"/>
                    </button>}
                </>
            )
        }
    };


    return (
        <>
            <div style={{float: 'right'}}>
                <Button variant="containedPrimary" endIcon={<Add/>}
                        onClick={() => setNewCriteria({standardId: standard.id, label: '', userGuide: ''})}>
                    Ajouter
                </Button>
            </div>
            <Table sx={{minWidth: 650}} size="small"
                   aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="right" style={{width: '5%'}}></TableCell>
                        <TableCell style={{width: '40%'}}>Critere</TableCell>
                        <TableCell style={{width: '40%'}}>Details info bulle</TableCell>
                        <TableCell style={{width: '15%'}}>Standard</TableCell>
                        <TableCell align="right" style={{width: '10%'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {newCriteria && (<TableRow
                        key="newCriteria"
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell component="th" scope="row"/>
                        <TableCell component="th" scope="row">
                            <Input
                                label="Critere"
                                type="text"
                                autoFocus={true}
                                value={newCriteria.label}
                                onChange={event => setNewCriteria({...newCriteria, label: event.target.value})}
                                className="form-control kt-quick-search__input"
                            />
                        </TableCell>
                        <TableCell align="right">
                            <TextField
                                value={newCriteria.userGuide}
                                label="Guide du critere"
                                className={clsx(classes.textField, classes.dense)}
                                onChange={event => setNewCriteria({...newCriteria, userGuide: event.target.value})}
                                margin="dense"
                                variant="outlined"
                                multiline
                                rowsMax="10"
                            />
                        </TableCell>

                        <TableCell align="right">
                            <button
                                onClick={e => validateAddCriteria(newCriteria, setNewCriteria)}
                                className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                                title="Valider l'ajout"
                            >
                                <i className="flaticon2-check-mark"></i>
                            </button>
                            <button
                                onClick={e => setNewCriteria(null)}
                                className="btn btn-clean btn-sm btn-icon btn-icon-lg"
                                title="Annuler l'ajout"
                            >
                                <i className="flaticon2-cancel"></i>
                            </button>
                        </TableCell>

                    </TableRow>)}


                    {standard.criterias.map((row, criteriaIndex) => (
                        <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {getRowOrderActionsCell(row, criteriaIndex)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Input
                                    label="Critere"
                                    type="text"
                                    autoFocus={true}
                                    value={toUpdateCriterias[row.id] ? toUpdateCriterias[row.id].label : row.label}
                                    onChange={event => handleCriteriaValueChange(row.id, 'label', event.target.value)}
                                    className="form-control kt-quick-search__input"
                                    disabled={!toUpdateCriterias[row.id]}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <TextField
                                    value={toUpdateCriterias[row.id] ? toUpdateCriterias[row.id].userGuide : row.userGuide}
                                    label="Guide du critere"
                                    className={clsx(classes.textField, classes.dense)}
                                    onChange={event => handleCriteriaValueChange(row.id, 'userGuide', event.target.value)}
                                    margin="dense"
                                    variant="outlined"
                                    multiline
                                    rowsMax="10"
                                    disabled={!toUpdateCriterias[row.id]}
                                />
                            </TableCell>

                            <TableCell align="right">
                                <FormControl style={{width: '100%'}}
                                             className={'vertical-center'}>
                                    <TextField
                                        label={"Standard"}
                                        select
                                        defaultValue={standard.id}
                                        disabled={!toUpdateCriterias[row.id]}
                                        value={toUpdateCriterias[row.id] ? toUpdateCriterias[row.id].standardId : row.standardId}
                                        onChange={event => handleCriteriaValueChange(row.id, 'standardId', event.target.value)}
                                    >
                                        {
                                            allStandard.map(e => {
                                                return (<MenuItem value={e.id}>{e.name}</MenuItem>);
                                            })
                                        }
                                    </TextField>
                                </FormControl>
                            </TableCell>

                            <TableCell align="right">
                                {getRowActionsCell(row)}
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>

            </Table>

        </>)
}
