import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { ExpandMore, Refresh } from "@material-ui/icons";
import { merge } from "lodash";
import { getRoleLabel } from "../../../utils/roles.utils";

const AccountManagerFilter = ({ applyFilters, roles, filter, setFilter, resetFilter }) => {

  const setFilterValue = (value) => {
    setFilter(merge({}, filter, value));
  };

  return (
    <Accordion className={"mb-2"}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Les paramètres de recherche</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={"row"}>
          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <TextField label="Prenom" variant="outlined" size="small" style={{ marginTop: "10px" }}
                         value={filter.firstName}
                         onChange={(event) => setFilterValue({ firstName: event.target.value })}/>
            </FormControl>
          </div>
          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <TextField label="Nom" variant="outlined" size="small" style={{ marginTop: "10px" }}
                         value={filter.lastName}
                         onChange={(event) => setFilterValue({ lastName: event.target.value })}/>
            </FormControl>
          </div>
          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <TextField label="Email" variant="outlined" size="small" style={{ marginTop: "10px" }}
                         value={filter.email}
                         onChange={(event) => setFilterValue({ email: event.target.value })}/>
            </FormControl>
          </div>

          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={filter.role}
                label="efp"
                onChange={(event) => setFilterValue({ role: event.target.value })}
              >
                {
                  roles.map(role => {
                    return (<MenuItem value={role}>{getRoleLabel(role)}</MenuItem>);
                  })
                }
              </Select>
            </FormControl>
          </div>

          <div className="col-md-4">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <TextField label="Établissement" variant="outlined" size="small" style={{ marginTop: "10px" }}
                         value={filter.establishment}
                         onChange={(event) => setFilterValue({ establishment: event.target.value })}/>
            </FormControl>
          </div>

        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div style={{ float: "right" }}>
              <Button variant="outlinedSecondary" startIcon={<Refresh/>}
                      style={{ marginRight: "5px" }}
                      onClick={() => resetFilter()}>
                Réinitialiser
              </Button>
              {/*<Button variant="containedPrimary" endIcon={<Search/>} onClick={() => applyFilters(filter)}>*/}
                {/*Rechercher*/}
              {/*</Button>*/}
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccountManagerFilter;
