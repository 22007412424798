import React from "react";
import { Add, Delete, Edit, Lock, Refresh } from "@material-ui/icons";
import { Button } from "@material-ui/core";

export const LockButton = (props) => (
  <span {...props}>
    <Lock
      style={{ color: "#2739C1" }}
      className="btn-pointer"/>
  </span>
);


export const EditButton = (props) => (
  <span {...props}>
    <Edit
      style={{ color: "#2739C1" }}
      className="btn-pointer"/>
  </span>
);


export const DeleteButton = (props) => (
  <span>
    <Delete {...props} color={"error"} className="btn-pointer"/>
  </span>
);


export const AddButton = (props) => {
  return (<Button variant="contained" color="primary" endIcon={<Add/>} {...props}>
    { props.title || 'Ajouter'}
  </Button>);
};

export const ResetButton = (props) => {
  return (<Button variant="outlined" color="secondary" startIcon={<Refresh/>} style={{ marginRight: "5px" }} {...props}>
    Réinitialiser
  </Button>);
};
