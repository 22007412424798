import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import * as crud from "../../../crud/referential-management.crud";

import { ReferentialManagementTable } from "./ReferentialManagementTable";
import PageLoading from "../../../../shared/component/PageLoading";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { includes } from "lodash";
import { ArrowBack } from "@material-ui/icons";
import { Button } from "@material-ui/core";


const ReferentialManagementPage = () => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  useEffect(() => {
    loadReferentialParameters();
  }, []);

  const [referentialParameters, setReferentialParameters] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadReferentialParameters = () => crud.getReferentialParameters()
    .catch(err => {
      notifyError(`Une erreure technique est survenue lors du chargement des parametres !`);
      throw err;
    })
    .then(({ data }) => {
      setLoading(false);
      setReferentialParameters(data);
    });

  const addNewParameter = (data, setterCallback) => {
    const year = new Date(data.year).getFullYear();
    if( includes(referentialParameters.map(item => item.year), year) ){
      return notifyError(`L'annee ${year} existe deja !`);
    }
    crud.addReferentialParameter({ ...data, year  })
      .catch(err => {
        notifyError(`Une erreure technique est survenue lors de l'ajoute de l'annee ${year} !`);
        throw err;
      })
      .then(() => {
        setterCallback(null);
        notifySuccess(`L'annee ${year} est bien ajoutee !`);
        loadReferentialParameters();
      });
  };

  const updateParameter = (data, setterCallback) => {
    console.log('====> new 1', new Date(data.validTo))
    console.log('====> new 2', new Date(data.validFrom))
    if(new Date(data.validTo) > new Date(data.validFrom)){
      crud.updateReferentialParameter(data)
        .catch(err => {
          notifyError(`Une erreure technique est survenue lors de la mise a jour de l'annee ${data.year} !`);
          throw err;
        })
        .then(() => {
          setterCallback(null);
          notifySuccess(`L'annee ${data.year} est bien mise a jour !`);
          loadReferentialParameters();
        });
    } else {
      notifyError(`La date de fin doit être supérieure à la date de début`);
    }

  };

  if (loading)
    return <PageLoading/>;
  return (
    <>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <div className="row mb-4">
            <div className="col-md-4">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span>Paramétrage de la session d’autoévaluation</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <div className={"filter-btn-div"}>
                <Button variant="outlinedPrimary" startIcon={<ArrowBack/>}
                        onClick={() => window.history.back()}
                        style={{ marginRight: "5px" }}>
                  Retour
                </Button>
              </div>
            </div>
          </div>
          <ReferentialManagementTable data={referentialParameters} addNewParamter={addNewParameter} updateParamter={updateParameter}/>
        </div>
      </div>
    </>
  );
};


export default ReferentialManagementPage;
