import React from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";

import frMessages from "./messages/fr";

const allMessages = {
  fr: frMessages,
};

export default function I18nProvider({ children }) {
  const locale = "fr";
  const messages = allMessages[locale];

  return (
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
  );
}
