import React, { useState } from "react";
import { AddButton } from "../../../../shared/component/ActionsButton";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import TextField from "@mui/material/TextField/TextField";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";


const AddNewProcess = ({ addNewProcess }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [value, setValue] = useState("");

  const closeModal = () => setModalVisibility(false);

  return (
    <>
      <AddButton title="Ajouter un processus" style={{ maxWidth: "200px" }} onClick={() => setModalVisibility(true)}/>
      <Dialog open={modalVisibility} onClose={() => setValue("")}>
        <DialogTitle>Ajouter un processus</DialogTitle>
        <DialogContent>
          <TextField
            value={value}
            label={"Nom du processus"}
            onChange={event => setValue(event.target.value)}
            margin="dense"
            variant="outlined"
            multiline
            rowsMax="10"/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalVisibility(false)}>Cancel</Button>
          <Button disabled={value?.length < 3} onClick={() => addNewProcess(value, closeModal)}>Ajouter</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


export default AddNewProcess;
