import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Button,
  Typography
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { ExpandMore, InsertDriveFile, Save, Publish } from "@material-ui/icons";
import ReferentialQuizHeader from "./ReferentialQuizHeader";
import {
  completeSubmit,
  loadAllQuizData,
  saveAnswers,
  setUpQuiz
} from "../../../crud/referential-quiz.crud";
import { Tabs, Tab } from "@material-ui/core";
import NewYearSetupModal from "./NewYearSetupModal";
import ReferentialQuizTable from "./ReferentialQuizTable";
import { groupBy, mapValues, values, first, pick, map, toNumber, sum, size, includes, findIndex } from "lodash";
import { downloadReferentialReport } from "../../../crud/reporting.crud";
import PageLoading from "../../../../shared/component/PageLoading";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import { actionConfirm } from "../../../../shared/utils/confirm.util";
import {
  canSaveAndSubmitAnswers,
  canSaveTrainingInstituteAnswers,
  canSubmitTrainingInstituteAnswers, isReadOnlyTrainingInstituteAnswers
} from "../../../utils/roles.utils";
import { getAllConfiguredYears, getValidYears } from "../../../crud/referential-management.crud";
import Chip from "@material-ui/core/Chip/Chip";
import { Prompt } from "react-router-dom";
import * as _ from "lodash";
import { UnauthorizedErrorPage } from "../../errors/UnauthorizedErrorPage";

const ReferentialQuizPage = ({ user }) => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());
  const confirm = actionConfirm(useConfirm());

  const { id: trainingInstituteId } = useParams();

  const [tabIndex, setTabIndex] = useState(0);

  const [setupYearModal, setSetupYearModal] = useState(false);
  const [answersChanged, setAnswersChanged] = useState(false);

  const [loading, setLoading] = useState(true);
  const [accessForbidden, setAccessForbidden] = useState(false);
  const [years, setYears] = useState([]);
  const [validYears, setValidYears] = useState([]);
  const [allYears, setAllYears] = useState([]);
  const [process, setProcess] = useState([]);
  const [answersByYear, setAnswersByYear] = useState({});
  const [metadata, setMetadata] = useState({});
  const [stats, setStats] = useState({ progress: 0, totalPoints: 0, filledPoints: 0 });

  const loadData = () => {
    setLoading(true);
    loadAllQuizData(trainingInstituteId)
      .catch(err => {
        const {response: {status} } = err;
        if(status === 403){
          setAccessForbidden(true);
        } else {
          notifyError("Une erreure technique lors du chargement du référentiel !")
        }
        throw err;
      })
      .then(({ metadata, years, process, answersByYear }) => {
        setYears(years);
        setProcess(process);
        setAnswersByYear(answersByYear);
        setMetadata(metadata);
        setLoading(false);

        const currentYearIndex = findIndex(years, y => y == new Date().getFullYear());
        if (currentYearIndex > 0) {
          setTabIndex(currentYearIndex);
        }

      });
    getValidYears().then(({ data }) => setValidYears(data));
    getAllConfiguredYears().then(({ data }) => setAllYears(data));
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    calculateStats();
  }, [answersByYear, tabIndex]);

  const setupNewYear = (year) => {
    setUpQuiz(trainingInstituteId, year)
      .catch(err => {
        notifyError("Une erreure technique lors de l'ajout du referentiel");
        throw err;
      })
      .then(() => {
        notifySuccess(`L'annee ${year} est bien configuree !`);
        loadData();
        setSetupYearModal(false);
      });
  };

  const answerChange = (target, newValue, criteriaId) => {
    setAnswersChanged(true);
    setAnswersByYear(data => {
      data[years[tabIndex]][criteriaId][target] = newValue;
      return { ...data };
    });
  };

  const calculateStats = () => {
    setStats(data => {
      const answers = values(answersByYear[years[tabIndex]]);
      data.totalPoints = size(answers) * 4;
      data.filledPoints = sum(map(map(answers, "maturityLevelValue"), toNumber));
      data.progress = (data.filledPoints * 100) / data.totalPoints;
      return { ...data };
    });
  };

  const getAnswerIdToMaturtityLevel = () => {
    const currentYearAnswers = values(answersByYear[years[tabIndex]]);
    return mapValues(groupBy(currentYearAnswers, "id"), val => pick(first(val), ["maturityLevel", "comment"]));
  };

  const saveResult = () => {
    saveAnswers(getAnswerIdToMaturtityLevel())
      .catch(err => {
        notifyError(`Erreur technique lors de la sauvegade de l'annee ${years[tabIndex]} !`);
        setAnswersChanged(false);
        throw err;
      })
      .then(() => {
        notifySuccess(`Les resultat sont bien sauvegardees pour l'annee ${years[tabIndex]} !`);
        setAnswersChanged(false);
        loadData();
      });
  };

  const submitResult = () => {
    completeSubmit(trainingInstituteId, years[tabIndex], getAnswerIdToMaturtityLevel())
      .catch(err => {
        notifyError(`Erreur technique lors de la sauvegade de l'annee ${years[tabIndex]} !`);
        throw err;
      })
      .then(() => {
        setMetadata(data => {
          data.quizMetaDataByYear[years[tabIndex]].draftFlag = false;
          return { ...data };
        });
        notifySuccess(`Les resultat sont bien soumises pour l'annee ${years[tabIndex]} !`);
      });
  };

  const getDraftFlag = () => {
    return metadata.quizMetaDataByYear[years[tabIndex]] ? metadata.quizMetaDataByYear[years[tabIndex]].draftFlag : null;
  };

  const exportReport = () => {
    const year = years[tabIndex];
    downloadReferentialReport(year, trainingInstituteId, metadata.trainingInstitute);
  };


  const getAllYearsExcludingExisting = () => {
    return _.filter(allYears, y => !_.includes(years, y));
  };


  if(accessForbidden)
    return <UnauthorizedErrorPage />

  if (loading)
    return <PageLoading/>;

  return (
    <>
      <NewYearSetupModal isOpen={setupYearModal} setupNewYear={setupNewYear}
                         closeModal={() => setSetupYearModal(false)} years={getAllYearsExcludingExisting()}/>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <ReferentialQuizHeader trainingInstitute={metadata.trainingInstitute}
                                 status={getDraftFlag() ? "BROUILLON" : "SOUMIS"}
                                 user={user}
                                 stats={stats}
                                 addYear={() => setSetupYearModal(true)}/>
          <Tabs
            value={tabIndex}
            onChange={(evt, value) => setTabIndex(value)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            {years.map(year => <Tab label={year}/>)}
          </Tabs>

          <div className="w-100 p-2">
            <div className="float-left d-flex">


            </div>
            <div className="float-right d-flex">

              {(canSubmitTrainingInstituteAnswers(user) && includes(validYears, Number(years[tabIndex]))) &&
              <Button className="float-right d-none" variant="containedPrimary" startIcon={<Publish/>}
                      onClick={() => confirm("Etes vous sure de vouloir soumettre le Questionnaire ? ").then(() => submitResult())}
                      style={{ marginRight: "5px" }}>
                Soumettre
              </Button>}
              {(canSaveTrainingInstituteAnswers(user) && includes(validYears, Number(years[tabIndex]))) &&
              <Button className="float-right" variant="outlinedPrimary" startIcon={<Save/>}
                      onClick={saveResult}
                      style={{ marginRight: "5px" }}>
                Sauvegarder
              </Button>}
              <Button variant="outlinedSecondary" startIcon={<InsertDriveFile/>}
                      onClick={exportReport}
                      style={{ marginRight: "5px" }}>
                Exporter
              </Button>
            </div>

          </div>
          {
            process.map((proc, procIndex) => {
              return (
                <div key={proc.id}>
                  <Accordion className={"mb-2"} TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      expandIcon={<ExpandMore/>}
                      aria-controls={"panel-content" + proc.id}
                      id={"panel-content" + proc.id}
                    >
                      <h6>
                        <Chip label={`Processus ${procIndex + 1}`} size="small" variant="outlined" className="mr-2"/>
                        {proc.label}
                      </h6>
                    </AccordionSummary>
                    <AccordionDetails>
                      {proc.standards.map((standard) => {
                        return (
                          <div key={standard.id} id="panel1a-header-standard">
                            <Accordion className={"mb-2"} TransitionProps={{ unmountOnExit: true }}>
                              <AccordionSummary
                                sx={{
                                  backgroundColor: "#000"
                                }}
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  <Chip label={standard.description} size="small" variant="outlined"
                                        className="mr-2"/>
                                  {standard.label}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <ReferentialQuizTable standard={standard} answerChange={answerChange}
                                                      readOnly={isReadOnlyTrainingInstituteAnswers(user) || !includes(validYears, Number(years[tabIndex]))}
                                                      answers={answersByYear[years[tabIndex]]}/>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })
          }

        </div>
      </div>
      <Prompt when={answersChanged}
              message={"Vous avez des modifications non enregistrées, êtes-vous sûr de vouloir quitter ?"}/>
    </>
  );
};

export default ReferentialQuizPage;
