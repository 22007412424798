import axios from "axios";

export const REGIONS_URL = "regions";
export const CITY_URL = "regions/city";


export function saveRegion(name) {
  return axios.post(REGIONS_URL, { value: name });
}

export function updateRegion(id, name) {
  return axios.put(REGIONS_URL, { id, value: name });
}

export function deleteRegion(id) {
  return axios.delete(REGIONS_URL, { params: { id } });
}

export function saveCity(name, regionId) {
  return axios.post(CITY_URL, { value: name }, { params: { regionId } });
}

export function updateCity(id, name) {
  return axios.put(CITY_URL, { id, value: name });
}


export function deleteCity(id) {
  return axios.delete(CITY_URL, { params: { id } });
}



