export function useNotification(snackbar) {
  const {enqueueSnackbar} = snackbar;

  const anchorOrigin = { horizontal: 'right', vertical: 'top' };

  const notifySuccess = (message) => {
    enqueueSnackbar(message, {variant: 'success', anchorOrigin });
  };

  const notifyInfo = (message) => {
    enqueueSnackbar(message, {variant: 'info', anchorOrigin});
  };

  const notifyError = (message) => {
    enqueueSnackbar(message, {variant: 'error', anchorOrigin});
  };

  return {notifySuccess, notifyError, notifyInfo};
}
