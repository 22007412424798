import { TableBody, TableCell, TableRow } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton/Skeleton";
import React from "react";

const TableLoading = () => (<TableBody>
    {[0, 1, 2, 3, 4]
      .map((row) => {
        return (
          <TableRow  hover role="checkbox" tabIndex={-1} key={row}>

            <TableCell align="right">
              <Skeleton animation="wave" className={"avatarLoader"}/>
            </TableCell>
            <TableCell align="right">
              <Skeleton animation="wave" className={"avatarLoader"}/>
            </TableCell>
            <TableCell align="right">
              <Skeleton animation="wave" className={"avatarLoader"}/>
            </TableCell>
            <TableCell align="right">
              <Skeleton animation="wave" className={"avatarLoader"}/>
            </TableCell>
            <TableCell align="right">
              <Skeleton animation="wave" className={"avatarLoader"}/>
            </TableCell>
          </TableRow>
        );
      })
    }
  </TableBody>
);


export default TableLoading;
