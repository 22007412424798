import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import * as ChartUtils from "../utils/chartjs-utils";
import { getReferentialAnswersRecap } from "../crud/referential-quiz.crud";
import { isEmpty, isNaN, isNull } from "lodash";

export default function RadarStatisticsChart({ year, ti, to, tc, dr, trainingInstituteLength, role }) {
  const ref = useRef();

  const [data, setData] = useState({
    labels: ["Niveau de maturité 1", "Niveau de maturité 2", "Niveau de maturité 3", "Niveau de maturité 4"],
    datasets: []
  });
  const [recapData, setRecapData] = useState({ numberOfIndicators: 0, fulfilledCriteria: 0, lastEnterData: null });

  useEffect(() => {
    // @TODO to be changed
    if (year) {
      if (ti) {
        getReferentialAnswersRecap(year, ti, 0).then(response => {
          let recapObject = { numberOfIndicators: 0, fulfilledCriteria: 0, lastEnterData: null };
          let dataSets = [];
          const colors = ["#00796b", "#e254ff", "#4e342e", "#a30000", "#f67019", "#166a8f"];
          if (response.data) {
            response.data.processDtos.forEach((e, i) => {
              //recapObject.numberOfIndicators += e.criteriaMaturityDtoList.length;
              recapObject.fulfilledCriteria += e.criteriaMaturityDtoList.filter(e => e.maturity != null).length;
              recapObject.numberOfIndicators += e.criteriaMaturityDtoList.length;
              dataSets.push(
                {
                  label: e.name,
                  borderWidth: 1,
                  data: [e.criteriaMaturityDtoList.filter(e => e.maturity == 1).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 2).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 3).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 4).length],
                  borderColor: colors[i],
                  backgroundColor: ChartUtils && ChartUtils.transparentize(colors[i], 0.4)
                }
              );
            });
          }
          setData({ ...data, datasets: dataSets });
          setRecapData({
            ...recapData,
            fulfilledCriteria: recapObject.fulfilledCriteria,
            numberOfIndicators: recapObject.numberOfIndicators,
            lastEntryDate: response.data.lastEntryDate
          });
        });
      }
      else if (tc) {
        getReferentialAnswersRecap(year, tc, 1).then(response => {
          let recapObject = {
            numberOfIndicators: 142 * trainingInstituteLength,
            fulfilledCriteria: 0,
            lastEnterData: null
          };
          let dataSets = [];
          const colors = ["#00796b", "#e254ff", "#4e342e", "#a30000", "#f67019", "#76ff03"];
          if (response.data) {
            response.data.processDtos.forEach((e, i) => {
              //recapObject.numberOfIndicators += e.criteriaMaturityDtoList.length;
              recapObject.fulfilledCriteria += e.criteriaMaturityDtoList.filter(e => e.maturity != null).length;
              dataSets.push(
                {
                  label: e.name,
                  borderWidth: 1,
                  data: [e.criteriaMaturityDtoList.filter(e => e.maturity == 1).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 2).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 3).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 4).length],
                  borderColor: colors[i],
                  backgroundColor: ChartUtils.transparentize(colors[i], 0.4)
                }
              );
            });
          }
          setData({ ...data, datasets: dataSets });
          setRecapData({
            ...recapData,
            fulfilledCriteria: recapObject.fulfilledCriteria,
            numberOfIndicators: recapObject.numberOfIndicators,
            lastEntryDate: response.data.lastEntryDate
          });
        });
      }
      else if (dr) {
        getReferentialAnswersRecap(year, tc, 3).then(response => {
          let recapObject = {
            numberOfIndicators: 142 * trainingInstituteLength,
            fulfilledCriteria: 0,
            lastEnterData: null
          };
          let dataSets = [];
          const colors = ["#00796b", "#e254ff", "#4e342e", "#a30000", "#f67019", "#76ff03"];
          if (response.data) {
            response.data.processDtos.forEach((e, i) => {
              //recapObject.numberOfIndicators += e.criteriaMaturityDtoList.length;
              recapObject.fulfilledCriteria += e.criteriaMaturityDtoList.filter(e => e.maturity != null).length;
              dataSets.push(
                {
                  label: e.name,
                  borderWidth: 1,
                  data: [e.criteriaMaturityDtoList.filter(e => e.maturity == 1).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 2).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 3).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 4).length],
                  borderColor: colors[i],
                  backgroundColor: ChartUtils.transparentize(colors[i], 0.4)
                }
              );
            });
          }
          setData({ ...data, datasets: dataSets });
          setRecapData({
            ...recapData,
            fulfilledCriteria: recapObject.fulfilledCriteria,
            numberOfIndicators: recapObject.numberOfIndicators,
            lastEntryDate: response.data.lastEntryDate
          });
        });
      }
      else if (to) {
        getReferentialAnswersRecap(year, to, 2).then(response => {
          let recapObject = {
            numberOfIndicators: 142 * trainingInstituteLength,
            fulfilledCriteria: 0,
            lastEnterData: null
          };
          let dataSets = [];
          const colors = ["#00796b", "#e254ff", "#4e342e", "#a30000", "#f67019", "#76ff03"];
          if (response.data) {
            response.data.processDtos.forEach((e, i) => {
              //recapObject.numberOfIndicators += e.criteriaMaturityDtoList.length;
              recapObject.fulfilledCriteria += e.criteriaMaturityDtoList.filter(e => e.maturity != null).length;
              dataSets.push(
                {
                  label: e.name,
                  borderWidth: 1,
                  elements: {
                    line: {
                      borderWidth: 0.3
                    }
                  },
                  data: [e.criteriaMaturityDtoList.filter(e => e.maturity == 1).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 2).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 3).length,
                    e.criteriaMaturityDtoList.filter(e => e.maturity == 4).length],
                  borderColor: colors[i],
                  backgroundColor: ChartUtils.transparentize(colors[i], 0.4)
                }
              );
            });
          }
          setData({ ...data, datasets: dataSets });
          setRecapData({
            ...recapData,
            fulfilledCriteria: recapObject.fulfilledCriteria,
            numberOfIndicators: recapObject.numberOfIndicators,
            lastEntryDate: response.data.lastEntryDate
          });
        });
      }
    }
  }, [year, to, ti, tc, trainingInstituteLength]);

  useEffect(() => {
    const chart = new Chart(ref.current, {
      type: "radar",
      data: data,
      options: {
        responsive: true,
        onResize: (x,y) => {
          console.log('on resize', x,y);
        },
        plugins: {
          title: {
            display: true,
            text: "Chart.js Radar Chart"
          }
        }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data]);


  const getProgressPercent = () => {
    const percentage = ((recapData.fulfilledCriteria / recapData.numberOfIndicators) * 100).toFixed(2);
    return isNaN(percentage) || isNull(percentage) ? 0 : percentage + "%";
  };

  return (
    <div className="kt-widget12">
      <div className="kt-widget12__content">
        <div className="kt-widget12__item">
          {
            role == "ROLE_EFP_SAISIE" &&
            <div className="kt-widget12__info">
              <span className="kt-widget12__desc">Nombre total des critères</span>
              <span className="kt-widget12__value">{recapData.numberOfIndicators}</span>
            </div>
          }
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Date de la derniere saisie</span>
            <span className="kt-widget12__value">{recapData.lastEntryDate || "Non trouvée"}</span>
          </div>
        </div>

        <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Nombre de critères saisis</span>
            <span className="kt-widget12__value">{recapData.fulfilledCriteria}</span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Taux de saisie (toutes les sessions confondues)</span>
            <div className="kt-widget12__progress">
              <div className="progress kt-progress--sm">
                <div
                  role="progressbar"
                  aria-valuemin={0}
                  aria-valuenow={100}
                  aria-valuemax={100}
                  style={{ width: getProgressPercent() }}
                  className="progress-bar bg-success"
                />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                                <span
                                  className="kt-widget12__stat">{getProgressPercent()} </span>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="kt-widget12__chart" style={{ minHeight: "350px" }}>
        <canvas
          ref={ref}
          id="kt_chart_order_statistics"
        />
      </div>
    </div>
  );
}
