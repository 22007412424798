import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { ExpandMore } from "@material-ui/icons";
import EditableInput from "../../../../shared/component/EditableInput";
import AddNewBranch from "./AddNewBranch";

export const SectorTable = ({ rows: regions, setRows, updateItem, deleteItem, addCity }) => {
  const [toUpdateRegion, setToUpdateRegion] = useState(null);
  const [toUpdateCity, setToUpdateCity] = useState(null);

  const handleExpand = row => {
    setRows(regions.map(item => {
      if (item.id === row.id) {
        item.expanded = !item.expanded;
      }
      return item;
    }));
  };

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label="Table des regions">
      <TableHead>
        <TableRow>
          <TableCell align="left">Secteur</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(regions || []).map((region) => (
          <>
            <TableRow
              key={region.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align={"left"}>
                <EditableInput
                  value={region}
                  toUpdate={toUpdateRegion}
                  setToUpdate={setToUpdateRegion}
                  field="name"
                  isUpdating={region.id === toUpdateRegion?.id}
                  validateEdit={() => updateItem("region", toUpdateRegion, () => setToUpdateRegion(null))}
                  onDelete={() => deleteItem("region", region.id)}
                  title={region.name}
                />
              </TableCell>
              <TableCell align="right">
                <ExpandMore onClick={() => handleExpand(region)}/>
              </TableCell>
            </TableRow>
            {region.expanded ? (
              <>
                <AddNewBranch region={region} addNewCity={addCity}/>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Filière</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(region.branches || []).map(city => (
                      <TableRow key={city.name}>
                        <TableCell component="th" scope="row" align={"center"}>
                          <EditableInput
                            value={city}
                            toUpdate={toUpdateCity}
                            setToUpdate={setToUpdateCity}
                            field="name"
                            isUpdating={city.id === toUpdateCity?.id}
                            validateEdit={() => updateItem("city", toUpdateCity, () => setToUpdateCity(null))}
                            onDelete={() => deleteItem("city", city.id)}
                            title={city.name}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : null}
          </>

        ))}
      </TableBody>
    </Table>
  );
};
