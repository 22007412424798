import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography } from "@material-ui/core";
import { Cancel, Save } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import {
  getEnhancementAxisData,
  getGlobalMaturityData,
  getTrainingOperatorReportData,
  getDfpReportData, saveImprovementAxesUserInput,
  pushDataAndDownloadPdf, saveTrainingOperatorReportUserInput, saveDfpReportUserInput
} from "../../../crud/reporting.crud";
import PageLoading from "../../../../shared/component/PageLoading";
import { forEach, isEmpty } from "lodash";
import {
  generateSaveReportData,
  ReportType,
  ReportTypeToExcelExportParams,
  ReportTypeToFilename,
  ReportTypeToTitle
} from "../../../utils/report-type.utils";
import { isFunction, cloneDeep } from "lodash";
import { Roles } from "../../../utils/roles.utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "95%",
  // maxHeight: "70%",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};


const ExportModal = ({ isOpen, closeModal, year, operator,type, user }) => {
  const { notifyError, notifyInfo, notifySuccess } = useNotification(useSnackbar());
console.log(user);
console.log(operator);
console.log(year)

  const [editRowsModel, setEditRowsModel] = useState({});
  const [loading, setLoading] = useState(true);
  const [header, setHeader] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [threshold, setThreshold] = useState(null);

  const resetModal = () => {
    setLoading(true);
    setHeader([]);
    setColumns([]);
    setRows([]);
    setThreshold(null);
  };


  const generateColumns = (header, threshold) => {
    return header.map((item, index) => ({
      field: index,
      headerName: item,
      width: 180,
      editable: index >= threshold
    }))
      .map(item => item.editable ? item : ({
        ...item,
        cellClassName: "disabled-cell",
        headerClassName: "disabled-cell"
      }));
  };

  const reportFetchCallbackByType = {
    [ReportType.IMPROVEMENT_AXIS_REPORT]: (year) => getEnhancementAxisData(year,operator,user),
    [ReportType.DFP_REPORT]: (year) => getDfpReportData(year),
    [ReportType.TRAINING_OPERATOR_REPORT]: (year) => getTrainingOperatorReportData(year),
    [ReportType.GLOBAL_MATURITY_REPORT]: (year) => getGlobalMaturityData(year)
  };

  const reportSaveCallbackByType = {
    [ReportType.IMPROVEMENT_AXIS_REPORT]: (data) => saveImprovementAxesUserInput(data),
    [ReportType.TRAINING_OPERATOR_REPORT]: (data) => saveTrainingOperatorReportUserInput(data),
    [ReportType.DFP_REPORT]: (data) => saveDfpReportUserInput(data)
  };


  useEffect(() => {
    resetModal();
    const fetchCallback = reportFetchCallbackByType[type];
    if (!fetchCallback || !isFunction(fetchCallback)) {
      console.error("cannot find fetch callback for type", type);
      return;
    }
    fetchCallback(year)
      .catch(err => {
        notifyError("Une erreure technique lors du chargement du rapport");
        throw err;
      })
      .then(({ data }) => {
        if (user.role === Roles.OF && type === ReportType.IMPROVEMENT_AXIS_REPORT) {
          setColumns(generateColumns(data.header, 13));
        } else {
          setColumns(generateColumns(data.header, data.editColIndexThreshold));
        }
        setHeader(data.header);
        setRows(data.data);
        setLoading(false);
        setEditRowsModel({});
        setThreshold(data.editColIndexThreshold);
      });
  }, [type]);

  const runExport = () => {
    if (isEmpty(editRowsModel)) {
      notifyInfo("Il semble que vous avez pas fait de modifications sur le rapport !");
    }
    const exportData = getMergedDataWithUserInputs();

    if (type === ReportType.GLOBAL_MATURITY_REPORT) {
      pushDataAndDownloadPdf({
        header,
        data: getExportData(exportData, header.length), ...ReportTypeToExcelExportParams[type]
      }, { year }, ReportTypeToFilename[type].concat(".xlsx"));
      closeModal();
    } else {
      saveData(exportData, true);
    }
  };


  const saveData = (exportData = getMergedDataWithUserInputs(), withExport = false) => {
    const saveCallback = reportSaveCallbackByType[type];
    if (!saveCallback || !isFunction(saveCallback)) {
      console.error("cannot find save callback for type", type);
      return;
    }
    saveCallback(generateSaveReportData(cloneDeep(exportData), type, threshold, year))
      .catch(err => {
        notifyError("Une erreure technique lors de la sauvegarde des donnees");
        throw err;
      })
      .then(() => {
        if (withExport) {
          pushDataAndDownloadPdf({
            header,
            data: getExportData(exportData, header.length), ...ReportTypeToExcelExportParams[type]
          }, { year }, ReportTypeToFilename[type].concat(".xlsx"));
          closeModal();
        } else {
          notifySuccess("Données sauvegardées avec succès !");
        }
      });
  };


  const getMergedDataWithUserInputs = () => {
    const data = [...rows];
    forEach(editRowsModel, (cellChanges, rowId) => {
      forEach(cellChanges, (change, cellId) => {
        data[rowId][cellId] = change.value;
      });
    });
    return data;
  };

  const getExportData = (exportData, headerLength) => {
    for (let chunk of exportData) {
      chunk.splice(headerLength, chunk.length);
    }
    return exportData;
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loading ?
          (<PageLoading/>) :
          (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2" className="mb-2">
                Rapport {ReportTypeToTitle[type]}
              </Typography>
              < div>
                < div style={{ height: "73vh" }}>
                  <DataGrid
                    getRowId={row => rows.indexOf(row)}
                    rows={rows}
                    columns={columns}
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={setEditRowsModel}
                  />
                </div>

              </div>
              <div className="row mt-2 mb-4">
                <div className="col-md-12">
                  <div style={{ textAlign: "center" }}>
                    <Button variant="outlinedSecondary" startIcon={<Cancel/>}
                            onClick={closeModal}
                            style={{ marginRight: "5px" }}>
                      Annuler
                    </Button>
                    <Button variant="outlinedPrimary" endIcon={<Save/>} onClick={() => saveData()}>
                      Sauvegarder
                    </Button>
                    <Button variant="containedPrimary" endIcon={<Save/>} onClick={() => runExport()}>
                      Exporter
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
      </Box>
    </Modal>
  );
};

export default ExportModal;
