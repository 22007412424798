import React, {memo, useState} from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextareaAutosize
} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import {Comment, Help} from "@material-ui/icons";
import Popover from "@mui/material/Popover/Popover";
import {includes, keys} from "lodash";


const ReferentialQuizTable = ({standard, answerChange, answers, readOnly}) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [comment, setComment] = useState("");
  const [updatingCommentCriteriaId, setUpdatingCommentCriteriaId] = useState(null);

  const onShowCommentPopover = (currentTarget, criteriaId) => {
    setAnchorEl(currentTarget);
    setComment(answers[criteriaId].comment || "");
    setUpdatingCommentCriteriaId(criteriaId);
  };

  const onCloseCommentPopover = () => {
    setAnchorEl(null);
    setUpdatingCommentCriteriaId(null);
    answerChange('comment', comment, updatingCommentCriteriaId);
  };


  const getMaturityLevels = (hasNA) => {
    if(hasNA) return ['1', '2', '3', '4', 'N/A'];
    else return ['1', '2', '3', '4'];
  };

  return (
    <Table sx={{minWidth: 650}} size="small"
           aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell>Critere</TableCell>
          <TableCell align="right">Niveau de maturité</TableCell>
          <TableCell align="right"></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standard.criterias.filter((cr) => includes(keys(answers), String(cr.id))).map((criteria, criteriaIndex) => (
          <TableRow
            key={criteria.id} value={criteria}
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row">
              {`${criteriaIndex + 1} - ` + criteria.label}
            </TableCell>
            <TableCell align="right">
              <FormControl style={{width: '100%'}}>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Age"
                  value={answers[criteria.id].maturityLevel}
                  disabled={readOnly}
                  onChange={(event) => answerChange('maturityLevel', event.target.value, criteria.id)}
                >
                  { getMaturityLevels(criteria.hasNA).map(ml => <MenuItem key={ml} value={ml}>{ml}</MenuItem>) }
                </Select>
              </FormControl>
            </TableCell>
            <TableCell align="right">

            </TableCell>

            <TableCell align="center">
              <Tooltip className="m-1" title={<p style={{'white-space': 'pre-wrap', 'fontSize': '13px'}}> {criteria.userGuide} </p>}
                       placement="top-start">
                <Help/>
              </Tooltip>
              {readOnly && <Tooltip className="m-1"
                                    title={<p style={{'white-space': 'pre-wrap'}}> {answers[criteria.id].comment} </p>}
                                    placement="top-start">
                <Comment/>
              </Tooltip>}

              {!readOnly && <IconButton aria-label="Modifier le commentaire"
                                        onClick={(event) => onShowCommentPopover(event.currentTarget, criteria.id)}>
                <Comment/>
              </IconButton>}
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onCloseCommentPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className={'p-4'}>
                  <TextareaAutosize
                    placeholder="Commentaire"
                    style={{width: 250}}
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                  />
                </div>
              </Popover>
            </TableCell>

          </TableRow>
        ))}
      </TableBody>

    </Table>
  );
};

export default memo(ReferentialQuizTable);
