import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { ExpandMore, Refresh, Search } from "@material-ui/icons";
import { merge } from "lodash";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider/LocalizationProvider";
import StaticDateRangePicker from "@mui/lab/StaticDateRangePicker/StaticDateRangePicker";
import Box from "@mui/material/Box/Box";


const LogViewerFilter = ({ applyFilter, filter, setFilter, resetFilter }) => {

  const setFilterValue = (value) => {
    setFilter(merge({}, filter, value));
  };

  return (
    <Accordion className={"mb-2"}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Les paramètres de recherche</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={"row"}>
          <div className="col-md-2">
            <FormControl style={{ width: "100%" }} className={"vertical-center"}>
              <InputLabel id="demo-simple-select-helper-label">Limite</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={filter.size}
                label="Limite"
                onChange={(event) => setFilterValue({ size: event.target.value })}
              >
                {
                  [50, 100, 150, 200, 250].map(item => {
                    return (<MenuItem value={item}>{item}</MenuItem>);
                  })
                }
              </Select>
            </FormControl>
          </div>

          <div className="col-md-10">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={filter.dateRange}
                onChange={(newValue) => {
                  setFilterValue({ dateRange: newValue });
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </div>

        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div style={{ float: "right" }}>
              <Button variant="outlinedSecondary" startIcon={<Refresh/>}
                      style={{ marginRight: "5px" }}
                      onClick={() => resetFilter()}>
                Réinitialiser
              </Button>
              <Button variant="containedPrimary" endIcon={<Search/>} onClick={applyFilter}>
                Rechercher
              </Button>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default LogViewerFilter;
