import React, {useState} from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography} from "@material-ui/core";
import {Cancel, Save} from "@material-ui/icons";

const NewYearSetupModal = ({isOpen, setupNewYear, closeModal, years}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: 'auto',
    maxHeight: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const [value, setValue] = useState(null);

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Configurer une nouvelle annee {JSON.stringify(years)}
        </Typography>
        <div>
          <div className="row mb-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Annee</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  onChange={handleChange}
                  value={value}
                >
                  {years.map(year => <MenuItem value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
          </div>
        </div>
        <div className="row mt-2 mb-4">
          <div className="col-md-12">
            <div style={{textAlign: 'center'}}>
              <Button variant="outlinedSecondary" startIcon={<Cancel/>}
                      onClick={closeModal}
                      style={{marginRight: '5px'}}>
                Annuler
              </Button>
              <Button variant="containedPrimary" endIcon={<Save/>} onClick={() => setupNewYear(value)} >
                Ajouter
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default NewYearSetupModal;
