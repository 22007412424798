import {put, takeLatest} from "redux-saga/effects";
import * as crud from "../../crud/process-management.crud";

const actionTypes = {
  LoadRefs: "[ProcessManagement] Load refs",
  SetRefs: "[ProcessManagement] Set refs",
  SaveCriteria: "[ProcessManagement] Save criteria",
  UpdateCriteria: "[ProcessManagement] Update criteria",
  DeleteCriteria: "[ProcessManagement] Delete criteria"
};

const initialAuthState = {
  refProcess: [],
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.SetRefs: {
      const {data} = action.payload;

      return {refProcess: data};
    }

    default:
      return state;
  }
};

export const actions = {
  loadRefs: () => ({type: actionTypes.LoadRefs}),
  setRefs: (data) => ({type: actionTypes.SetRefs, payload: {data}})
};

export function* saga() {
  yield takeLatest(actionTypes.LoadRefs, function* loadRefsSaga() {
    const {data} = yield crud.getRefProcess();

    yield put(actions.setRefs(data));
  });
}
