import React from "react";
import { Route } from "react-router-dom";
import { canAccessPage } from "../utils/roles.utils";
import { UnauthorizedErrorPage } from "../pages/errors/UnauthorizedErrorPage";

function ProtectedRoute({ user, path, Component }) {
  const render = props => {
    const {params} = props.match;

    if (canAccessPage(path, params, user))
      return <Component {...props} user={user}/>;
    else
      return <UnauthorizedErrorPage />;
  };

  return (
    <Route path={path} render={render}/>
  );
}

export default ProtectedRoute;
