import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import AccountManagerFilter from "./AccountManagerFilter";
import { getAllUsers } from "../../../crud/training.crud";
import NewAccount from "./NewAccount";
import { uniq } from "lodash";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { getRoleLabel } from "../../../utils/roles.utils";
import { deleteUser } from "../../../crud/auth.crud";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { DeleteButton, EditButton, LockButton } from "../../../../shared/component/ActionsButton";
import { genericFilterAndPagination } from "../../../utils/search.utils";
import CustomPagination from "../../../../shared/component/CustomPagination";
import { actionConfirm } from "../../../../shared/utils/confirm.util";
import { useConfirm } from "material-ui-confirm";

const INITIAL_FILTER_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  establishment: ""
};

const defaultData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "123456",
  role: null,
  trainingOperator: null,
  trainingInstitute: null,
  trainingComplex: null
};

function AccountManager({ user }) {
  const confirm = actionConfirm(useConfirm());

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [filters, setFilters] = React.useState(INITIAL_FILTER_STATE);
  const [openModal, setOpenModal] = useState(false);
  const [modify, setModify] = useState(false);
  const [showUpdatePassModal, setShowUpdatePassModal] = useState(false);
  const [toUpdateUser, setToUpdateUser] = useState(null);
  const { notifyError, notifySuccess } = useNotification(useSnackbar());


  const [dataToModify, setDataToModify] = useState(defaultData);

  useEffect(() => {
    loadData();
  }, [openModal]);

  const loadData = () => {
    getAllUsers()
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des utilisateurs !");
        throw err;
      })
      .then(response => {
        setRows(response.data);
      });
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const getDistinctRows = () => {
    return uniq(rows.map(r => r.roles[0].label));
  };

  function deleteUserById(id) {
    deleteUser(id)
      .catch(err => {
        notifyError("Une erreure technique lors de la supression de l'utilisateur !");
        throw err;
      }).then(() => {
      notifySuccess("L'utilisateur a ete supprime avec succes !");
      loadData();
    });
  }


  const { data, dataLength } = genericFilterAndPagination(rows, filters, page, rowsPerPage);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-12">
          <div style={{ float: "right" }}>
            <Button variant="containedPrimary" endIcon={<Add/>} onClick={() => {
              setOpenModal(true);
              setDataToModify(defaultData);
              setModify(false);
            }}>
              Ajouter
            </Button>
          </div>
        </div>
      </div>

      <AccountManagerFilter roles={getDistinctRows()}
                            setFilter={setFilters}
                            filter={filters}
                            resetFilter={() => setFilters(INITIAL_FILTER_STATE)}/>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Prénom</TableCell>
                <TableCell align="right">Nom</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Rôle</TableCell>
                <TableCell align="right">Établissement</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || []).map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.firstName}
                  </TableCell>
                  <TableCell align="right">{row.lastName}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{getRoleLabel(row.roles[0].label)}</TableCell>
                  <TableCell align="right">{row.establishment}</TableCell>
                  <TableCell align="right">
                    <LockButton title="Modifier le mot de passe" onClick={() => {
                      setShowUpdatePassModal(true);
                      setToUpdateUser(row);
                    }}/>
                    <EditButton onClick={() => {
                      setOpenModal(true);
                      setModify(true);
                      setDataToModify({ ...row, role: row.roles[0].name });
                    }}/>
                    <DeleteButton
                      onClick={() => confirm("Etes vous sûr de bien vouloir suprrimer cet enregitrement ?").then(() => deleteUserById(row.id))}/>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>

                <CustomPagination
                  totalRows={dataLength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <NewAccount openModalValue={openModal} closeModal={closeModal}
                      loadData={loadData}
                      user={user} key={"modal-" + openModal} modify={modify} dataToModify={dataToModify}/>
          <UpdatePasswordModal visibility={showUpdatePassModal} closeModal={() => {
            setShowUpdatePassModal(false);
            setToUpdateUser(null);
          }}
                               user={toUpdateUser}/>
        </div>
      </div>
    </>
  );
}


export default AccountManager;
