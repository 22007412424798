import React, { useEffect, useState } from "react";
import { Portlet, PortletBody, PortletHeader, PortletHeaderToolbar } from "../../../partials/content/Portlet";
import RadarStatisticsChart from "../../../widgets/RadarStatisticsChart";
import PortletHeaderDropdown from "../../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import DashboardByTi from "./DashboardByTI";
import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography, MenuItem } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import MaturityLevelMeasures from "./MaturityLevelMeasures";
import MaturityLevelMeasuresByProcess from "./MaturityLevelMeasuresByProcess";
import { getAllYearsList } from "../../../crud/referential-quiz.crud";
import { v4 as uuidv4 } from "uuid";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import { getAllReceivedNotifications, updateNotification } from "../../../crud/notification.crud";
import ExportModal from "./ExportModal";
import { getAllTrainingOperators } from "../../../crud/training.crud";
import { FillStatusByOperatorAndComplex } from "./FillStatusByOperatorAndComplex";
import { includes, sortBy } from "lodash";
import { canSeeFillStatus, Roles } from "../../../utils/roles.utils";
import { Cancel } from "@material-ui/icons";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { ReportType } from "../../../utils/report-type.utils";
import { getAggregatedCriteriaReport } from "../../../crud/reporting.crud";
import { getEFfpParSession } from "../../../crud/reporting.crud";

function Dashboard({ user }) {
  const [years, setYears] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [trainingOperators, setTrainingOperators] = useState([]);
  const [trainingInstitutes, setTrainingInstitutes] = useState([]);
  const [trainingComplex, setTrainingComplex] = useState([]);
  const [trainingDrs, setTrainingDrs] = useState([]);
  const [selectedTrainingOperator, setSelectedTrainingOperator] = useState();
  const [selectedTrainingComplex, setSelectedTrainingComplex] = useState();
  const [selectedTrainingDR, setSelectedTrainingDR] = useState();
  const [selectedTrainingInstitute, setSelectedTrainingInstitute] = useState();
  const [exportModal, setExportModal] = useState({ visibility: false, type: null });
  const [expanded, setExpanded] = useState(true);
  const { notifyError, notifySuccess } = useNotification(useSnackbar());


  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleChangeTo = (event) => {
    setSelectedTrainingOperator(event.target.value);
  };

  const handleChangeTi = (event) => {
    setSelectedTrainingInstitute(event.target.value);
    //setYears(data[event.target.value]);
  };

  const handleChangeTc = (event) => {
    setSelectedTrainingComplex(event.target.value);
    //setYears(data[event.target.value]);
  };
  const handleChangeDR = (event) => {
    setSelectedTrainingDR(event.target.value);
    //setYears(data[event.target.value]);
  };

  useEffect(() => {

    getAllReceivedNotifications(user.id)
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des notifications !");
        throw err;
      })
      .then(response => {
        setNotifications(response.data);
      });

    getAllTrainingOperators()
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des donnees !");
        throw err;
      })
      .then(response => {
        setTrainingOperators(sortBy(response.data, item => item.name.toUpperCase()));
      });

    getAllYearsList()
      .catch(err => {
        notifyError("Une erreure technique lors du chargement des donnees !");
        throw err;
      })
      .then(response => {
        setYears(response.data);
      });

  }, []);


  useEffect(() => {
    if (years && years.length > 0) {
      setSelectedYear(years[0].year);
    }
  }, [years]);

  useEffect(() => {
    if (trainingOperators && trainingOperators.length > 0) {
      setSelectedTrainingOperator(trainingOperators[0].id);
      setSelectedYear(years[years.length - 1]);
    }
  }, [trainingOperators]);


  useEffect(() => {

    if (selectedTrainingOperator) {
      setTrainingInstitutes(sortBy(trainingOperators.filter(e => e.id == selectedTrainingOperator)[0].trainingInstitutes, item => item.name.toUpperCase()));
      setTrainingComplex(trainingOperators.filter(e => e.id == selectedTrainingOperator)[0].trainingComplexes);
      setTrainingDrs(trainingOperators.filter(e => e.id == selectedTrainingOperator)[0].trainingDRs);
      if (user.role == Roles.CF) {
        setSelectedTrainingComplex(trainingOperators.filter(e => e.id == selectedTrainingOperator)[0].trainingComplexes[0].id);
      }
      if (user.role == Roles.EFP_SAISIE || user.role == Roles.EFP_VALIDATEUR) {
        setSelectedTrainingInstitute(trainingOperators.filter(e => e.id == selectedTrainingOperator)[0].trainingInstitutes[0].id);
      }

    }
  }, [selectedTrainingOperator]);

  useEffect(() => {
    if (selectedTrainingComplex) {
      const trainingInstitutes = trainingOperators.filter(e => e.id == selectedTrainingOperator)[0].trainingInstitutes.filter(e => {
        if (e.trainingComplex) {
          return e.trainingComplex.id == selectedTrainingComplex;
        }
      });

      setTrainingInstitutes(sortBy(trainingInstitutes, item => item.name.toUpperCase()));
      setSelectedTrainingInstitute(null);
    }

  }, [selectedTrainingComplex]);

  const closeNotification = (not) => {
    not.read = true;
    updateNotification(not).then(response => {
      getAllReceivedNotifications(user.id).then(response => {
        setNotifications(response.data);
      });
    });
  };

  const closeExportModal = () => {
    setExportModal({ visibility: false, type: null });
  };

  const showExportModal = type => {
    if (type === ReportType.AGGREGATED_CRITERIA) {
      getAggregatedCriteriaReport(selectedYear);
    } else if(type === ReportType.EFP_PAR_SESSION) {
      getEFfpParSession();
    }else{
      setExportModal({ visibility: true, type });
    }
  };

  return (
    <>
      <ExportModal isOpen={exportModal.visibility} closeModal={closeExportModal} operator={selectedTrainingOperator} year={selectedYear}
        type={exportModal.type} user={user} />
      }

      {notifications.map(not => {
        return (
          not.read === false &&
          <div className="row mb-4" key={not.id + "not"}>
            <div className="col-md-12">
              <Alert severity="error" style={{ background: "#ffcdd2" }}
                onClose={() => {
                  closeNotification(not);
                }}>
                {not.body}
              </Alert>
            </div>
          </div>
        );
      })}

      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <div className="row mb-2">
            <div className="col-md-2">
              <FormControl style={{ width: "100%" }} className={"vertical-center"}>
                <TextField
                  id="outlined-select-currency-native2"
                  select
                  label="OF"
                  value={selectedTrainingOperator}
                  onChange={handleChangeTo}
                  key={uuidv4()}
                  disabled={includes([Roles.EFP_SAISIE, Roles.EFP_VALIDATEUR], user.role)}
                >
                  <MenuItem value={null}></MenuItem>
                  {trainingOperators.map((option) => (
                    <MenuItem key={"txc" + option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>

              </FormControl>

            </div>
            {(selectedTrainingOperator && trainingDrs.length > 0) &&
              <div className="col-md-2" style={{ display: "flex" }}>
                <FormControl style={{ width: "100%" }} className={"vertical-center"}>
                  <TextField
                    id="outlined-select-currency-native2"
                    select
                    label="DR"
                    value={selectedTrainingDR}
                    onChange={handleChangeDR}
                    key={uuidv4()}
                    disabled={includes([Roles.EFP_SAISIE, Roles.EFP_VALIDATEUR, Roles.CF], user.role)}
                  >
                    {trainingDrs.map((option) => (
                      <MenuItem key={"dr" + option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                </FormControl>
                <div>
                  {
                    selectedTrainingDR &&
                    <Cancel className="btn-pointer" onClick={() => setSelectedTrainingDR(null)} />
                  }
                </div>
              </div>
            }

            {(selectedTrainingOperator && trainingComplex.length > 0) &&
              <div className="col-md-2" style={{ display: "flex" }}>
                <FormControl style={{ width: "100%" }} className={"vertical-center"}>
                  <TextField
                    id="outlined-select-currency-native2"
                    select
                    label="CF"
                    value={selectedTrainingComplex}
                    onChange={handleChangeTc}
                    key={uuidv4()}
                    disabled={includes([Roles.EFP_SAISIE, Roles.EFP_VALIDATEUR], user.role)}
                  >
                    {trainingComplex.map((option) => (
                      <MenuItem key={"txc" + option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                </FormControl>
                <div>
                  {
                    selectedTrainingComplex &&
                    <Cancel className="btn-pointer" onClick={() => setSelectedTrainingComplex(null)} />
                  }
                </div>
              </div>
            }
            {((selectedTrainingOperator || selectedTrainingComplex)) &&

              <div className="col-md-2" style={{ display: "flex" }}>

                <FormControl style={{ width: "80%" }} className={"vertical-center"}>
                  <TextField
                    id="outlined-select-currency-native2"
                    select
                    label="EFP"
                    value={selectedTrainingInstitute}
                    onChange={handleChangeTi}
                    key={uuidv4()}
                  >
                    {trainingInstitutes.map((option) => (
                      <MenuItem key={"txc" + option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>

                </FormControl>
                <div>
                  {
                    selectedTrainingInstitute &&
                    <Cancel className="btn-pointer" onClick={() => setSelectedTrainingInstitute(null)} />
                  }
                </div>

              </div>
            }
            <div className="col-md-1">
              {<FormControl style={{ width: "100%" }} className={"vertical-center"}>
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Année"
                  value={selectedYear}
                  onChange={handleChange}
                  key={uuidv4()}
                >
                  {years.map((option) => (
                    <MenuItem key={"tx" + option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

              </FormControl>}
            </div>
          </div>
          <Accordion className={"mb-2"} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>Tableau de bord </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className="col-xl-6">
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <MaturityLevelMeasuresByProcess
                        key={"mr" + selectedTrainingComplex + selectedTrainingOperator + selectedTrainingInstitute + selectedYear}
                        year={selectedYear}
                        ti={selectedTrainingInstitute}
                        dr={selectedTrainingDR}
                        to={selectedTrainingOperator}
                        tc={selectedTrainingComplex} />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <MaturityLevelMeasures
                        key={"ms" + selectedTrainingComplex + selectedTrainingOperator + selectedTrainingInstitute + selectedYear}
                        year={selectedYear} ti={selectedTrainingInstitute}
                        to={selectedTrainingOperator}
                        dr={selectedTrainingDR}
                        tc={selectedTrainingComplex} />
                    </div>

                  </div>
                </div>

                <div className="col-xl-6">
                  <Portlet fluidHeight={true}>
                    <PortletHeader
                      title="Tableau Récapitulatif"
                      toolbar={
                        <PortletHeaderToolbar>
                          <PortletHeaderDropdown year={selectedYear} onSelect={showExportModal} user={user} />
                        </PortletHeaderToolbar>
                      }
                    />

                    <PortletBody>
                      <RadarStatisticsChart
                        role={user.role}
                        key={"radae" + selectedTrainingComplex + selectedTrainingOperator + selectedTrainingInstitute + selectedYear}
                        year={selectedYear} ti={selectedTrainingInstitute} to={selectedTrainingOperator}
                        tc={selectedTrainingComplex} trainingInstituteLength={trainingInstitutes.length}
                        dr={selectedTrainingDR} />
                    </PortletBody>
                  </Portlet>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="row">
            <div className="col-md-12">
              <DashboardByTi key={selectedTrainingComplex + selectedTrainingOperator + selectedTrainingInstitute}
                year={selectedYear} ti={selectedTrainingInstitute} to={selectedTrainingOperator}
                dr={selectedTrainingDR}
                tc={selectedTrainingComplex} />
            </div>
          </div>
          {canSeeFillStatus(user) &&
            <Accordion className={"mb-2"}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography>Taux de remplissage par EFP</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FillStatusByOperatorAndComplex year={selectedYear} tc={selectedTrainingComplex}
                  to={selectedTrainingOperator} dr={selectedTrainingDR} />
              </AccordionDetails>
            </Accordion>
          }

        </div>
      </div>
    </>
  );
}

export default Dashboard;
