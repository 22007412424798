import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import * as crud from "../../../crud/referential-management.crud";

import PageLoading from "../../../../shared/component/PageLoading";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { includes } from "lodash";
import { ArrowBack } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from "@material-ui/core";
import TableLoading from "../../../../shared/component/TableLoading";

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Modal } from 'react-bootstrap';
const ReferentialSessionPage = () => {
  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  useEffect(() => {
    loadReferentialParameters();
  }, []);

  const [referentialParameters, setReferentialParameters] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadReferentialParameters = () => crud.getReferentialSession()
    .catch(err => {
      notifyError(`Une erreure technique est survenue lors du chargement des parametres !`);
      throw err;
    })
    .then(({ data }) => {
      setLoading(false);
      setReferentialParameters(data);
    });

  const addNewParameter = (data, setterCallback) => {
    const year = new Date(data.year).getFullYear();
    if( includes(referentialParameters.map(item => item.year), year) ){
      return notifyError(`L'annee ${year} existe deja !`);
    }
    crud.addReferentialParameter({ ...data, year  })
      .catch(err => {
        notifyError(`Une erreure technique est survenue lors de l'ajoute de l'annee ${year} !`);
        throw err;
      })
      .then(() => {
        setterCallback(null);
        notifySuccess(`L'annee ${year} est bien ajoutee !`);
        loadReferentialParameters();
      });
  };

  const updateSession= (data, setterCallback) => {{
      crud.updateReferentialSession(data)
        .catch(err => {
          notifyError(`Une erreure technique est survenue lors de la mise a jour de la session !`);
          throw err;
        })
        .then(() => {
          notifySuccess(`La Session ${rowData.name} est bien activée!`);
          loadReferentialParameters();
        });


  }};


  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState({id:"",name:""});

  function handleDeactivate(session) {
    console.log(session)
    setRowData(session);
    setShowModal(true);

    
  }
  
  function handleModalClose() {
    setShowModal(false);
  }
  
  function handleModalConfirm() {
    updateSession(rowData)
   
    setShowModal(false);
  }

  if (loading)
    return <PageLoading/>;
  return (
    <>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <div className="row mb-4">
            <div className="col-md-4">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span>Paramétrage de la session d’autoévaluation</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <div className={"filter-btn-div"}>
                <Button variant="outlinedPrimary" startIcon={<ArrowBack/>}
                        onClick={() => window.history.back()}
                        style={{ marginRight: "5px" }}>
                  Retour
                </Button>
              </div>
            </div>
          </div>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Session</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            {!loading ?

              <TableBody>
                {(referentialParameters || []).map((row) => (
                  <TableRow
                    key={row.id}
                    style={{ backgroundColor: row.activated ? '#007bff' : '' }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    
  {row.activated ? (
    <TableCell align="right">
  <IconButton color="danger"  aria-label="Add an alarm">
  <Icon> check-square</Icon>
</IconButton>
</TableCell>
  ) : (
    <TableCell align="right">
    <IconButton color="secondary"  onClick={() => handleDeactivate(row)}>
    <Icon>close</Icon>
  </IconButton>
  </TableCell>
  )}

 
                  </TableRow>
                ))}
              </TableBody> : <TableLoading/>}

          </Table>
          <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm l'activation de la session {rowData.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Voulez-vous vraiment activez la session?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleModalConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
        </div>
      </div>
    </>
  );
};


export default ReferentialSessionPage;
