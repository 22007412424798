import axios from "axios";


const REFERENTIAL_MANAGEMENT_URL = "referential-management";
const REFERENTIAL_MANAGEMENT_CURRENT_URL = "referential-management/current";
const REFERENTIAL_MANAGEMENT_VALID_YEARS_URL = "referential-management/valid-years";
const REFERENTIAL_MANAGEMENT_ALL_YEARS_URL = "referential-management/all-years";
const REFERENTIAL_SESSIONS_URL = "referential-management/sessions";



export function getReferentialParameters() {
  return axios.get(REFERENTIAL_MANAGEMENT_URL);
}

export function getValidYears() {
  return axios.get(REFERENTIAL_MANAGEMENT_VALID_YEARS_URL);
}
export function getAllConfiguredYears() {
  return axios.get(REFERENTIAL_MANAGEMENT_ALL_YEARS_URL);
}

export function addReferentialParameter(data) {
  return axios.post(REFERENTIAL_MANAGEMENT_URL, data);
}

export function updateReferentialParameter(data) {
  return axios.put(REFERENTIAL_MANAGEMENT_URL, data);
}

export function getCurrentYearParams(){
  return axios.get(REFERENTIAL_MANAGEMENT_CURRENT_URL);
}

export function getReferentialSession() {
  return axios.get(REFERENTIAL_SESSIONS_URL);
}

export function updateReferentialSession(data) {
  return axios.put(REFERENTIAL_SESSIONS_URL,data);
}