import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    MenuItem,
    TextField,
    Typography,
} from "@material-ui/core";
import {ExpandMore, Save} from "@material-ui/icons";
import {getAllTrainingAreaZone, getAllTrainingStatus} from "../../../../../crud/trainingInstitute.crud";
import {v4 as uuidv4} from "uuid";
import {Controller, useForm} from "react-hook-form";
import { getAllUsers } from "../../../../../crud/training.crud";

export const GeneralInformations = ({generalInformationsData, handleGeneraleInformationsData}) => {
    const [status, setStatus] = useState([]);
    const [areaZones, setAreaZones] = useState([]);
    const {watch, control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: generalInformationsData
    });
    const [selectedResponsible, setSelectedResponsible] = React.useState(null);
  const [responsibles, setResponsibles] = React.useState([]);

    const onSubmit = data => {
        handleGeneraleInformationsData(data);
    };

    useEffect(() => {

      getAllUsers().then(response => {
        setResponsibles(response.data);
      });

        getAllTrainingStatus().then(response => {
            setStatus(response.data);
        });

        getAllTrainingAreaZone().then(response => {
            setAreaZones(response.data);
        });

        const subscription = watch((value, { name, type }) => {
            handleGeneraleInformationsData(value);
        });
        return () => subscription.unsubscribe();

    }, [watch]);
    return (
        <div className="row">
            <div className="col-md-12">
                <Accordion className={'mb-2'}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography> Informations générales</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <Controller
                                        className={'vertical-center'}
                                        name="email"
                                        control={control}
                                        render={({field}) => <TextField
                                            {...field}
                                            label={"Courriel"}
                                            variant="outlined"
                                            size="small"
                                            style={{marginTop: '10px'}}/>}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Controller
                                        className={'vertical-center'}
                                        name="phoneNumber"
                                        control={control}
                                        render={({field}) => <TextField
                                            {...field}
                                            label={"Téléphone"}
                                            variant="outlined"
                                            size="small"
                                            style={{marginTop: '10px'}}/>}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Controller
                                        key={uuidv4()}
                                        className={'vertical-center'}
                                        name="instituteStatus"
                                        rules={{required: false}}
                                        control={control}
                                        render={({field}) => <FormControl style={{width: '100%'}}
                                                                          className={'vertical-center'}>
                                            <TextField
                                                label={"Statut de l'établissement"}
                                                key={uuidv4()}
                                                select
                                                {...field}
                                            >
                                                {
                                                    status.map(e => {
                                                        return (
                                                            <MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                                                    })
                                                }
                                            </TextField>
                                        </FormControl>}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Controller
                                        key={uuidv4()}
                                        className={'vertical-center'}
                                        name="areaZone"
                                        rules={{required: false}}
                                        control={control}
                                        render={({field}) => <FormControl style={{width: '100%'}}
                                                                          className={'vertical-center'}>
                                            <TextField
                                                label={"Milieu (situation de l'EFP)"}
                                                key={uuidv4()}
                                                select
                                                {...field}
                                            >
                                                {
                                                    areaZones.map(e => {
                                                        return (
                                                            <MenuItem value={e.id} key={uuidv4()}>{e.name}</MenuItem>);
                                                    })
                                                }
                                            </TextField>
                                        </FormControl>}
                                    />
                                </div>

                            </div>
                          <div className="row mb-2">
                              {/*  <div className="col-md-3">
                                  <Controller
                                    className={'vertical-center'}
                                    name="responsible"
                                    control={control}

                                    rules={{required: true}}
                                    render={({field}) => <FormControl key={uuidv4()} style={{width: '100%'}} className={'vertical-center'}>

                                      <TextField
                                        label={"Resposable"}
                                        key={selectedResponsible +'resp'}
                                        select
                                        defaultValue={selectedResponsible}
                                        {...field}
                                      >

                                        {
                                          responsibles.map(e => {
                                            return (
                                              <MenuItem value={e.id}
                                                        key={uuidv4()}>{e.lastName + ' ' + e.firstName}</MenuItem>);
                                          })
                                        }
                                      </TextField>
                                    </FormControl>}
                                  />
                                </div>
*/}
                            </div>

                        </form>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
};

export default GeneralInformations;
