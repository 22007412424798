import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getAllReceivedNotifications, getAllSentNotifications} from "../../crud/notification.crud";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {NotificationImportantOutlined} from '@material-ui/icons';

const Notification = ({user, type}) => {
    const [notificatioons, setNotifications] = useState([])

    useEffect(() => {
        switch (type) {
            case 'sent':
                getAllSentNotifications(user.id).then(response => {
                        setNotifications(response.data);
                    }
                );
            case 'received':
                getAllReceivedNotifications(user.id).then(response => {
                        setNotifications(response.data);
                    }
                );
        }
    }, []);

    return (

        <>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {notificatioons.map(e => {
                    return (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <NotificationImportantOutlined />
                                </Avatar>
                            </ListItemAvatar>
                            {type == 'sent' &&  <ListItemText primary={e.to.firstName + ' '  + e.to.lastName} secondary={e.body} />}
                            {type == 'received' &&  <ListItemText primary={ e.from.firstName + ' '  + e.from.lastName} secondary={e.body} />}
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
};
const mapStateToProps = ({auth: {user}}) => ({
    user
});

export default connect(mapStateToProps)(Notification);
