import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack, Lock, Save } from "@material-ui/icons";
import PageLoading from "../../../../shared/component/PageLoading";
import Avatar from "@mui/material/Avatar/Avatar";
import UpdatePasswordModal from "../accounts-manager/UpdatePasswordModal";
import { getRoleLabel } from "../../../utils/roles.utils";
import { updateEmail } from "../../../crud/auth.crud";
import { useNotification } from "../../../../shared/utils/notification.util";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { register, update } from "../../../crud/register.crud";


const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));


const MyProfilePage = ({ user }) => {
  const classes = useStyles();
  const { control, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: {email: user.email}
  });
  const { notifySuccess, notifyError } = useNotification(useSnackbar());

  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const handleChangeEmail = (em) => {
    console.log('===>, email 1', em.email)
    updateEmail(em).catch(err => {
        notifyError("Une erreure technique lors de la modification de l'email !");
      throw err;
    })
      .then(response => {
          notifySuccess("Email modifié avec succés");
      });
  };

  const onSubmit = em => {
    handleChangeEmail(em)
  };

  if (!user)
    return <PageLoading/>;
  return (
    <>
      <div className={"kt-portlet"}>
        <div className={"kt-portlet__body"}>
          <div className="row mb-4">
            <div className="col-md-4">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <span>Mon profil</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
            </div>
            <div className="col-md-4">
              <div className={"filter-btn-div"}>
                <Button variant="outlinedPrimary" startIcon={<ArrowBack/>}
                        onClick={() => window.history.back()}
                        style={{ marginRight: "5px" }}>
                  Retour
                </Button>
              </div>
            </div>
          </div>
          <form className={classes.container} noValidate autoComplete="off">
            <div className="row ml-2" style={{ width: "100%" }}>
              <div className="col-md-6 d-flex">
                <Avatar>{user.initials}</Avatar>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <Button startIcon={<Lock/>} variant="outlinedPrimary" size="large"
                        onClick={() => setShowUpdatePasswordModal(true)}>Modifier mon mot de passe</Button>
              </div>
            </div>
            <TextField
              id="outlined-name"
              label="Prénom"
              className={classes.textField}
              value={user.firstName}
              disabled={true}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="outlined-name"
              label="Nom"
              className={classes.textField}
              value={user.lastName}
              disabled={true}
              margin="normal"
              variant="outlined"
            />

            <TextField
              id="outlined-name"
              label="Role"
              className={classes.textField}
              value={getRoleLabel(user.roles[0])}
              disabled={true}
              margin="normal"
              variant="outlined"
            />
            {user.trainingOperator && <TextField
              id="outlined-name"
              label="Opérateur de formation"
              className={classes.textField}
              value={user.trainingOperator}
              disabled={true}
              margin="normal"
              variant="outlined"
            />}
            {user.trainingComplex && <TextField
              id="outlined-name"
              label="Comlexe de formation"
              className={classes.textField}
              value={user.trainingComplex}
              disabled={true}
              margin="normal"
              variant="outlined"
            />}
            {user.trainingInstitute && <TextField
              id="outlined-name"
              label="Etablissement de formation"
              className={classes.textField}
              value={user.trainingInstitute}
              disabled={true}
              margin="normal"
              variant="outlined"
            />}



          </form>
          <form onSubmit={handleSubmit(onSubmit)} >

          <div className="row mr-2">
            <div className="col-md-12">
                <Controller
                  className={"vertical-center"}
                  name="email"
                  rules={{ required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g }}
                  control={control}
                  render={({ field }) => <TextField
                    {...field}
                    label="E-mail"
                    id="outlined-name"
                    className={classes.textField}
                    variant="outlined"
                    />}
                />
                {errors.email && <span className={"label-error"}>Adresse e-mail invalide</span>}
            </div>
          </div>
          <div className="row mt-3 ml-1">
            <div className="col-md-12">
              <Button variant="containedPrimary" endIcon={<Save/>} type="submit">
                Modifier l'email
              </Button>
            </div>
          </div>
          </form>
        </div>
      </div>
      <UpdatePasswordModal visibility={showUpdatePasswordModal} user={user}
                           closeModal={() => setShowUpdatePasswordModal(false)} withOldPassword={true}/>
    </>
  );
};

export default MyProfilePage;
